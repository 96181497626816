// /auth/google/callback
import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../../apis/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import { getOrderPlatform } from '../../biz/order';
import { useLoginStore } from '@/hooks/useLoginStore';
import {
  getLoginPageUrl,
  getDetailPageUrl,
  getOrderId,
  getJoinCampaign,
  getLoginErrorPageUrl,
  clearToken,
  checkLoginAndMatch
} from '@/biz/tool';

const GoogleCallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toastRef = useRef('');

  const getUrl = async (orderPlatform: any) => {
    const orderId = getOrderId();
    if (!orderId) {
      window.location.href = getLoginErrorPageUrl();
    }

    try {
      toast.dismiss(toastRef.current);
      toastRef.current = toast.loading(
        `Redirecting to ${orderPlatform ? getOrderPlatform({ platform: orderPlatform }) : 'third-party platform'} access authorization page...`
      );

      const {
        data: {
          data: { url }
        }
      } = await axiosInstance.post(`/auth/google`, {
        redirect_uri: `${window.location.origin}/auth/google/callback`,
        orderId,
        joinCampaign: getJoinCampaign()
      });

      if (url) {
        window.location.href = url;
      } else {
        toast.dismiss(toastRef.current);
        // 不展示后端报错, 直接跳转到失败页
        getLoginErrorPageUrl();
      }
    } catch (error: any) {
      console.error('Fail to get Google auth url', error);
      // 不展示后端报错, 直接跳转到失败页
      window.location.href = getLoginErrorPageUrl();
    }
  };

  const login = async (
    access_token: string,
    state?: string,
    orderPlatform?: string
  ) => {
    try {
      const {
        data: { code, message, data }
      } = await axiosInstance.post(`/auth/google/callback`, {
        access_token,
        state
      });
      const { user, token, platform } = data || {};
      const isLoginAndMatch = checkLoginAndMatch(data);

      if (token) {
        window.localStorage.setItem('token', token);
        if (user) {
          window.localStorage.setItem(
            'user',
            JSON.stringify({
              email: user.email,
              name: user.username
            })
          );
        }
        if (platform) {
          window.localStorage.setItem('platform', JSON.stringify(platform));
        }

        if (!isLoginAndMatch) {
          toast.error(
            'The account does not match the account bound to the campaign.'
          );
          clearToken();
          window.location.href = getLoginErrorPageUrl({
            type: 'accountNotMatch'
          });
          return;
        }

        setTimeout(() => {
          window.location.href = getDetailPageUrl({ loginSuccess: true });
        }, 0);
      } else {
        // 不展示后端报错, 直接跳转到失败页
        window.location.href = getLoginErrorPageUrl();
      }
    } catch (error: any) {
      console.error('auth error', error);
      if (
        orderPlatform &&
        error?.message?.includes('Request failed with status code 403')
      ) {
        getUrl(orderPlatform);
        return;
      }
      // 不展示后端报错, 直接跳转到失败页
      window.location.href = getLoginErrorPageUrl();
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.hash.replace(/^#/, ''));
    // 取消登录 重定向地址为：http://localhost:5001/auth/google/callback#error=access_denied
    // google
    const access_token = query.get('access_token');
    const state = query.get('state') || '';
    const error = query.get('error');

    let orderPlatform: any;
    if (state) {
      try {
        const payload = JSON.parse(state);
        orderPlatform = payload.platform;
      } catch (e) {
        console.error('failed to parse state', e);
      }
    }

    if (error) {
      console.log('Cancel login google: ', error);
    } else {
      toastRef.current = toast.loading('Redirecting...');
    }

    if (access_token) {
      login(access_token, state, orderPlatform);
    } else {
      if (error === 'access_denied') {
        toast.error(`You have canceled login.`);
      } else {
        toast.error(`Missing access_token.`);
      }
      setTimeout(() => {
        navigate(orderPlatform ? getDetailPageUrl() : `/campaign/error`);
      }, 0);
    }
  }, [location, navigate]);

  return (
    <Toaster
      toastOptions={{
        style: {
          fontSize: 12,
          borderRadius: 4,
          background: '#1C2024',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
          color: '#fff'
        }
      }}
    />
  );
};

export default GoogleCallbackPage;
