import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import axiosInstance from '../../apis/axiosInstance';
import WebLoading from '../../components/common/webloading';
import Lottie from 'react-lottie';
import scanLottie from './qr-scan-lottie.json';
import toast from 'react-hot-toast';
import { getQrCodeLink } from '@/biz/tool';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: scanLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const TaskDetailPage: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const origin = window.location.origin;
  const router = query.get('router') || 'home';
  const orderId = query.get('orderId');

  const [orderData, setOrderData] = useState({
    influencerName: '',
    influencerTags: [],
    campaignName: '',
    orderPrice: '',
    profilePictureLink: '',
    productLogo: ''
  });
  const [loading, setLoading] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState(getQrCodeLink());

  const queryOrderData = async () => {
    if (!orderId) {
      setQrCodeLink('https://ahalab.io/');
      setTimeout(() => {
        toast.error('Missing order ID. Please try again. ');
      }, 0);
      return;
    }

    setLoading(true);
    try {
      const {
        data: { data }
      } = await axiosInstance.get(`/platform/order/${orderId}`);

      if (data?.influencer && data?.campaign && data?.order) {
        setOrderData({
          influencerName: data.influencer.username,
          influencerTags: data.influencer.tags,
          campaignName: data.campaign.name,
          orderPrice: data.order.price,
          profilePictureLink: data.influencer.profilePictureLink,
          productLogo: data.product?.logo
        });
      } else {
        toast.error('Network error. Please try again.');
      }
    } catch (error) {
      toast.error('Network error. Please try again.');
      console.error('error: ', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    queryOrderData();
  }, []);

  return (
    <div className="flex justify-between max-w-[1440px] px-[120px] mx-auto h-screen min-h-[840px] overflow-y-hidden">
      <div className="flex flex-col justify-between mt-14">
        <div className="ml-[10px]">
          <img className="h-10" alt="loginLogo" src="/c-ahaLogo.png" />
        </div>
        <div className="flex flex-col items-center mt-20">
          <div className="w-[540px] text-black text-[64px] font-semibold font-geist leading-[64px]">
            Scan to continue on mobile
          </div>
          <div className="relative mt-10 mb-6">
            <QRCodeSVG
              value={qrCodeLink}
              title={qrCodeLink}
              size={180}
              marginSize={3}
            />
            <div className="absolute top-0 h-[182px]">
              <Lottie
                options={defaultOptions}
                speed={0.5}
                height={182}
                width={182}
                // isStopped={this.state.isStopped}
                // isPaused={this.state.isPaused}
              />
            </div>
          </div>
          <div className="w-[573px] text-center text-[#60646c] text-xl font-light font-geist leading-[30px]">
            Our web app is coming soon! In the meantime, please scan the QR code
            to view your campaign on the Aha mobile app.
          </div>
        </div>
        {/* 底部占位 */}
        <div className="h-8"></div>
      </div>
      <div className="relative top-1/2 translate-y-[-50%] h-[780px]">
        <div className="relative top-[64px] left-1/2 translate-x-[-50%] w-[664.65px] h-[681.62px] opacity-[0.65] bg-gradient-to-b from-[#fff] from-0%  to-[#9898FF] to-[88.36%] rounded-[476px] blur-[40px]" />
        <img
          className="absolute top-0 left-1/2 translate-x-[-50%] w-[520px] h-[900px]"
          alt="demo"
          src="/web-iphone-demo.png"
        />
        {/* 下面是文字数据 */}
        <div className="absolute top-[9px] left-1/2 translate-x-[-50%] w-[342px] h-[758px] rounded-[30px] text-white overflow-hidden">
          <div className="absolute top-9 left-[46px] text-[21px] font-geist font-semibold italic w-[186px] truncate">
            {orderData.influencerName}
          </div>
          <div className="absolute left-[21px] top-[163px] w-[56px] h-[56px] overflow-hidden flex justify-center items-center rounded-full bg-white p-[6px]">
            <img
              className="w-[56px]"
              alt="product-logo"
              src={orderData.productLogo || `/c-detail-task.png`}
            />
          </div>
          <div className="absolute left-[88px] top-[190px] translate-y-[-50%] text-lg leading-5 font-semibold font-geist w-[240px] line-clamp-3">
            {orderData.campaignName}
          </div>
          <div className="absolute left-1/2 translate-x-[-50%] top-[230px] text-[32px] font-semibold">
            {orderData.orderPrice ? `$${orderData.orderPrice}` : '--'}
          </div>
          <div className="absolute left-[73px] bottom-[219px] w-[69px] h-[69px] overflow-hidden flex justify-center items-center rounded-full bg-white p-[6px]">
            <img
              className="w-[69px]"
              alt="product-logo"
              src={orderData.productLogo || `/c-detail-task.png`}
            />
          </div>
          {orderData.profilePictureLink ? (
            <img
              alt="avatar"
              className="absolute right-[73px] bottom-[219px] h-[69px] rounded-full"
              src={orderData.profilePictureLink}
            />
          ) : null}
          {Array.isArray(orderData?.influencerTags) &&
          orderData.influencerTags.length > 0 ? (
            <div className="absolute left-1/2 translate-x-[-50%] top-[550px] flex flex-col items-center w-[335px]">
              <div className="text-[#1C2024] mb-1 font-semibold">
                Why you're a match
              </div>
              <div className="text-[#B06700] flex flex-wrap justify-center ">
                {orderData.influencerTags
                  .slice(0, 5)
                  .map((tag: string, index: number) => (
                    <div
                      key={tag}
                      className="bg-[#FFF6EE] mx-1 px-2 mb-2 text-xs font-medium leading-5 rounded-[10px]"
                    >
                      {tag}
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
          <WebLoading open={loading} />
        </div>
      </div>
    </div>
  );
};

export default TaskDetailPage;
