import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LoginStore = {
  isLogin: boolean;
  setIsLogin: (isLogin: boolean) => void;
  isLoginMatch: boolean;
  setIsLoginMatch: (isLoginMatch: boolean) => void;
  thisGooglePlatformId: string | null;
  setThisGooglePlatformId: (thisGooglePlatformId: string | null) => void;
  influencerUserGooglePlatformId: string | null;
  setOrderUserGooglePlatformId: (
    influencerUserGooglePlatformId: string | null
  ) => void;
  influencerUserGooglePlatformEmail: string | null;
  setOrderUserGooglePlatformEmail: (
    influencerUserGooglePlatformEmail: string | null
  ) => void;
};

export const useLoginStore = create(
  persist<LoginStore>(
    (set) => ({
      isLogin: false,
      setIsLogin: (isLogin: boolean) => set({ isLogin }),
      isLoginMatch: false,
      setIsLoginMatch: (isLoginMatch: boolean) => set({ isLoginMatch }),
      thisGooglePlatformId: null,
      setThisGooglePlatformId: (thisGooglePlatformId: string | null) =>
        set({ thisGooglePlatformId }),
      influencerUserGooglePlatformId: null,
      setOrderUserGooglePlatformId: (
        influencerUserGooglePlatformId: string | null
      ) => set({ influencerUserGooglePlatformId }),
      influencerUserGooglePlatformEmail: null,
      setOrderUserGooglePlatformEmail: (
        influencerUserGooglePlatformEmail: string | null
      ) => set({ influencerUserGooglePlatformEmail })
    }),
    {
      name: 'login-storage' // 存储的唯一名称
    }
  )
);
