import React from 'react';

import styles from "./stepsCard.module.less"

export const StepsCard: React.FC = () => {
    return (
        <div className={styles.stepsCard}>
            <div className={styles.title}>Just <span className={styles.boldNum}>3</span>steps  to claim your reward！</div>
            <div className={styles.desc}>
                <div className={styles.item}>
                    <img src="/c-step1.png" className={styles.stepImg} />
                    <div className={styles.content}>
                        <div className={styles.stepTitle}><span className={styles.stepNum}>1.</span>Apply to a Campaign</div>
                        <div className={styles.stepDesc}>Find and join a campaign that fits your style. </div>
                    </div>
                </div>

                <div className={styles.item}>
                    <img src="/c-step2.png"  className={styles.stepImg} />
                    <div className={styles.content}>
                        <div className={styles.stepTitle}><span className={styles.stepNum}>2.</span>Create and Submit Content</div>
                        <div className={styles.stepDesc}>Follow the guidelines and send it for review. </div>
                    </div>
                </div>

                <div className={styles.item}>
                    <img src="/c-step3.png"  className={styles.stepImg} />
                    <div className={styles.content}>
                        <div className={styles.stepTitle}><span className={styles.stepNum}>3.</span>Publish and Earn</div>
                        <div className={styles.stepDesc}>Post approved content and get your reward!</div>
                    </div>
                </div>
            </div>
        </div>
    )
} 