import React from 'react';
import styles from './campaignPayment.module.less';
import { normalizePrice } from '@/utils/normalizePrice';

import {
  AHA_DOCS_LINK,
  getFrontPaymentRate
} from '../../pages/campaign/config';

interface Props {
  data: any;
}

export const CampaignPayment: React.FC<Props> = ({
  data: { payment, order, timeline } = {}
}) => {
  const rate = getFrontPaymentRate(order?.prepaymentRate);
  const lastRate = getFrontPaymentRate(1 - order?.prepaymentRate);
  const lastPrice = order?.price - order?.prePaymentAmount;

  return (
    <div className={styles.payment}>
      <div className={styles.detail}>
        <div className={styles.item}>
          <div className={styles.label}>Total payment</div>
          <div className={styles.value}>
            {!isNaN(order?.price) ? normalizePrice(order.price) : ''}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {isNaN(order?.prepaymentRate) ? '' : rate} upfront payment
          </div>
          <div className={styles.timeValue}>
            {!isNaN(order?.prePaymentAmount)
              ? normalizePrice(order?.prePaymentAmount)
              : ''}
            , paid after application approval
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {isNaN(order?.prepaymentRate) ? '' : lastRate} post-delivery payment
          </div>
          <div className={styles.timeValue}>
            {!isNaN(lastPrice) ? normalizePrice(lastPrice) : ''}, paid within 1
            week of posting
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Payment method</div>
          <div className={styles.value}>
            Bank Transfer or Paypal
            {/* 暂时固定为 Bank Transfer */}
            {/* Bank Transfer */}
            {/* {payment?.accountType
              ? payment?.accountType === 'paypal'
                ? 'PayPal'
                : 'Bank Transfer'
              : 'PayPal or Bank Transfer'} */}
          </div>
        </div>
      </div>
      <div className="w-[358px] p-2 bg-[#f9f9fb] rounded-lg flex-col justify-start items-center gap-4 inline-flex">
        <div className="self-stretch justify-start items-start gap-1 inline-flex">
          <span className="mt-1">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99993 13.2708C3.54193 13.2708 0.729095 10.458 0.729095 6.99996C0.729095 3.54196 3.54193 0.729126 6.99993 0.729126C10.4579 0.729126 13.2708 3.54196 13.2708 6.99996C13.2708 10.458 10.4579 13.2708 6.99993 13.2708ZM6.99993 1.60413C4.02435 1.60413 1.6041 4.02438 1.6041 6.99996C1.6041 9.97554 4.02435 12.3958 6.99993 12.3958C9.97551 12.3958 12.3958 9.97554 12.3958 6.99996C12.3958 4.02438 9.97551 1.60413 6.99993 1.60413ZM7.43743 9.62496V6.95852C7.43743 6.71702 7.24143 6.52102 6.99993 6.52102C6.75843 6.52102 6.56243 6.71702 6.56243 6.95852V9.62496C6.56243 9.86646 6.75843 10.0625 6.99993 10.0625C7.24143 10.0625 7.43743 9.86646 7.43743 9.62496ZM7.59494 4.95829C7.59494 4.63629 7.33419 4.37496 7.01161 4.37496H7.00577C6.68377 4.37496 6.42528 4.63629 6.42528 4.95829C6.42528 5.28029 6.68961 5.54163 7.01161 5.54163C7.33361 5.54163 7.59494 5.28029 7.59494 4.95829Z"
                fill="#60646C"
              />
            </svg>
          </span>
          <p className="flex text-[#1c2024] text-xs leading-5">
            Fees may apply.
            <a
              target="_blank"
              className="ml-1 underline text-[#0c67e6]"
              href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
              rel="noreferrer"
            >
              Learn more
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
