import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { CardIcon, BackIcon } from '../../components/common/icon';
import styles from './campaignHeader.module.less';
import { useLoginStore } from '@/hooks/useLoginStore';
import { LoginButtonNoStyle } from '@/components/campaign/loginButtonNoStyle';
import {
  getOrderId,
  getToken,
  getLoginPageUrl,
  getHomePageUrl
} from '@/biz/tool';

const UseInfo: React.FC<{ isOpen: boolean; setIsOpen: Function }> = ({
  isOpen,
  setIsOpen
}) => {
  const orderId = getOrderId();
  const [profile, setProfile] = useState<any>({});
  const [user, setUser] = useState<any>({});
  useEffect(() => {
    try {
      const str = window.localStorage.getItem('profile');
      if (str) {
        setProfile(JSON.parse(str));
      }
    } catch (e) {}
    try {
      const str = window.localStorage.getItem('user');
      if (str) {
        setUser(JSON.parse(str));
      }
    } catch (e) {}
  }, [window.localStorage.getItem('profile')]);

  function logout() {
    window.localStorage.removeItem('orderPlatform');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('profile');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('platform');
    if (orderId) {
      window.location.href = getHomePageUrl();
    } else {
      window.location.href = `/`;
    }
  }

  return (
    <Drawer
      id="drawerCtn"
      anchor="right"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles.rightDrawer}>
        <div className={styles.backBtn} onClick={() => setIsOpen(false)}>
          <img
            alt="backup"
            src="/c-angleLeft.png"
            className={styles.leftIcon}
          />
          Back
        </div>
        <div className={styles.userInfo}>
          <img
            alt="userAvatar"
            src={getToken() ? profile?.avatar : '/c-avatar-empty.svg'}
            className={styles.userAvatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/c-avatar-empty.svg';
            }}
          />
          <div>
            <div translate="no" className={styles.userName}>
              {profile?.name}
            </div>
            <div className={styles.email}>{user?.email}</div>
          </div>
        </div>
        <div className={styles.menu}>
          <NavLink to={`/campaign/payment/method?orderId=${orderId}`}>
            <div className={styles.item}>
              <CardIcon className={styles.itemIcon} />
              Payment account
            </div>
          </NavLink>
        </div>
        <div className={styles.logoutBtn} onClick={() => logout()}>
          <img alt="logout" src="/c-logout.png" className={styles.logoutIcon} />
          Log out
        </div>
      </div>
    </Drawer>
  );
};

export const Header: React.FC<{ title: string; isBack?: boolean }> = ({
  title,
  isBack = false
}) => {
  const navigate = useNavigate();
  const { isLogin, isLoginMatch } = useLoginStore();
  const [profile, setProfile] = useState<any>({});

  useEffect(() => {
    try {
      const str = window.localStorage.getItem('profile');
      if (str) {
        setProfile(JSON.parse(str));
      }
    } catch (e) {}
  }, [window.localStorage.getItem('profile')]);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.header}>
        <UseInfo isOpen={isOpen} setIsOpen={setIsOpen} />
        {!isBack ? (
          // 回 Home 页
          <NavLink to={getHomePageUrl()}>
            <img alt="loginLogo" src="/c-ahaLogo.png" className={styles.icon} />
          </NavLink>
        ) : (
          // 回 上一 页
          <BackIcon
            className={styles.back}
            onClick={() => window.history.back()}
          />
        )}
        <div className={styles.title}>{title}</div>
        {isLogin && isLoginMatch ? (
          <img
            alt="avatar"
            src={getToken() ? profile?.avatar : '/c-avatar-empty.svg'}
            className={styles.avatar}
            onClick={() => {
              setIsOpen(true);
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/c-avatar-empty.svg';
              console.error('youtube avatar failed!!! ' + profile?.avatar);
            }}
          />
        ) : (
          <LoginButtonNoStyle initAccessGoogle>
            {profile?.avatar ? (
              <img
                alt="avatar"
                src={getToken() ? profile?.avatar : '/c-avatar-empty.svg'}
                className={styles.avatar}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/c-logo.jpg';
                }}
              />
            ) : null}
          </LoginButtonNoStyle>
        )}
      </div>
      <div className="h-12 w-full"></div>
    </>
  );
};

export default Header;
