import {
  getOrderContentType,
  getOrderContentGenre,
  getOrderPlatform
} from '../../biz/order';
import React from 'react';
import {
  BuildingIcon,
  AlarmIcon,
  DollarIcon
} from '../../components/common/icon';
import styles from './campaignInfo.module.less';
import { normalizePrice } from '@/utils/normalizePrice';
import { getDuration } from '@/biz/time';

interface Props {
  data: any;
}

type PlatformType = 'linkedin' | 'twitter' | 'tiktok' | 'instagram';

// TODO: platform 有 三种样式: 1. 黑白 twitter, tiktok 2. 紫色 instagram 3. 蓝色 linkedin(已完成)

export const CampaignInfo: React.FC<Props> = ({
  data: { order, influencer, campaign, timeline, customer } = {}
}) => {
  const firstPostDeadline = (
    hours: number | null,
    createdAt?: string | Date | null
  ) => {
    if (createdAt)
      return new Date(createdAt)
        .toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          timeZoneName: 'short'
        })
        .replace(' at ', ', ');
    if (hours) return `${getDuration(hours)} after application approval`;
    return '-';
  };

  const platformStyle = {
    linkedin: {
      color: '#1E78FF',
      backgroundColor: '#EAF3FF'
    },
    twitter: {
      color: '#FCFDFF',
      backgroundColor: '#1C2024'
    },
    tiktok: {
      color: '#FFFFFF',
      backgroundColor: '#000000'
    },
    instagram: {
      color: '#5A31F0',
      backgroundColor: '#EFF1FF'
    },
    youtube: {
      color: '#F05331',
      backgroundColor: '#FFEBE5'
    }
  };

  return (
    <>
      <div className={styles.campaignInfo}>
        <div translate="no" className={styles.title}>
          {campaign?.name}
        </div>
        <div className={styles.tags}>
          <div
            className={styles.platform}
            style={
              platformStyle[order?.platform as PlatformType]
                ? platformStyle[order?.platform as PlatformType]
                : platformStyle['linkedin']
            }
          >
            {getOrderPlatform(order)}
          </div>
          <div className={styles.type}>{getOrderContentGenre(order)}</div>
          <div className={styles.type}>{getOrderContentType(order)}</div>
          {!isNaN(order?.price) ? (
            <div className={styles.type}>{normalizePrice(order.price)}</div>
          ) : null}
        </div>
      </div>
    </>
  );
};
