import React from 'react';
import { formatPrice } from '../../biz/order';
import { getOrderContentType } from '../../biz/order';
import { getTimezoneName, getTime } from '../../biz/time';
import styles from './index.module.less';

interface Props {
  data: any;
}

export const TaskCard: React.FC<Props> = ({
  data: { order, influencer, campaign, product, timeline } = {}
}) => {
  // postDeadline
  const postDeadline = (createdAt: string | Date | null) => {
    if (!createdAt) return '--';
    return new Date(createdAt)
      .toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short'
      })
      .replace(' at ', ', ');
  };

  return (
    <div className={styles.taskCard}>
      <div className={styles.cardTitle}>
        {/* <img
          alt="product-logo"
          className={styles.titleIcon}
          src={ `/c-detail-task.png`}
        /> */}
        <div className="w-20 h-20 border border-[#E8E8EC] overflow-hidden flex justify-center items-center rounded-full bg-white mr-5">
          <img
            className="max-w-20 max-h-20"
            alt="product-logo"
            src={product?.logo || `/c-detail-task.png`}
            onLoad={(e) => {
              const img = e.target as HTMLImageElement;
              const aspectRatio = Math.abs(
                img.naturalWidth / img.naturalHeight
              );
              if (aspectRatio === 1) {
                img.style.padding = '20%'; // 正方形图片 1:1
              } else if (aspectRatio > 2 || aspectRatio < 0.5) {
                img.style.padding = '5%'; // 长宽比 > 2:1 或 < 1:2
              } else {
                img.style.padding = '10%'; // 1:2 <= 比例 <= 2:1
              }
            }}
          />
        </div>
        <div className={styles.title}>
          <div translate="no" className={styles.campaignName}>
            {campaign?.name}
          </div>
          <div className={styles.titleDesc}>
            <span className="mr-1">Post deadline:</span>
            {timeline?.postDeadline?.deadlineTs ? (
              <span className="text-right">
                {getTime(timeline?.postDeadline?.deadlineTs)}
              </span>
            ) : null}
          </div>
          {timeline?.postDeadline?.deadlineTs ? (
            <div className={styles.titleDesc}>
              ({getTimezoneName(timeline?.postDeadline?.deadlineTs)})
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.cardDesc}>
        <div className={styles.descItem}>
          {/* { order?.platform === 'tiktok' ? <img src="/c-tiktok.png" className={styles.cardIcon}/> : <span className={styles.cardIcon}>{order?.platform}</span> } */}
          <img
            className={styles.cardIcon}
            src={
              order?.platform
                ? `/c-home-${order?.platform}.png`
                : `/c-home-${'youtube'}.png`
            }
            alt={order?.platform}
            // onError={({ currentTarget }) => {
            //   currentTarget.onerror = null;
            //   currentTarget.src = '/c-logo.png';
            // }}
          />
          <div className={styles.itemType}>Platform</div>
        </div>
        <div className={styles.splitLine} />
        <div className={styles.descItem}>
          <div translate="no" className={styles.price}>
            {order?.price ? formatPrice(order?.price) : '--'}
          </div>
          <div className={styles.itemType}>Reward</div>
        </div>
        <div className={styles.splitLine} />
        <div className={styles.descItem}>
          <img alt="video" src="/c-video.png" className={styles.cardIcon} />
          {/* {getOrderContentType(order).includes('Video') ? (
            <img src="/c-video.png" className={styles.cardIcon} />
          ) : (
            <img src="/c-image_text.png" className={styles.cardIcon} />
          )} */}
          <div className={styles.itemType}>Content Type</div>
        </div>
      </div>
    </div>
  );
};
