import { OrderStatus, OrderContentType } from '../interface/order';
import { PlatformItems } from '../interface/common';

export const getOrderStep = (order: any) => {
  const status = order?.status;
  if (
    [
      OrderStatus.Created,
      OrderStatus.Inviting,
      OrderStatus.Invited,
      OrderStatus.CreatorApplied,
      OrderStatus.Cancelled
    ].includes(status)
  )
    return 0;
  if ([OrderStatus.UnderProduction].includes(status))
    return order.version >= 1 ? 2 : 0;
  if ([OrderStatus.UnderReview].includes(status)) return 1;
  if ([OrderStatus.PendingPublish].includes(status)) return 3;
  if ([OrderStatus.PendingPayment].includes(status)) return 4;
  if (
    [
      OrderStatus.PaymentSubmitted,
      OrderStatus.Paid,
      OrderStatus.PaymentFailed,
      OrderStatus.Completed
    ].includes(status)
  )
    return 5;
  return 0;
};

export const getOrderContentType = (order: any) => {
  const contentType = order?.contentType;
  if (
    [
      OrderContentType.ImageText,
      OrderContentType.IMAGETEXT,
      OrderContentType.IMAGE_TEXT
    ].includes(contentType)
  )
    return 'Image & Text';
  if (
    [
      OrderContentType.Video,
      OrderContentType.SHORTVIDEO,
      OrderContentType.VIDEO
    ].includes(contentType)
  )
    return 'Video';
  if (
    [OrderContentType.LONGVIDEO, OrderContentType.DEDICATED_VIDEO].includes(
      contentType
    )
  )
    return 'Dedicated Video';
  if (
    [OrderContentType.MIDROLL, OrderContentType.INTEGRATED_VIDEO].includes(
      contentType
    )
  )
    return 'Integrated Video';
  // 有默认值
  return 'Image & Text';
};

export const getOrderContentGenre = (order: any) => {
  const contentGenre = order?.contentGenre || order?.contentForm;
  // 处理大小写
  return typeof contentGenre !== 'string'
    ? '-'
    : contentGenre
        .split(/\s+/)
        .map((word) =>
          word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : ''
        )
        .join(' ');
};

export const getOrderPlatform = (order: any) => {
  let platform = order?.platform;

  if (typeof platform !== 'string') return '-';
  if (platform === '__platform__') return 'platform';
  platform = platform.toLowerCase();
  // 处理大小写
  if (PlatformItems.TIKTOK.toLowerCase() === platform) return 'TikTok';
  if (PlatformItems.YOUTUBE.toLowerCase() === platform) return 'YouTube';
  if (PlatformItems.LINKEDIN.toLowerCase() === platform) return 'LinkedIn';
  return `${platform.charAt(0).toUpperCase()}${platform.slice(1)}`;
};

export const formatPrice = (price: number) => {
  if (!isNaN(parseFloat(String(price))) && isFinite(price)) {
    if (price < 1000) {
      return `$${Math.floor(price)}`;
    } else if (price >= 1000 && price < 10000) {
      return `$${Math.floor(price / 100) / 10}K`;
    } else if (price >= 10000 && price < 1000000) {
      return `$${Math.floor(price / 1000)}K`;
    } else if (price >= 1000000 && price < 10000000) {
      return `$${Math.floor(price / 100000) / 10}M`;
    } else if (price >= 10000000) {
      return `$${Math.floor(price / 1000000)}M`;
    }
  } else {
    return '';
  }
};

export const getUploadedRecordsFromActivity = (activity: any[]): any[] => {
  if (Array.isArray(activity) && activity.length > 0) {
    let scriptVersion = 1;
    let draftVersion = 1;
    let scriptConfirmed = null;
    let draftConfirmed = null;
    activity.reverse();
    activity.forEach((item: any) => {
      if (item.targetStatus === OrderStatus.PendingPayment) {
        item.contentType = 'final';
      }
      const feedback = activity.find(
        (e: any) =>
          (e.payload?.draft_id === item._id &&
            e.sourceStatus === OrderStatus.UnderReview &&
            e.targetStatus === OrderStatus.UnderProduction) ||
          (e.sourceStatus === OrderStatus.ReviewRejected &&
            e.targetStatus === OrderStatus.Cancelled)
      );

      scriptConfirmed = activity.find(
        (item: any) =>
          item.sourceStatus === OrderStatus.UnderReview &&
          item.targetStatus === OrderStatus.UnderProduction &&
          item.payload?.contentStage === 'Script' &&
          item.text === 'Under_review --> Under_production'
      );
      draftConfirmed = activity.find(
        (item: any) =>
          item.sourceStatus === OrderStatus.UnderReview &&
          item.targetStatus === OrderStatus.PendingPublish
      );

      if (
        item.sourceStatus === OrderStatus.UnderProduction &&
        item.targetStatus === OrderStatus.UnderReview
      ) {
        if (item.payload?.contentStage === 'Script') {
          item.contentType = 'script';
          item.version = scriptVersion;
          feedback && (item.feedback = feedback);
          scriptVersion++;
        } else {
          item.contentType = 'draft';
          item.version = draftVersion;
          feedback && (item.feedback = feedback);
          draftVersion++;
        }
      }
    });
    activity.reverse();

    // 找到 draft 的所有
    const script = activity.filter((e: any) => e.contentType === 'script');
    const draft = activity.filter((e: any) => e.contentType === 'draft');
    const final = activity.filter((e: any) => e.contentType === 'final');
    script.length > 0 && (script[0].confirmed = Boolean(scriptConfirmed));
    draft.length > 0 && (draft[0].confirmed = Boolean(draftConfirmed));

    return [...final, ...draft, ...script];
  } else {
    return [];
  }
};
