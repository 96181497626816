import React from 'react';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import styles from './submitFinalLink.module.less';
import './custom.less';
import { getOrderId, getToken, getLoginPageUrl } from '@/biz/tool';

export const SubmissionHistoryButton: React.FC = () => {
  const navigate = useNavigate();
  const orderId = getOrderId();
  return (
    <div id="bottom" className={styles.bottom}>
      <Button
        onClick={() => {
          if (!getToken()) {
            navigate(getLoginPageUrl());
            return;
          }
          navigate(`/campaign/delivery/${orderId}`);
        }}
        variant="contained"
        className={styles.cancelButton}
      >
        Submission history
      </Button>
    </div>
  );
};
