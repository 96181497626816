import React, { useState, useRef, useEffect } from 'react';
import { GoogleIconWhite } from '../../components/common/icon';
import axiosInstance from '../../apis/axiosInstance';
import toast from 'react-hot-toast';
import { getOrderId, getJoinCampaign } from '@/biz/tool';
import { LoginButtonNoStyle } from '@/components/campaign/loginButtonNoStyle';
import { Tracker } from '@/utils';

interface Props {
  desc: any;
}

export const LoginDetailBottomButton: React.FC<Props> = ({ desc }) => {
  const [loginLoading, setLoginLoading] = useState(false);

  return (
    <div
      id="bottom"
      className="fixed bottom-0 p-4 bg-white border-t border-[#e8e8ec]"
    >
      <LoginButtonNoStyle
        initAccessGoogle
        buttonLoading={loginLoading}
        setButtonLoading={setLoginLoading}
      >
        <div className="w-[358px] min-h-[42px] px-4 py-2 bg-[#5a31f0] rounded-[999px] justify-center items-center gap-3 inline-flex cursor-pointer">
          <GoogleIconWhite className="w-6 h-6" />
          <div className="flex-col justify-center items-start inline-flex">
            <div className="text-white text-base font-semibold font-['Geist'] leading-normal w-[140px] text-nowrap">
              {loginLoading ? 'Logging in with Google...' : 'Login with Google'}
            </div>
            {desc ? (
              <div className="text-white/60 text-xs font-normal font-['Inter']">
                {desc}
              </div>
            ) : null}
          </div>
        </div>
      </LoginButtonNoStyle>
    </div>
  );
};
