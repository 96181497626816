import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/campaign/campaignHeader';
import { getOrderPlatform } from '../../biz/order';
import { Tracker } from '../../utils';
import styles from './linked.module.less';

const textMap = {
  success: {
    img: '/c-linked.png',
    title: (platform: any) => `${getOrderPlatform({ platform })} connected!`,
    desc: (platform: any) =>
      `Your ${getOrderPlatform({ platform })} account has been successfully linked to your Aha account. Start your journey now!`,
    button: 'Go to Campaign Details'
  },
  fail: {
    img: '/c-failed.png',
    title: (platform: any) =>
      `Couldn't connect to ${getOrderPlatform({ platform })}`,
    desc: (platform: any) => (
      <div className="text-left bg-[#F9F9FB] rounded-lg px-[6px] py-4 mt-1">
        <p className="mb-2">1. Try logging in again.</p>
        <p>
          {`2. Be sure to use the ${getOrderPlatform({ platform })} account associated with this Aha collaboration.`}
        </p>
      </div>
    ),
    button: 'Return to login'
  }
};

const LinkedPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get('status');
  const platform = query.get('platform');
  const redirect =
    query.get('redirect') || window.localStorage.getItem('auth_redirect');

  let isSuccess = status === 'success';

  // TODO: 根据上面的 platform 处理 图片 和 文案
  let currentText = textMap[isSuccess ? 'success' : 'fail'];

  return (
    <>
      <Header title="Log in" />
      <div className={styles.linked}>
        <img src={currentText.img} className={styles.linkedImg} />
        <div className={styles.title}>{currentText.title(platform)}</div>
        <div className={styles.desc}>{currentText.desc(platform)}</div>
        {isSuccess ? (
          <div className={styles.imgCtn}>
            {/* // TODO: 缺 Twitter 的 logo */}
            <div className={styles.platformLogoCtn}>
              <img
                src={`/c-${'youtube-60x60'}.png`}
                className={styles.platformLogo}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/c-linkedLogo.png';
                }}
              />
            </div>
            <img src="/c-brokenIcon.png" className={styles.brokenIcon} />
            <img src="/c-linkedLogo.png" className={styles.linkedLogo} />
          </div>
        ) : null}
        {redirect && (
          <NavLink className={styles.buttonBox} to={redirect}>
            <div
              onClick={() => {
                Tracker.click(currentText.button);
              }}
              className={styles.button}
            >
              {currentText.button}
            </div>
          </NavLink>
        )}
      </div>
    </>
  );
};

export default LinkedPage;
