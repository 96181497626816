import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Radio from '@material-ui/core/Radio';
import { NavLink, useNavigate } from 'react-router-dom';
import { ExclamationTipsIcon } from '../common/icon';
import { OrderStatus } from '../../interface/order';
import { Tracker } from '../../utils';
import styles from './submitDraftButton.module.less';
import './custom.less';
import { useCountDown } from '../../hooks/useCountdown';
import { getToken, getLoginPageUrl } from '@/biz/tool';

interface Props {
  data: any;
}

const submitMap = {
  Script: 'submitScript',
  Draft: 'submitDraft'
};

const Feedback: React.FC<{
  feedback: any;
  isOpen: boolean;
  setIsOpen: Function;
  text: any;
  order: any;
}> = ({ isOpen, setIsOpen, text, order, feedback }) => {
  const [method, setMethod] = useState('none');
  return (
    <Drawer
      id="submitDraftCtn"
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        Tracker.click('view feedback');
      }}
    >
      <div className={styles.content}>
        <div className={styles.drawerTitle}>
          Feedback{' '}
          <img
            src="/c-closeIcon.png"
            className={styles.closeIcon}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className={styles.drawerDesc}>
          {Array.isArray(text) &&
            text.map((item: any, index: number) => (
              <div key={index} className={styles.li}>
                {item}
              </div>
            ))}
        </div>
        <Button
          variant="contained"
          className={styles.submitButton}
          onClick={() => {
            setIsOpen(false);
            Tracker.click('view feedback');
            // window.location.href = `/campaign/delivery/${order?._id}`;
          }}
        >
          Got it
        </Button>

        <div className={styles.submitButtonHint}>
          * You can revisit feedback anytime in "Submission history"
        </div>
        <div className="text-xs text-center text-[#b9bbc6] mt-2 leading-tight">
          {feedback?.isScript
            ? 'For any questions regarding script feedback contact'
            : 'For any questions regarding draft video feedback contact'}{' '}
          <a
            href="mailto:support@ahalab.ai"
            className="text-center text-[#0c67e6] font-normal font-['Inter'] underline leading-snug ml-1"
          >
            support@ahalab.ai
          </a>
        </div>
      </div>
    </Drawer>
  );
};

const DraftType: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
  order: any;
}> = ({ isOpen, setIsOpen, order }) => {
  const [method, setMethod] = useState('none');
  return (
    <Drawer
      id="submitDraftCtn"
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles.content}>
        <div className={styles.drawerTitle}>
          Submit video draft{' '}
          <img
            src="/c-closeIcon.png"
            className={styles.closeIcon}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className={styles.drawerDesc}>
          How would you like to submit your draft?
        </div>
        <div
          className={styles.radioCtn}
          onClick={() => {
            setMethod(method === 'content' ? 'none' : 'content');
          }}
        >
          <div className={styles.radio}>
            <Radio checked={method === 'content'} />
          </div>
          Manually upload
        </div>
        <div
          className={styles.radioCtn}
          onClick={() => {
            setMethod(method === 'link' ? 'none' : 'link');
          }}
        >
          <div className={styles.radio}>
            <Radio checked={method === 'link'} />
          </div>
          Provide URL
        </div>
        <NavLink to={`/campaign/draft/upload/${method}?orderId=${order?._id}`}>
          <Button variant="contained" className={styles.submitButton}>
            Confirm
          </Button>
        </NavLink>
        <Button
          variant="contained"
          className={styles.cancelButton}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </Drawer>
  );
};

export const SubmitScriptOrDraftButton: React.FC<Props> = ({
  data: { order, activity = [], timeline } = {}
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isShowFeedback, setIsShowFeedback] = useState(false);
  const [isShowSubmissionHistoryUnread, setIsShowSubmissionHistoryUnread] =
    useState(false);
  const [feedback, setFeedback] = useState<any>({});
  const [isScript, setIsScript] = useState(false);

  // draft
  const draftDeadline = (createdAt?: string | Date | null) => {
    if (!createdAt) return;
    return new Date(createdAt).getTime();
  };
  const scriptCountDown = draftDeadline(
    timeline?.initialScriptDraft?.deadlineTs
  );
  const draftCountDown = draftDeadline(timeline?.draft?.deadlineTs);

  const [day, hour, minute, second] = useCountDown(draftCountDown);
  const [script_day, script_hour, script_minute, script_second] =
    useCountDown(scriptCountDown);

  useEffect(() => {
    if (order?.contentStage === 'Script') {
      setIsScript(true);
    }
  }, [order.contentStage]);

  useEffect(() => {
    if (
      order?.status === OrderStatus.UnderProduction &&
      Array.isArray(activity) &&
      activity.length > 0
    ) {
      const feedbackActivity = activity.find(
        (item: any) =>
          (item.sourceStatus === OrderStatus.UnderReview &&
            item.targetStatus === OrderStatus.UnderProduction) ||
          (item.sourceStatus === OrderStatus.ReviewRejected &&
            item.targetStatus === OrderStatus.Cancelled)
      );

      const text = feedbackActivity?.text;
      const id = feedbackActivity?._id;
      if (text) {
        if (
          feedbackActivity?.payload?.contentStage !== 'Script' ||
          (feedbackActivity?.payload?.contentStage === 'Script' &&
            feedbackActivity?.payload?.draft_id)
        ) {
          // 1种情况，draft
          // 2种情况，script
          // 不弹窗的情况：审核通过，没有draft_id
          setFeedback({
            isScript: feedbackActivity?.payload?.contentStage === 'Script',
            id,
            text: text.split(/\r?\n/)
          });
          const readFeedbackId = window.localStorage.getItem('readFeedbackId');
          if (readFeedbackId !== id) {
            setIsShowFeedback(true);
            setIsShowSubmissionHistoryUnread(true);
          }
        }
      }
    }
  }, [activity, order]);

  return (
    <div id="bottom" className={styles.bottom}>
      {/* // TODO: 如果没有 Feedback, 样式要改? */}
      {/* // TODO: 如果已经提交了 Draft, 样式要改 */}
      {/* // TODO: 倒计时 */}
      {/* //TODO：根据接口返回的状态动态决定是否展示 */}
      {/* 每次都弹 问题不大 */}
      {feedback?.text && isShowFeedback ? (
        <Feedback
          feedback={feedback}
          isOpen={isShowFeedback}
          setIsOpen={setIsShowFeedback}
          text={feedback.text}
          order={order}
        />
      ) : (
        <>
          <div className={styles.title}>
            {isScript ? (
              <>
                Time left to send script:{' '}
                <span translate="no" className={styles.subTitle}>
                  {script_day
                    ? `${script_day}d ${script_hour}h ${script_minute}m ${script_second}s`
                    : `${script_hour}h ${script_minute}m ${script_second}s`}
                </span>
              </>
            ) : (
              <>
                Time left to send draft:{' '}
                <span translate="no" className={styles.subTitle}>
                  {day
                    ? `${day}d ${hour}h ${minute}m ${second}s`
                    : `${hour}h ${minute}m ${second}s`}
                </span>
              </>
            )}
          </div>
          <div className="bg-white w-full flex gap-2 mt-3">
            <div
              className={
                isShowSubmissionHistoryUnread
                  ? styles.unreadSubmissionHistory
                  : styles.submissionHistory
              }
              onClick={() => {
                if (!getToken()) {
                  navigate(getLoginPageUrl());
                  return;
                }
                window.localStorage.setItem('readFeedbackId', feedback?.id);
                navigate(`/campaign/delivery/${order?._id}`);
              }}
            >
              <Button variant="contained" className={styles.cancelButton}>
                Submission history
                {isShowSubmissionHistoryUnread && (
                  <div className={styles.unreadFeedback}>1</div>
                )}
              </Button>
            </div>
            <Button
              variant="contained"
              className={styles.button}
              onClick={() => {
                if (!getToken()) {
                  navigate(getLoginPageUrl());
                  return;
                }
                if (isScript) {
                  Tracker.click('submit script');
                  navigate(
                    `/campaign/draft/upload/link?orderId=${order?._id}&type=script`,
                    {
                      replace: true
                    }
                  );
                } else {
                  Tracker.click('submit video draft');
                  navigate(
                    `/campaign/draft/upload/link?orderId=${order?._id}&type=draft`,
                    {
                      replace: true
                    }
                  );
                }
              }}
            >
              {isScript ? 'Submit script' : 'Submit video draft'}
            </Button>
          </div>
        </>
      )}
      <DraftType isOpen={isOpen} setIsOpen={setIsOpen} order={order} />
    </div>
  );
};
