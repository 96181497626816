import { datafluxRum } from '@cloudcare/browser-rum';
import { useLoginStore } from '../hooks/useLoginStore';

export const isMobile = () => {
  if (window.navigator) {
    const ua = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipod|phone|ipad/i.test(ua);
  } else {
    return false;
  }
};

export const isWebPage = () => {
  const pathname = window.location.pathname;
  return pathname.startsWith('/web/campaign/') || pathname.startsWith('/web');
};

export const isWebOnPc = () => {
  return !isMobile() && isWebPage();
};

export const getOrderIdFromUrl = () => {
  try {
    // orderIdFromUrlSearch 访问链接
    // /campaign/home/xxx
    // /campaign/detail/xxx
    // /campaign/delivery/xxx
    // /draft/detail/xxx
    const pathname = window.location.pathname;
    const orderIdRegex =
      /\/campaign\/(home|detail|delivery|draft\/detail)\/([a-f0-9]{24})/;
    const regArray = pathname.match(orderIdRegex);
    return regArray?.[2] || '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getOrderIdFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('orderId');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getRouterFromUrlSearch = () => {
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('router') || 'home';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getOrderIdFromGoogleState = () => {
  try {
    const query = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    const state = query.get('state') || '';
    let orderId;
    if (state) {
      try {
        const payload = JSON.parse(state);
        orderId = payload.orderId;
      } catch (e) {
        console.error('failed to parse state', e);
      }
    }
    return orderId;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getOrderId = () => {
  const orderId =
    getOrderIdFromUrl() ||
    getOrderIdFromUrlSearch() ||
    getOrderIdFromGoogleState() ||
    '';
  try {
    if (!orderId) {
      const error = new Error(
        `MOBILE: No orderid!!!, homepage is ${window.localStorage.getItem('homepage')}`
      );
      datafluxRum && datafluxRum.addError(error);
    }
  } catch (e) {
    console.log(e);
  }

  return orderId;
};

const getGoogleState = (type: 'joinCampaign' | 'orderId') => {
  try {
    const query = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    const state = query.get('state') || '';
    let payload;
    let result;
    if (state) {
      try {
        payload = JSON.parse(state);
        result = payload[type];
      } catch (e) {
        console.error('failed to parse state', e);
      }
    }
    return result;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getJoinCampaign = () => {
  // 获取 ?joinCampaign=xxx 和 ?=xxx&joinCampaign=xxx 的情况
  // and 获取 google 返回的 joinCampaign 参数
  const joinCampaignFromGoogleState = getGoogleState('joinCampaign');
  if (
    joinCampaignFromGoogleState === true ||
    joinCampaignFromGoogleState === 'true'
  ) {
    return true;
  }

  try {
    const search = window.location.search;
    const joinCampaign = new URLSearchParams(search)?.get('joinCampaign');
    if (joinCampaign === 'true') {
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getLoginPageUrl = (params?: any) => {
  const orderId = getOrderId();
  // 需要优化链接拼接参数。
  let loginPageUrl = `/campaign/login`;
  if (orderId) {
    if (params?.joinCampaign) {
      loginPageUrl = `/campaign/login?orderId=${orderId}&joinCampaign=true`;
    } else {
      loginPageUrl = `/campaign/login?orderId=${orderId}`;
    }
  }
  return loginPageUrl;
};

export const getAuthPageUrl = () => {
  const orderId = getOrderId();

  let authPageUrl = `/auth`;
  if (orderId) {
    authPageUrl = `/auth?orderId=${orderId}`;
  }
  return authPageUrl;
};

export const getHomePageUrl = () => {
  const orderId = getOrderId();
  return `/campaign/home/${orderId}`;
};

export const getDetailPageUrl = (params?: any) => {
  const orderId = getOrderId();
  if (params?.loginSuccess) {
    return `/campaign/detail/${orderId}?loginSuccess=true`;
  }
  return `/campaign/detail/${orderId}`;
};

export const getLoginSuccessFromUrlSearch = () => {
  // 获取 ?orderId=xxx 和 ?a=xxx&orderId=xxx 的情况
  try {
    const search = window.location.search;
    return new URLSearchParams(search)?.get('loginSuccess');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const getQrCodeLink = () => {
  const origin = window.location.origin;
  const router = getRouterFromUrlSearch();
  const orderId = getOrderIdFromUrlSearch();
  if (router && orderId) {
    return `${origin}/campaign/${router}/${orderId}`;
  }
  return '';
};

export const getOrderIdAndRouterFromUrl = () => {
  const pathname = window.location.pathname;
  const orderIdRegex =
    /\/campaign\/(home|detail|delivery|draft\/detail)\/([a-f0-9]{24})/;
  const regArray = pathname.match(orderIdRegex);
  const router = regArray?.[1] === 'detail' ? 'detail' : 'home';
  const orderId = getOrderId();
  return {
    router,
    orderId
  };
};

export const getRouterFromUrl = (): 'home' | 'detail' => {
  // orderIdFromUrl 直接访问 /home /detail /delivery 可取的
  const pathname = window.location.pathname;
  const orderIdRegex =
    /\/campaign\/(home|detail|delivery|draft\/detail)\/([a-f0-9]{24})/;
  const regArray = pathname.match(orderIdRegex);
  const router = regArray?.[1] === 'detail' ? 'detail' : 'home';
  return router as 'home' | 'detail';
};

export const getRealRouterFromUrl = ():
  | 'home'
  | 'detail'
  | 'delivery'
  | 'draft/detail'
  | '' => {
  const pathname = window.location.pathname;
  const orderIdRegex =
    /\/campaign\/(home|detail|delivery|draft\/detail)\/([a-f0-9]{24})/;
  const regArray = pathname.match(orderIdRegex);
  const router = regArray?.[1] || '';
  return router as 'home' | 'detail' | 'delivery' | 'draft/detail' | '';
};

export const getToken = () => {
  return window.localStorage.getItem('token');
};

export const getLinkPageUrl = ({ status, platform, redirect }: any) => {
  // eg `/campaign/linked?status=fail&platform=${encodeURIComponent(orderPlatform)}&redirect=${loginPageUrl}`
  let url = `/campaign/linked?status=${status}&platform=${encodeURIComponent(platform)}`;
  const orderId = getOrderId();
  if (orderId) {
    url += `&orderId=${orderId}`;
  }
  // redirect参数是链接，要加在最后面，或者后面优化用encodeURIComponent传输
  if (redirect !== false) {
    url += `&redirect=${redirect || getLoginPageUrl()}`;
  }

  return url;
};

export const getErrorPageUrl = () => {
  return `/campaign/error?orderId=${getOrderId()}`;
};

export const getLoginErrorPageUrl = (params?: any) => {
  if (params?.type) {
    return `/campaign/loginError?orderId=${getOrderId()}&type=${params?.type}`;
  }
  return `/campaign/loginError?orderId=${getOrderId()}`;
};

export const checkLogin = (
  detailData: any
): {
  isLogin: boolean;
  isLoginMatch: boolean;
  influencerUserGooglePlatformId: string;
} => {
  const {
    setIsLogin,
    setIsLoginMatch,
    setThisGooglePlatformId,
    setOrderUserGooglePlatformId,
    setOrderUserGooglePlatformEmail
  } = useLoginStore.getState();
  const { influencer, platform = [] } = detailData;
  const influencerUserPlatform = influencer?.user?.platform || [];

  const thisGooglePlatformId =
    platform?.find &&
    platform.find((p: any) => p.platform === 'google')?.platformId;
  const influencerUserGooglePlatformId = influencerUserPlatform.find(
    (op: any) => op.platform === 'google'
  )?.payload?.id;
  const influencerUserGooglePlatformEmail = influencerUserPlatform.find(
    (op: any) => op.platform === 'google'
  )?.payload?.email;
  // 检查google平台id是否匹配
  const maskedEmail = influencerUserGooglePlatformEmail
    ? maskEmail(influencerUserGooglePlatformEmail)
    : '';
  setThisGooglePlatformId(thisGooglePlatformId);
  setOrderUserGooglePlatformId(influencerUserGooglePlatformId);
  setOrderUserGooglePlatformEmail(maskedEmail);

  let isLogin = false;
  let isLoginMatch = false;

  if (platform) {
    // 有 platform 说明登录了
    isLogin = true;
  } else {
    isLogin = false;
  }

  if (!influencerUserGooglePlatformId) {
    // 未绑定过
  }

  if (influencerUserGooglePlatformId) {
    isLoginMatch =
      thisGooglePlatformId &&
      influencerUserGooglePlatformId &&
      thisGooglePlatformId === influencerUserGooglePlatformId;
  } else {
    // 未绑定过
    isLoginMatch = true;
  }

  // 标准逻辑 绑定过 ，进行登录匹配判定。
  setIsLogin(isLogin);
  setIsLoginMatch(isLoginMatch);
  return {
    isLogin,
    isLoginMatch,
    influencerUserGooglePlatformId
  };
};

export const checkLoginAndMatch = (detailData: any) => {
  const { isLogin, isLoginMatch, influencerUserGooglePlatformId } =
    checkLogin(detailData);
  if (!influencerUserGooglePlatformId) {
    // 未绑定过
    return isLogin;
  }
  return isLogin && isLoginMatch;
};

export const maskEmail = (email: string): string => {
  const [username, domain] = email.split('@');
  let maskedUsername = username;

  if (username.length > 4) {
    const start = username.slice(0, 2);
    const end = username.slice(-2);
    maskedUsername = `${start}***${end}`;
  } else if (username.length > 1) {
    maskedUsername = `${username[0]}***${username[username.length - 1]}`;
  } else {
    maskedUsername = '***';
  }

  return `${maskedUsername}@${domain}`;
};

export const clearToken = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('platform');
};
