import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Tracker } from '../../utils';

import styles from './requirementsDialog.module.less';
import './custom.less';

export const RequirementsDialog: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
}> = ({ isOpen, setIsOpen }) => {
  if (isOpen) {
    Tracker.click('content requirements tips');
  }
  return (
    <Dialog id="rDialog" open={isOpen}>
      <DialogContent className={styles.dialog}>
        <img src="/c-dialogIcon.png" className={styles.img} />
        <div className={styles.title}>Content Requirements</div>
        <div className={styles.desc}>
          Read this before you create your content to increase the chance of
          your content getting approved.
        </div>
        <div className={styles.button} onClick={() => setIsOpen(false)}>
          View now
        </div>
      </DialogContent>
    </Dialog>
  );
};
