import React from 'react';

import styles from './footer.module.less';

export const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <img src="/c-footerLogo.png" className={styles.logo} />
      <a href="mailto:support@ahalab.ai">
        <div className={styles.contact}>support@ahalab.ai</div>
      </a>
    </div>
  );
};
