import axiosInstance from './axiosInstance';

const TASK_BASE_URL = '';

export const getTaskById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`${TASK_BASE_URL}/platform/task/${id}`);
    return response.data.data.influencerTask;
  } catch (error) {
    return error;
  }
};

export const getTaskByIdCheckLogin = async (id: string) => {
    try {
      const response = await axiosInstance.get(`${TASK_BASE_URL}/influencerTask/platform/${id}`);
      return response.data.data.influencerTask;
    } catch (error) {
      return error;
    }
  };