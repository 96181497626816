export const getTimezoneName = (timestamp: string | Date) => {
  const today = new Date(timestamp);
  const short = today.toLocaleDateString('en-US');
  const full = today.toLocaleDateString('en-US', { timeZoneName: 'long' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

export const getTime = (timestamp: string | Date) => {
  const today = new Date(timestamp);
  const time = today
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    .replace(' at ', ', ');

  return `${time}`;
  // 'September 30, 2024, 2:53 PM (China Standard Time)'
  // 'September 30, 2024, 4:23 PM (Central Australia Time)'
  // 'September 29, 2024, 11:53 PM (Pacific Time)'
};

export const getDuration = (hours: number) => {
  // if (hours > 48) return `${Math.ceil(hours / 24)} days`;
  // if (hours > 1) return `${Math.ceil(hours)} hours`;
  // return `${Math.ceil(hours)} hour`;
  if (hours === 24) return `1 day`;
  if (hours > 24) return `${Math.ceil(hours / 24)} days`;
  if (hours > 1) return `${hours} hours`;
  return `${Math.ceil(hours / 24)} day`;
};

export const getDurationFormatBusinessDays = (hours: number) => {
  if (hours === 24) return `1 business day`;
  if (hours > 24) return `${Math.ceil(hours / 24)} business days`;
  if (hours > 1) return `${hours}  hours`;
  return `${Math.ceil(hours / 24)} business days`;
};
