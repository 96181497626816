import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../apis/axiosInstance';
import toast from 'react-hot-toast';
import { getOrderId, getJoinCampaign } from '@/biz/tool';
import { Tracker } from '@/utils';

interface Props {
  buttonLoading?: boolean;
  setButtonLoading?: (loading: boolean) => void;
  initAccessGoogle?: boolean; // true 用在detail页面，false 用在loginError页面
  children?: React.ReactNode;
}

export const LoginButtonNoStyle: React.FC<Props> = ({
  buttonLoading,
  setButtonLoading,
  initAccessGoogle = false,
  children
}) => {
  const orderId = getOrderId();
  const toastRef = useRef('');
  const canAccessGoogle = useRef(true);

  useEffect(() => {
    // 先校验是否可以访问Google
    if (initAccessGoogle) {
      googleAccess();
    }
  }, []);

  const googleAccess = async () => {
    try {
      await fetch('https://www.google.com', {
        method: 'HEAD',
        mode: 'no-cors',
        signal: AbortSignal.timeout(3000)
      })
        .then((response) => {
          canAccessGoogle.current = true;
          console.log('Users can access Google.');
        })
        .catch((error) => {
          canAccessGoogle.current = false;
          toast.dismiss(toastRef.current);
          toast.error(
            `Couldn't access Google services. Please check your internet connection and try again.`
          );
          console.error("Users can't access Google, error:", error);
        });
    } catch (error) {
      canAccessGoogle.current = false;
      console.error('googleAccess error: ', error);
    }
  };

  const getUrl = async () => {
    try {
      toast.dismiss(toastRef.current);
      toastRef.current = toast.loading(
        'Accessing Google services, please wait...'
      );

      const {
        data: {
          data: { url }
        }
      } = await axiosInstance.post(`/auth/google`, {
        redirect_uri: `${window.location.origin}/auth/google/callback`,
        orderId,
        joinCampaign: getJoinCampaign()
      });
      // TODO: 错误处理
      if (url) {
        if (canAccessGoogle.current) {
          window.location.href = url;
        } else {
          toast.dismiss(toastRef.current);
        }
      } else {
        toast.dismiss(toastRef.current);
        toast.error('Fail to get Google auth url.');
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (error: any) {
      console.error('Fail to get Google auth url', error);
      toast.dismiss(toastRef.current);
      toast.error('Fail to get Google auth url.');
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div
      onClick={async () => {
        setButtonLoading && setButtonLoading(true);
        if (!buttonLoading) {
          Tracker.click('login with google');
          if (initAccessGoogle && canAccessGoogle.current) {
            //
          } else {
            await googleAccess();
          }
          if (canAccessGoogle.current) {
            await getUrl();
          }
          setTimeout(() => {
            setButtonLoading && setButtonLoading(false);
          }, 3000);
        }
      }}
    >
      {children}
    </div>
  );
};
