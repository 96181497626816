import React, { useEffect, useState } from 'react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import styles from './joinedUser.module.less';

import './custom.less';

interface Props {
  loading?: boolean;
}

let target: any;

export const JoinedUser: React.FC<Props> = ({ loading }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [imgList, setImgList] = useState([999]);
  useEffect(() => {
    if (!loading) {
      setImgList([1, 2, 3, 4, 5, 7, 8, 9]);
    }
  }, [loading]);
  useEffect(() => {
    target = document.getElementById('swiper');

    setInterval(() => {
      if (target.swiper) {
        target.swiper?.slidePrev();
      }
    }, 2000);
  }, []);

  return (
    <div className={styles.userList}>
      <div className="swiper-no-swiping">
        <Swiper
          // install Swiper modules
          id="swiper"
          className={styles.swiper}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={5}
          loop
          // onSwiper={(i: any) => console.log(111, i)}
          onSlideChange={(i: any) => setActiveIndex(i.realIndex)}
        >
          {imgList.map((item, index) => (
            <SwiperSlide
              key={item}
              className={styles.slider}
              style={{ zIndex: 99 - index }}
            >
              <img
                id={`img${item}`}
                src={`/c-user${item}.png`}
                className={styles.img}
                style={{
                  animation: activeIndex == index ? 'slidein 0.5s' : 'none'
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
