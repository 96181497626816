import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../apis/axiosInstance';
import { TaskCard } from '../../components/campaign/detail';
import { Header } from '../../components/campaign/header';
import { JoinCard } from '../../components/campaign/joinCard';
import { MatchCard } from '../../components/campaign/matchCard';
import { StepsCard } from '../../components/campaign/stepsCard';
import { About } from '../../components/campaign/about';
import { Footer } from '../../components/campaign/footer';
import Loading from '../../components/common/loading';
import { getOrderId } from '@/biz/tool';

import styles from './guide.module.less';
import toast from 'react-hot-toast';

const GuidePage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const orderId = getOrderId();

  const [data, setData] = useState({});

  // TODO: 全局统一处理报错 和 loading?
  const getData = async (orderId: string) => {
    try {
      setLoading(true);
      // TODO: 跳转的整体流程之后统一改
      // 登录之后跳转
      window.localStorage.setItem(
        'auth_redirect',
        `/campaign/detail/${orderId}`
      );
      // 所有需要回到首页的地方使用
      window.localStorage.setItem('homepage', `/campaign/detail/${orderId}`);

      const {
        data: { data }
      } = await axiosInstance.get(
        `/platform/order/${encodeURIComponent(orderId)}`
      );

      if (!data.order) {
        navigate('/campaign/error');
      }

      setData(data);
      const orderPlatform = data?.order?.platform;
      if (orderPlatform) {
        window.localStorage.setItem('orderPlatform', orderPlatform);
      }
      if (data?.influencer) {
        window.localStorage.setItem(
          'profile',
          JSON.stringify({
            name: data?.influencer?.name,
            avatar: data?.influencer?.profilePictureLink
          })
        );
      }
    } catch (e: any) {
      toast.error(e.message || 'Fail to get data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getData(orderId);
    }
  }, [orderId]);

  return (
    <div className={styles.bg}>
      {loading ? <Loading open={true} /> : null}
      <Header data={data} />
      <TaskCard data={data} />
      <JoinCard data={data} />
      <MatchCard data={data} />
      <StepsCard />
      <About />
      <Footer />
    </div>
  );
};

export default GuidePage;
