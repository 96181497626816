import styles from './copyToClipboard.module.less';

type CopyToClipboardProps = {
  onClick: () => void;
};

export const CopyToClipboard = (props: CopyToClipboardProps) => {
  const { onClick } = props;
  return (
    <img
      alt="copy"
      src="/c-copy.png"
      className={styles.copyIcon}
      onClick={onClick}
    />
  );
};
