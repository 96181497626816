import React, { useEffect, useState } from 'react';
import axiosInstance from '../../apis/axiosInstance';
import { OrderStatus } from '../../interface/order';
import Header from '../../components/campaign/campaignHeader';
import { ContentRequirements } from '../../components/campaign/contentRequirements';
import { Delivery } from '../../components/campaign/delivery';
import { JoinButton } from '../../components/campaign/joinButton';
import Loading from '../../components/common/loading';
import { getOrderId } from '@/biz/tool';

import styles from './delivery.module.less';

import '../../components/campaign/custom.less';
import toast from 'react-hot-toast';

const DetailPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const orderId = getOrderId();
  const [data, setData] = useState<any>({});

  // TODO: 继续调整不同情况流程展示的逻辑
  // 流程状态
  // OrderStatus.Invited -> 0, 此时可以 joinCampaign
  // OrderStatus.CreatorApplied -> 1, 此时可以添加收款方式(应需要结合是否已经设置了收款方式, 具体看需求)
  // OrderStatus.UnderRevision, OrderStatus.UnderProduction -> 2, 此时可以提交 Draft, 可查看 Feedback. tabId 应该为 1(?)
  let [state, setState] = useState(2);

  // TODO: 全局统一处理报错 和 loading?
  const getData = async (orderId: string) => {
    try {
      setLoading(true);
      // TODO: 跳转的整体流程之后统一改
      // 登录之后跳转
      window.localStorage.setItem(
        'auth_redirect',
        `/campaign/detail/${orderId}`
      );
      // 所有需要回到首页的地方使用
      window.localStorage.setItem('homepage', `/campaign/detail/${orderId}`);
      const {
        data: { data }
      } = await axiosInstance.get(
        `/order/detail/${encodeURIComponent(orderId)}`
      );

      setData(data);
      const orderPlatform = data?.order?.platform;
      if (orderPlatform) {
        window.localStorage.setItem('orderPlatform', orderPlatform);
      }
      const order_id = data?.order?._id;
      if (order_id) {
        const {
          data: {
            data: { activity: activityData }
          }
        } = await axiosInstance.get(
          `/order/${encodeURIComponent(order_id)}/activities`
        );
        let activity = activityData?.filter((el: any) => el.action !== 'edm');
        if (Array.isArray(activity) && activity.length > 0) {
          let scriptVersion = 1;
          let draftVersion = 1;
          activity.reverse();
          activity = activity.map((item) => {
            if (item.targetStatus === OrderStatus.UnderReview) {
              if (item.payload?.contentStage !== 'Script') {
                item.version = draftVersion;
                draftVersion++;
              } else {
                item.version = scriptVersion;
                scriptVersion++;
              }
            }
            return item;
          });
          activity.reverse();
        }
        setData({ ...data, activity });
      }
      if (data?.influencer) {
        window.localStorage.setItem(
          'profile',
          JSON.stringify({
            name: data?.influencer?.name,
            avatar: data?.influencer?.profilePictureLink
          })
        );
      }
      const orderStatus = data?.order?.status;
      if ([OrderStatus.Invited].includes(orderStatus)) {
        setState(0);
      } else if ([OrderStatus.CreatorApplied].includes(orderStatus)) {
        setState(1);
      } else if (
        [OrderStatus.Created, OrderStatus.Inviting].includes(orderStatus)
      ) {
        setState(-1);
      } else if ([OrderStatus.Cancelled].includes(orderStatus)) {
        setState(2);
      } else {
        setState(2);
      }
    } catch (e: any) {
      toast.error(e?.message || 'Fail to get data.');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (orderId) {
      getData(orderId);
    }
  }, [orderId]);

  state = 2;

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <Header title="Submission history" isBack />
      <div id="detail" className={styles.detail}>
        <Delivery data={data} />
        <ContentRequirements data={data} type={'delivery'} />

        {state === 0 ? <JoinButton data={data} /> : null}
      </div>
    </>
  );
};

export default DetailPage;
