import React, { useEffect, useState } from 'react';
import axiosInstance from '../../apis/axiosInstance';
import Header from '../../components/campaign/campaignHeader';
import {
  LinkContent,
  FinalLinkContent
} from '../../components/campaign/deliveryContent';
import { OrderStatus } from '../../interface/order';
import { EmptyPlaceHolder } from '../../components/campaign/emptyPlaceHolder';
import Loading from '../../components/common/loading';
import { getOrderId } from '@/biz/tool';
import { getUploadedRecordsFromActivity } from '@/biz/order';
import styles from './draftDetail.module.less';
import toast from 'react-hot-toast';

const DraftDetail: React.FC = () => {
  const orderId = getOrderId();
  const [loading, setLoading] = useState(true);
  const [uploadedRecords, setUploadedRecords] = useState<any[]>([]);

  const getData = async (orderId: string | number) => {
    try {
      setLoading(true);
      if (orderId) {
        const {
          data: {
            data: { activity: activityData }
          }
        } = await axiosInstance.get(
          `/order/${encodeURIComponent(orderId)}/activities`
        );
        let activity = activityData?.filter((el: any) => el.action !== 'edm');
        const records = getUploadedRecordsFromActivity(activity);
        setUploadedRecords(records);
      }
    } catch (e: any) {
      toast.error(e.message || 'Fail to get data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getData(orderId);
    }
  }, [orderId]);

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <Header title="Submission history" isBack />
      <div className={styles.detail}>
        {uploadedRecords.length > 0 ? (
          uploadedRecords.map((el: any) => {
            if (el.contentType === 'final') {
              return <FinalLinkContent item={el} />;
            } else if (el.contentType === 'draft') {
              return <LinkContent item={el} />;
            } else if (el.contentType === 'script') {
              return <LinkContent item={el} />;
            }
            return null;
          })
        ) : (
          <EmptyPlaceHolder style={{ marginTop: '25vh' }} />
        )}
      </div>
    </>
  );
};

export default DraftDetail;
