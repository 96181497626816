import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Radio from '@/components/Radio/index';
import Header from '../../components/campaign/campaignHeader';
import toast from 'react-hot-toast';
import { getOrderId } from '@/biz/tool';
import { Tracker } from '../../utils';
import axiosInstance from '../../apis/axiosInstance';
import styles from './paymentMethod.module.less';
import '../../components/campaign/custom.less';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#000000',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '10px 12px',
    maxWidth: '248px',
    marginBottom: '4px',
    marginRight: '44px'
  }
});

const PaymentMethod: React.FC = () => {
  const [method, setMethod] = useState('bank');
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState('bank');
  const orderId = getOrderId();

  const getPaymentAccount = async () => {
    try {
      const {
        data: { data }
      } = await axiosInstance.get(`/payment/account`);
      setCurrentPaymentMethod(data?.payment?.accountType);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentAccount();
  }, []);

  return (
    <>
      <Header title="Payment method" isBack />
      <div id="drawerCtn" className={styles.payment}>
        <img alt="loginLogo" src="/c-ahaLogo.png" className={styles.logoImg} />
        <div className={styles.title}>Set up your payment method</div>
        <div className={styles.desc}>
          Choose your preferred payment method and start earning with ease.
        </div>

        <div
          id="payment"
          className={styles.method}
          style={{ borderColor: method === 'bank' ? '#5B3DE9' : '#E8E8EC' }}
          onClick={() => {
            setMethod(method === 'bank' ? 'none' : 'bank');
          }}
        >
          <div className={styles.radio}>
            <Radio checked={method === 'bank'} />
          </div>
          <div className={styles.methodTitle}>
            <div className="flex">
              <div className={styles.label}>Bank transfer</div>
              {currentPaymentMethod === 'airwallex' && (
                <div className={styles.tag}>Current</div>
              )}
            </div>
            <div className="flex flex-col">
              <div className={styles.methodDesc}>
                <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                Up to 7 business day
              </div>
              <div className={`${styles.methodDesc}`}>
                <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                Fees may apply. More details in{' '}
                <a
                  target="_blank"
                  className="ml-1 underline text-[#0c67e6]"
                  href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
                  rel="noreferrer"
                >
                  Aha Docs
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="payment"
          className={styles.method}
          style={{ borderColor: method === 'paypal' ? '#5B3DE9' : '#E8E8EC' }}
          onClick={() => {
            setMethod(method === 'paypal' ? 'none' : 'paypal');
          }}
        >
          <div className={styles.radio}>
            <Radio checked={method === 'paypal'} />
          </div>
          <div className={styles.methodTitle}>
            <div className="flex">
              <div className={styles.label}>PayPal</div>
              {currentPaymentMethod === 'paypal' && (
                <div className={styles.tag}>Current</div>
              )}
            </div>
            <div className="flex flex-col">
              <div className={styles.methodDesc}>
                <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                Up to 1 business day
              </div>
              <div className={`${styles.methodDesc}`}>
                <div className="w-1 h-1 mr-[6px] bg-[#60646c] rounded-full" />
                Fees may apply. More details in{' '}
                <a
                  target="_blank"
                  className="ml-1 underline text-[#0c67e6]"
                  href="https://www.notion.so/11104daded68803fb924f34bbac22a76?pvs=25#11804daded68807d8342edb667ae343b"
                  rel="noreferrer"
                >
                  Aha Docs
                </a>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          className={styles.submitButton}
          onClick={() => {
            Tracker.click('continue to set up payment');
            if (method === 'none') {
              toast.error('Please select your preferred payment method.');
            } else {
              window.location.href = `/campaign/payment/method/update/${encodeURIComponent(method)}?orderId=${orderId}`;
            }
          }}
        >
          Continue
        </Button>
        <Button
          variant="contained"
          className={styles.cancelButton}
          onClick={() => {
            window.history.back();
            Tracker.click('skip setting up payment');
          }}
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default PaymentMethod;
