import React, { useState, useEffect } from 'react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import styles from './about.module.less';
import './custom.less';

export const About: React.FC = () => {
  return (
    <div className={styles.about}>
      <img alt="logo" src="/c-logo.png" className={styles.logoImg} />
      <div className={styles.title}>About Aha</div>
      <div className={styles.desc}>
        Aha connects influencers with top-tier, well-matched brand deals,
        empowering creators to monetize and create confidently. Trusted by over
        12,000 creators worldwide.
      </div>
      <img alt="office" src="/c-office.png" className={styles.officeImg} />
      <div className={styles.subTitle}>
        2000+ influencers collaborate <br />
        with us
      </div>
      <Swiper
        id="about"
        className={styles.swiper}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        spaceBetween={50}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        loop
        pagination={{ clickable: true }}
        observer={true} // 启用观察器，用于实时更新Swiper
        observeParents={true} // 启用观察器，观察Swiper的父元素变化
        // onSwiper={() => console.log(1)}
        // onSlideChange={() => console.log('slide change')}
      >
        {[
          {
            avatar: '/c-user-0.jpg',
            name: 'Amaliya Tykhovska @shabby7_chichome',
            platform: 'Instagram',
            followers: '131K',
            text: `“Aha's exceptional customer service made the entire process, from content creation to payment, a total breeze!”`
          },
          {
            avatar: '/c-user-1.jpg',
            name: 'Parul Gautam @Parul_Gautam7',
            platform: 'Twitter',
            followers: '38.7K',
            text: `“I've found Aha to be super reliable. The collabs have been efficient, payments timely, and really professional overall.”`
          },
          {
            avatar: '/c-user-2.jpg',
            name: 'Deepanshu Sharma',
            platform: 'Twitter',
            followers: '33.9K',
            text: `“Compared to traditional methods, I'd say Aha platform is far superior by being more efficient and time-saving. Their clear guidelines and smooth communication were key to achieving optimal results.”`
          },
          {
            avatar: '/c-user-3.jpg',
            name: 'Omer H @devopstoolbox',
            platform: 'YouTube',
            followers: '22.7K',
            text: `“Working with Aha was an absolute pleasure. Thanks to Aha's in-depth knowledge of content creation, I was able to improve my content quality and fan engagement.”`
          },
          {
            avatar: '/c-user-4.jpg',
            name: 'Raul Junco',
            platform: 'Twitter',
            followers: '19.5K',
            text: `“When you can write for a product you use and love, something magical clicks. Aha connected me with high-quality advertisers that perfectly aligned with my content and values.”`
          }
        ].map((item, index) => (
          <SwiperSlide key={index} className={styles.slider}>
            <div className={styles.itemCard}>
              <div className={styles.sliderTitle}>
                <div className="h-full flex items-center">{item.text}</div>
              </div>

              <div className={styles.userInfo}>
                <img alt="avatar" src={item.avatar} className={styles.avatar} />
                <div>
                  <div translate="no" className={styles.userName}>
                    {item.name}
                  </div>
                  <div className={styles.userDesc}>
                    {item.platform}, Followers: {item.followers}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
