import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OrderStatus } from '../../interface/order';
import styles from './paymentInfo.module.less';
import { getDuration } from '@/biz/time';

import './custom.less';
import { normalizePrice } from '@/utils/normalizePrice';
interface Props {
  data: any;
}

export const PaymentInfo: React.FC<Props> = ({
  data: { order, timeline, payment } = {}
}) => {
  // payment
  const paymentDeadline = (hours: number | null) => {
    if (hours) return getDuration(hours);
    return '-';
  };
  const paymentStatus = (status: OrderStatus) => {
    if ([OrderStatus.Paid, OrderStatus.Completed].includes(status))
      return 'Delivered';
    if ([OrderStatus.PaymentSubmitted].includes(status)) return 'Submitted';
    if ([OrderStatus.PaymentFailed].includes(status)) return 'Failed';
    return '-';
  };
  return (
    <div id="checkList" className={styles.info}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Payment info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className={styles.text}>
              <div className={styles.label}>Payment amount</div>
              {`${order?.price ? normalizePrice(order.price).replace('$', '') : ''} USD`}
            </div>
            <div className={styles.text}>
              <div className={styles.label}>Payment status</div>
              {paymentStatus(order?.status)}
            </div>
            <div className={styles.text}>
              <div className={styles.label}>Payment method</div>
              {payment?.accountType === 'paypal' ? 'PayPal' : 'Bank'}
            </div>
            <div className={styles.text}>
              <div className={styles.label}>Please note:</div>
            </div>
            <div className={styles.ul}>
              <div className={styles.li}>
                It may take up to{' '}
                {paymentDeadline(timeline?.payment?.maxWaitInHours)} for your
                payment to be processed by your payment method.
              </div>
              <div className={styles.li}>
                If you have any questions, feel free to contact us at{' '}
                <a href="mailto:support@ahalab.ai">
                  <span className={styles.link}> support@ahalab.ai</span>
                </a>
                .
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
