import React from 'react';

import styles from './matchCard.module.less';

interface Props {
  data: any;
}

export const MatchCard: React.FC<Props> = ({
  data: { order, influencer, product, campaign } = {}
}) => {
  return (
    <div className={styles.matchCard}>
      <div className={styles.title}>Shine Bright with a New Partnership</div>
      <div className={styles.desc}>
        Based on Aha's recommendation algorithms, we've found that your creative
        style perfectly aligns with this brand.
      </div>
      <div className={styles.imgCtn}>
        <div className="w-20 h-20 border border-[#E8E8EC] overflow-hidden flex justify-center items-center rounded-full bg-white">
          <img
            className="max-w-20 max-h-20"
            alt="product-logo"
            src={product?.logo || `/c-detail-task.png`}
            onLoad={(e) => {
              const img = e.target as HTMLImageElement;
              const aspectRatio = Math.abs(
                img.naturalWidth / img.naturalHeight
              );
              if (aspectRatio === 1) {
                img.style.padding = '20%'; // 正方形图片 1:1
              } else if (aspectRatio > 2 || aspectRatio < 0.5) {
                img.style.padding = '5%'; // 长宽比 > 2:1 或 < 1:2
              } else {
                img.style.padding = '10%'; // 1:2 <= 比例 <= 2:1
              }
            }}
          />
        </div>
        {/* <img src="/c-platform.png" className={styles.platformImg} /> */}
        <img src="/c-hand.png" className={styles.handImg} />
        <img
          src={influencer?.profilePictureLink}
          className={styles.avatarImg}
          alt={influencer?.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/c-logo.jpg';
            console.error(
              'youtube avatar failed!!! ' + influencer?.profilePictureLink
            );
          }}
        />
      </div>

      {Array.isArray(influencer?.tags) && influencer.tags.length > 0 && (
        <div>
          <div className={styles.match}>Why you're a match</div>
          <div className={styles.tagCtn}>
            {influencer.tags.slice(0, 5).map((tag: string, index: number) => (
              <div key={tag} className={styles.tag}>
                {tag}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
