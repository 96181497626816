import React, { useState, useEffect } from 'react';

import styles from './emptyPlaceHolder.module.less';

export const EmptyPlaceHolder: React.FC<{ style?: any }> = ({ style }) => {
  return (
    <div className={styles.emptyCtn} style={style ? style : {}}>
      <img src="/c-empty.png" className={styles.emptyImg} />
      No content uploaded at the moment
    </div>
  );
};
