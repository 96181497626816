import React from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from "react-router-dom";
import styles from "./index.module.less"

const IndexPage: React.FC = () => {



  return (
    <div className={styles.campaignHome}>
      <img className={styles.image} src="/c-banner.jpg" />
      <div className={styles.campaignContent}>
        <div style={{ width: "20rx" }}>Dear creator_name</div>
        <div className={styles.campaignContentText}>
          You have been approved for
          brand_name's campaign. Submit your content by submission_date to get
          price !
        </div>
        <div className={styles.campaignContentText}>
          You have been approved for
          brand_name's campaign. Submit your content by submission_date to get
          price !You have been approved for
          brand_name's campaign. Submit your content by submission_date to get
          price !You have been approved for
          brand_name's campaign. Submit your content by submission_date to get
          price !
        </div>
      </div>
      <NavLink
        to="/campaign/guide/"
      >
        <Button variant="contained" className={styles.campaignButton}>
          View details
        </Button>
      </NavLink>
      <img className={styles.contactImg} src="/c-contact.png" />
    </div>
  );
};

export default IndexPage;
