import React, { useState, useEffect } from 'react';

import styles from './webloading.module.less';

const WebLoading: React.FC<{
  onClose?: Function;
  open: boolean;
  bgColor?: string;
}> = ({ open, onClose, bgColor = '#00000080' }) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => {
        setIsOpen(false);
        onClose && onClose();
      }, 10000);
    }
  }, [open]);

  if (!isOpen) return null;

  return (
    <div>
      <div className={`${styles.imgCtn}`} style={{ backgroundColor: bgColor }}>
        <img src="/c-rotate.png" className={styles.icon} />
      </div>
    </div>
  );
};

export default WebLoading;
