// /auth/:platform/callback 暂时用不到
import React, { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../apis/axiosInstance';
import { PlatformItems } from '../../interface/common';
import toast, { Toaster } from 'react-hot-toast';
import { getOrderPlatform } from '../../biz/order';
import { getLoginPageUrl, getOrderId, getLinkPageUrl } from '@/biz/tool';

const PlatformCallbackPage: React.FC = () => {
  const toastRef = useRef('');
  const { platform } = useParams();
  const location = useLocation();

  const handleCallback = async () => {
    const search = new URLSearchParams(location.search);
    const hash = new URLSearchParams(location.hash.replace(/^#/, ''));
    const error = hash.get('error');

    const loginPageUrl = getLoginPageUrl();

    // 取消登录 重定向地址为：http://localhost:5001/auth/youtube/callback#error=access_denied
    // youtube
    if (error) {
      console.log('Cancel the youtube authorization: ', error);
    } else {
      toastRef.current = toast.loading(
        `Connecting with ${getOrderPlatform({ platform })} account`
      );
    }

    let payload: any;
    if (platform === PlatformItems.TWITTER) {
      payload = {
        oauth_token: search.get('oauth_token'),
        oauth_verifier: search.get('oauth_verifier')
      };
    } else if (platform === PlatformItems.YOUTUBE) {
      payload = {
        access_token: hash.get('access_token')
      };
    } else if (platform === PlatformItems.LINKEDIN) {
      // StrictMode 下会发生多次请求, 导致 The token used in the request has been revoked by the user
      payload = {
        code: search.get('code'),
        state: search.get('state')
      };
    } else if (platform === PlatformItems.TIKTOK) {
      // 不能使用 http://localhost
      payload = {
        code: search.get('code'),
        state: search.get('state')
      };
    } else if (platform === PlatformItems.INSTAGRAM) {
      payload = {
        access_token: hash.get('access_token')
      };
    }

    payload.orderId = getOrderId();

    if (error === 'access_denied') {
      toast.error(`You have canceled login.`);
      setTimeout(() => {
        window.location.href = getLinkPageUrl({ status: 'fail', platform });
      }, 2500);
      return;
    }

    try {
      const {
        data: { code, message, data }
      } = await axiosInstance.post(`/auth/${platform}/callback`, payload);
      toast.dismiss(toastRef.current);
      console.log('toastRef: ', toastRef);

      if (code === 0 && data?.platform) {
        toast.success('Successfully connected');
        window.localStorage.setItem('platform', JSON.stringify(data?.platform));
        setTimeout(() => {
          window.location.href = getLinkPageUrl({
            status: 'success',
            platform,
            redirect: false
          });
        }, 1000);
      } else {
        toast.error(message || 'Failed to auth.');
        setTimeout(() => {
          window.location.href = getLinkPageUrl({ status: 'fail', platform });
        }, 2500);
      }
    } catch (error: any) {
      console.error('platform callback error', error);
      // 此情况先不报错
      if (error?.message?.includes('failed to get channel info')) {
        window.location.href = getLinkPageUrl({ status: 'fail', platform });
        return;
      }
      toast.dismiss(toastRef.current);
      toast.error(error?.message || 'Failed to auth.');
      setTimeout(() => {
        window.location.href = getLinkPageUrl({ status: 'fail', platform });
      }, 2500);
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <Toaster
      toastOptions={{
        style: {
          fontSize: 12,
          borderRadius: 4,
          background: '#1C2024',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
          color: '#fff'
        }
      }}
    />
  );
};

export default PlatformCallbackPage;
