// /campaign/login/ 废弃
import React, { useState, useRef } from 'react';
import styles from './login.module.less';
import axiosInstance from '../../apis/axiosInstance';
import { GoogleIcon } from '../../components/common/icon';
import { Tracker } from '../../utils';
import toast from 'react-hot-toast';
import { getOrderId, getJoinCampaign } from '@/biz/tool';
// TODO: 与设计稿不一致

const LoginPage: React.FC = () => {
  const orderId = getOrderId();

  const [loginLoading, setLoginLoading] = useState(false);
  const toastRef = useRef('');

  const googleAccess = () => {
    try {
      fetch('https://www.google.com', {
        method: 'HEAD',
        mode: 'no-cors',
        signal: AbortSignal.timeout(3000)
      })
        .then((response) => {
          console.log('Users can access Google.');
        })
        .catch((error) => {
          toast.dismiss(toastRef.current);
          toast.error(
            `Couldn't access Google services. Please check your internet connection and try again.`
          );
          console.error("Users can't access Google, error:", error);
        });
    } catch (error) {
      console.error('googleAccess error: ', error);
    }
  };

  const getUrl = async () => {
    try {
      toast.dismiss(toastRef.current);
      toastRef.current = toast.loading(
        'Accessing Google services, please wait...'
      );

      googleAccess();

      const {
        data: {
          data: { url }
        }
      } = await axiosInstance.post(`/auth/google`, {
        redirect_uri: `${window.location.origin}/auth/google/callback`,
        orderId,
        joinCampaign: getJoinCampaign()
      });
      // TODO: 错误处理
      if (url) {
        window.location.href = url;
      } else {
        toast.dismiss(toastRef.current);
        toast.error('Fail to get Google auth url.');
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      }
    } catch (error: any) {
      console.error('Fail to get Google auth url', error);
      toast.dismiss(toastRef.current);
      toast.error('Fail to get Google auth url.');
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    }
  };

  return (
    <div className={styles.login}>
      <img src="/c-logo.png" className={styles.logoImg} />
      <div className={styles.title}>Welcome to Aha</div>
      <div className={styles.desc}>Turn your influence into cash with Aha</div>
      <div
        className={styles.button}
        onClick={async () => {
          if (!loginLoading) {
            Tracker.click('login with google');
            setLoginLoading(true);
            try {
              await getUrl();
            } catch (error) {
              console.error(error);
            } finally {
              setTimeout(() => {
                setLoginLoading(false);
              }, 3000);
            }
          }
        }}
      >
        <GoogleIcon className={styles.googleLogo} />
        {!loginLoading ? (
          <>
            <div className={styles.btnName}>Log in with Google</div>
            <img src="/c-arrowRight.png" className={styles.rightIcon} />
          </>
        ) : (
          <>
            <div className={styles.btnName}>Logging in with Google...</div>
            <div className={styles.rightIcon} />
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
