import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { NavLink, useNavigate } from 'react-router-dom';
import { Tracker } from '../../utils';
import styles from './submitFinalLink.module.less';
import './custom.less';
import { useCountDown } from '../../hooks/useCountdown';
import { getOrderId, getToken, getLoginPageUrl } from '@/biz/tool';
import { OrderStatus } from '../../interface/order';

interface Props {
  data: any;
}

export const SubmitPaymentDialog: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
}> = ({ isOpen, setIsOpen }) => {
  const orderId = getOrderId();

  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <div className={styles.dialogTitle}>Complete Payment Setup</div>
      <div className={styles.dialogDesc}>
        To ensure you receive your payment, please enter your payment details
        before submitting the final link.
      </div>
      <div className={styles.btnCtn}>
        <div
          className={styles.subBtn}
          onClick={() => {
            Tracker.click('cancel in payment setup modal');
            setIsOpen(false);
          }}
        >
          {' '}
          Cancel
        </div>
        <NavLink to={`/campaign/payment/method?orderId=${orderId}`}>
          <div
            className={styles.btn}
            onClick={() => {
              Tracker.click('set up in payment setup modal');
            }}
          >
            Set up
          </div>
        </NavLink>
      </div>
    </Dialog>
  );
};

export const CheckPaymentDialog: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
  order: any;
  payment: any;
}> = ({ isOpen, setIsOpen, order, payment }) => {
  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <div className={styles.dialogTitle}>Review payment info</div>
      <div className={styles.dialogDesc}>
        Take a quick look to confirm your payment information is correct:
      </div>
      <div className={styles.item}>
        <div className={styles.itemName}>Payment method</div>
        <div className={styles.itemValue}>
          {payment?.accountType === 'paypal' ? 'PayPal' : 'Bank'}
        </div>
      </div>
      {payment?.accountType === 'paypal' && (
        <div className={styles.item}>
          <div className={styles.itemName}>PayPal account</div>
          <div className={styles.itemValue}>{payment?.paypal?.receiver}</div>
        </div>
      )}
      <div className={styles.btnCtn}>
        <NavLink to={`/campaign/payment/method?orderId=${order?._id}`}>
          <div className={styles.subBtn}>Update</div>
        </NavLink>
        <NavLink
          to={`/campaign/draft/upload/link?orderId=${order?._id}&type=final`}
        >
          <div className={styles.btn}>Correct</div>
        </NavLink>
      </div>
    </Dialog>
  );
};

export const SubmitFinalLink: React.FC<Props> = ({
  data: { order, payment, timeline } = {}
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isShowSetup, setIsShowSetup] = useState(false);

  // finalPostDeadline
  const finalPostDeadline = (createdAt?: string | Date | null) => {
    if (!createdAt) return;
    return new Date(createdAt).getTime();
  };
  const time = finalPostDeadline(timeline?.finalPostDeadline?.deadlineTs);
  const [day, hour, minute, second] = useCountDown(time);

  return (
    <div id="bottom" className={styles.bottom}>
      <>
        <div className={styles.title}>
          Time left to submit final link:{' '}
          <span translate="no" className={styles.subTitle}>
            {day
              ? `${day}d ${hour}h ${minute}m ${second}s`
              : `${hour}h ${minute}m ${second}s`}
          </span>{' '}
        </div>
        <div className="bg-white w-full flex gap-2 mt-3">
          <div
            className="w-[175px] flex-shrink-0"
            onClick={() => {
              if (!getToken()) {
                navigate(getLoginPageUrl());
                return;
              }
              navigate(`/campaign/delivery/${order?._id}`);
            }}
          >
            <Button variant="contained" className={styles.cancelButton}>
              Submission history
            </Button>
          </div>
          <Button
            variant="contained"
            className={styles.button}
            onClick={() => {
              if (!getToken()) {
                navigate(getLoginPageUrl());
                return;
              }
              // payment ? setIsOpen(true) : setIsShowSetup(true);
              if (!payment) {
                setIsShowSetup(true);
                return;
              }
              Tracker.click('Submit final link');
              const method = 'link';
              navigate(
                `/campaign/draft/upload/${method}?orderId=${order?._id}&type=final`,
                { replace: true }
              );
            }}
          >
            Submit final link
          </Button>
        </div>
      </>

      <CheckPaymentDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        order={order}
        payment={payment}
      />
      <SubmitPaymentDialog isOpen={isShowSetup} setIsOpen={setIsShowSetup} />
    </div>
  );
};
