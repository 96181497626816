import React from 'react';

import styles from './header.module.less';

interface Props {
  data: any;
}

export const Header: React.FC<Props> = ({
  data: { order, influencer, campaign } = {}
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <span>Hi </span>
        <span translate="no">
          {influencer?.username || influencer?.name || ''}
        </span>
        <span>,</span>
      </div>
      <div className={styles.desc}>
        You're a perfect match for this brand collaboration!
      </div>
    </div>
  );
};
