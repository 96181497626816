import React, { useEffect, useState } from 'react';
import { CheckList } from './checkList';
import { DeliveryContent } from './deliveryContent';
import { PaymentInfo } from './paymentInfo';
import Drawer from '@material-ui/core/Drawer';
import { OrderStatus } from '../../interface/order';
import { Tracker } from '../../utils';
import { getDuration, getDurationFormatBusinessDays } from '@/biz/time';
import { StarIcon } from '../common/icon';

import styles from './delivery.module.less';
import {
  CloseIcon,
  StepCheckIcon,
  WarnIcon,
  HandHoldingDollarIcon,
  HourglassIcon,
  ClockIcon,
  ExclamationIcon
} from '../common/icon';

interface Props {
  data: any;
}

const formatDateTime = (date: string | Date | null) => {
  if (!date) return '-';
  return new Date(date)
    .toLocaleString('en-US', {
      month: 'long', // 月份全称，例如 "June"
      day: 'numeric', // 日期，例如 "3"
      hour: 'numeric', // 小时，例如 "2"
      minute: '2-digit', // 分钟，例如 "30"
      hour12: true // 使用 12 小时制
    })
    .replace(' at ', ', ');
};
const formatText = (
  sourceStatus: string | null,
  targetStatus: string | null,
  text: string | null,
  version: string | number | null,
  contentStage: string | null
) => {
  if (
    sourceStatus === OrderStatus.CreatorRejected &&
    targetStatus === OrderStatus.Cancelled
  )
    return 'Declined the campaign';
  if (targetStatus === OrderStatus.CreatorApplied)
    return 'Applied for the campaign';
  if (
    sourceStatus === OrderStatus.CreatorApplied &&
    targetStatus === OrderStatus.UnderProduction
  )
    return 'Application approved';
  if (targetStatus === OrderStatus.ApplyRejected) return 'Application rejected';

  if (targetStatus === OrderStatus.UnderReview && contentStage !== 'Script')
    return `Submitted video draft v${version}`;

  if (targetStatus === OrderStatus.UnderReview && contentStage === 'Script')
    return `Submitted script`;

  if (
    sourceStatus === OrderStatus.UnderReview &&
    targetStatus === OrderStatus.UnderProduction
  ) {
    if (contentStage !== 'Script') {
      return 'Received feedback';
    } else {
      if (text === 'Under_review --> Under_production') {
        return 'Script approved';
      } else {
        return 'Received feedback';
      }
    }
  }

  if (targetStatus === OrderStatus.ReviewRejected) return 'Draft rejected';
  if (targetStatus === OrderStatus.PendingPublish) return 'Draft approved';
  if (targetStatus === OrderStatus.PendingPayment)
    return 'Content link uploaded';
  if (targetStatus === OrderStatus.PaymentSubmitted) return 'Payment delivered';
  return text;
};

const LogDrawer: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
  activity: any;
}> = ({ isOpen, setIsOpen, activity }) => {
  // Payment_submitted --> Paid 是平台返回的 准确支付成功，不需要显示。
  activity = activity
    .map((item: any) => {
      if (
        item.sourceStatus === 'Payment_submitted' &&
        item.targetStatus === 'Paid'
      ) {
        return null;
      } else {
        return item;
      }
    })
    .filter(Boolean);

  return (
    <Drawer
      id="drawerCtn"
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles.drawerTitle}>
        Submission history{' '}
        <CloseIcon
          className={styles.closeIcon}
          onClick={() => setIsOpen(false)}
        />
      </div>
      <div className={styles.drawerDesc}>
        {Array.isArray(activity) &&
          activity.map((item: any, index: Number) => {
            if (index === 0)
              return (
                <div key={item._id} className={styles.item}>
                  <img
                    src="/c-activeIndicator.png"
                    className={styles.itemIcon}
                  />
                  <div className={styles.itemDetail}>
                    <div className={styles.itemTitle}>
                      {formatText(
                        item.sourceStatus,
                        item.targetStatus,
                        item.text,
                        item.version,
                        item.payload?.contentStage
                      )}
                    </div>
                    <div className={styles.itemTime}>
                      {formatDateTime(item.createdAt)}
                    </div>
                  </div>
                </div>
              );
            return (
              <div key={item._id} className={styles.item}>
                <img src="/c-indicator.png" className={styles.itemIcon} />
                <div className={styles.itemDetail}>
                  <div
                    className={styles.itemTitle}
                    style={{ color: '#60646C' }}
                  >
                    {formatText(
                      item.sourceStatus,
                      item.targetStatus,
                      item.text,
                      item.version,
                      item.payload?.contentStage
                    )}
                  </div>
                  <div className={styles.itemTime} style={{ color: '#B9BBC6' }}>
                    {formatDateTime(item.createdAt)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Drawer>
  );
};

const getStatusIcon = (stepNum: number) => {
  const iconMap: { [k: number]: any } = {
    0: <ClockIcon className={styles.statusIcon} />,
    1: <HourglassIcon className={styles.statusIcon} />,
    2: <ExclamationIcon className={styles.statusIcon} />,
    3: <ClockIcon className={styles.statusIcon} />,
    4: <ClockIcon className={styles.statusIcon} />,
    5: <HourglassIcon className={styles.statusIcon} />,
    6: <HandHoldingDollarIcon className={styles.statusIcon} />
  };

  return iconMap[stepNum] || iconMap[0];
};

export const CampaingStatus: React.FC<{
  stepNum: number;
  activity: any;
  order: any;
  timeline: any;
}> = ({ stepNum, activity = [], order, timeline }) => {
  const [isShowLog, setIsShowLog] = useState(false);

  // draft
  const draftDeadline = (
    hours: number | null,
    createdAt?: string | Date | null
  ) => {
    if (createdAt) {
      const timeString = new Date(createdAt).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      const dateString = new Date(createdAt).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });

      return `${timeString} on ${dateString}`;
    }

    if (hours) return `${getDuration(hours)} after application approval`;
    return '-';
  };
  // review
  const review = (hours: number | null) => {
    if (hours) return getDurationFormatBusinessDays(hours);
    return '-';
  };
  // payment
  const paymentDeadline = (hours: number | null) => {
    if (hours) return getDuration(hours);
    return '-';
  };

  return (
    <div className={styles.status}>
      <div className={styles.statusTitle}>
        Campaign Status
        {stepNum >= 0 ? (
          <div
            className={styles.subTitle}
            onClick={() => {
              setIsShowLog(true);
              Tracker.click('click see details');
            }}
          >
            See details
            <img src="/c-right.png" className={styles.rightIcon} />
          </div>
        ) : null}
      </div>
      {/* // TODO: 时间, 文案 */}
      {/* script提交阶段 */}
      {stepNum === 0 &&
        order?.contentStage === 'Script' &&
        order?.status === OrderStatus.UnderProduction && (
          <div className={styles.detail}>
            Submit your script draft before{' '}
            {draftDeadline(
              timeline?.initialScriptDraft?.maxWaitInHours,
              timeline?.initialScriptDraft?.deadlineTs
            )}
            , and your script will be reviewed once submitted.
          </div>
        )}
      {/* script审核阶段 */}
      {stepNum === 0 &&
        order?.contentStage === 'Script' &&
        order?.status === OrderStatus.UnderReview && (
          <div className={styles.detail}>
            We will send you feedback via email within{' '}
            {review(timeline?.review?.maxWaitInHours)}. Stay tuned!
          </div>
        )}

      {/* draft提交阶段 */}
      {stepNum === 1 &&
        order?.contentStage !== 'Script' &&
        order?.status === OrderStatus.UnderProduction &&
        order?.version === 0 && (
          <div className={styles.detail}>
            Submit your video draft before{' '}
            {draftDeadline(
              timeline?.draft?.maxWaitInHours,
              timeline?.draft?.deadlineTs
            )}
            , and your video draft will be reviewed once submitted.
          </div>
        )}
      {/* draft 审核阶段 */}
      {stepNum === 1 &&
        order?.contentStage !== 'Script' &&
        order?.status === OrderStatus.UnderReview && (
          <div className={styles.detail}>
            We will send you feedback via email within{' '}
            {timeline?.review?.maxWaitInHours} hours. Stay tuned!
          </div>
        )}

      {/* draft 反馈后阶段 UnderProduction && version >= 1&& contentStage !== Script */}
      {stepNum === 1 &&
        order?.status === OrderStatus.UnderProduction &&
        order?.contentStage !== 'Script' &&
        order?.version >= 1 && (
          <div className={styles.detail}>
            Please provide a revised video draft as soon as possible based on
            the suggested changes.
          </div>
        )}

      {/* 其他阶段 */}
      {stepNum > 0 && (
        <div className={styles.detail}>
          {
            [
              '', // script阶段 会有review
              '', // draft阶段 会有review
              '', // draft阶段 反馈后，二稿稿子提交 提示修改。
              'Your draft has been approved! Please publish your content within the scheduled time.',
              `Payment will be issued ${paymentDeadline(timeline?.payment?.maxWaitInHours)} post-publication. Please verify your payment details are correct.`,
              'Payment has been sent. Thanks for your effort! We look forward to future successful partnerships.'
            ][stepNum]
          }
        </div>
      )}

      <div className={styles.statusDesc}>
        <div className={styles.stepper}>
          <div className={styles.stepperImg}>
            {[0, 1, 2, 3].map((index) => {
              let step = stepNum >= 2 ? stepNum - 1 : stepNum;
              return (
                <>
                  <div
                    className={
                      index > step
                        ? styles.stepNum
                        : index === step
                          ? styles.activeStepNum
                          : styles.completedStepNum
                    }
                  >
                    {index < 3 ? index + 1 : null}
                    {index === 3 ? (
                      <StarIcon
                        className={` w-[14px] h-[14px] m-[5px] ${index < step ? 'text-white' : 'text-[#b9bbc6]'}`}
                      />
                    ) : null}
                  </div>
                  {index >= step && index < 3 ? (
                    <div className={styles.line} />
                  ) : null}
                  {index < step && index < 3 ? (
                    <div className={styles.activeLine} />
                  ) : null}
                </>
              );
            })}
          </div>

          <div className={styles.stepperDesc}>
            <div className={stepNum >= 0 ? styles.active : ''}>Script</div>
            <div className={stepNum >= 1 ? styles.active : ''}>Video</div>
            <div className={stepNum >= 3 ? styles.active : ''}>Publish</div>
            <div className={stepNum >= 4 ? styles.active : ''}>Payment</div>
          </div>
        </div>
      </div>
      <LogDrawer
        isOpen={isShowLog}
        setIsOpen={setIsShowLog}
        activity={activity}
      />
    </div>
  );
};

export const Delivery: React.FC<Props> = ({
  data: {
    order,
    influencer,
    campaign,
    action,
    timeline,
    activity,
    payment
  } = {}
}) => {
  // 0, 待提交 Draft
  // 1, 等待审核
  // 2, 等待发布
  // 3, 已发布, 等待支付
  // 4, 已支付
  const getOrderStep = (status: OrderStatus) => {
    if (
      [
        OrderStatus.Created,
        OrderStatus.Inviting,
        OrderStatus.Invited,
        OrderStatus.CreatorApplied,
        OrderStatus.Cancelled
      ].includes(status)
    )
      return 0;
    if ([OrderStatus.UnderProduction].includes(status)) {
      if (order?.contentStage === 'Script') {
        return 0;
      }
      // return order.version >= 1 ? 2 : 0;
      return 1;
    }

    if ([OrderStatus.UnderReview].includes(status)) {
      if (order?.contentStage === 'Script') {
        return 0;
      } else {
        return 1;
      }
    }

    if ([OrderStatus.PendingPublish].includes(status)) return 3;
    if ([OrderStatus.PublishRejected].includes(status)) return 3; // 填报链接校验失败

    if ([OrderStatus.PendingPayment].includes(status)) return 4;
    if (
      [
        OrderStatus.PaymentSubmitted,
        OrderStatus.Paid,
        OrderStatus.PaymentFailed,
        OrderStatus.Completed
      ].includes(status)
    )
      return 5;
    return 0;
  };
  const [stepNum, setStepNum] = useState(0);

  useEffect(() => {
    if (order?.status) {
      setStepNum(getOrderStep(order.status));
    } else {
      setStepNum(0);
    }
  }, [order]);

  return (
    <div className={styles.delivery}>
      {stepNum >= 0 && (
        <CampaingStatus
          stepNum={stepNum}
          activity={activity}
          order={order}
          timeline={timeline}
        />
      )}
      {stepNum > 4 ? <PaymentInfo data={{ order, payment, timeline }} /> : null}
      {stepNum >= 0 ? <DeliveryContent data={{ order, activity }} /> : null}
    </div>
  );
};
