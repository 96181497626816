export enum OrderStatus {
  Created = 'Created',
  Inviting = 'Inviting',
  Invited = 'Invited',
  CreatorRejected = 'Creator_rejected',
  CreatorApplied = 'Creator_applied',
  ApplyRejected = 'Apply_rejected',
  UnderProduction = 'Under_production',
  UnderReview = 'Under_review',
  ReviewRejected = 'Review_rejected',
  PendingPublish = 'Pending_publish',
  PublishRejected = 'Publish_rejected', // youtube 链接校验 失败。
  PendingPayment = 'Pending_payment',
  PaymentSubmitted = 'Payment_submitted',
  Paid = 'Paid',
  PaymentFailed = 'Payment_failed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  UnderRevision = 'Under_revision' // Deprecated 若有未迁移的老数据, 还是需要展示
}

export enum OrderContentType {
  ImageText = 'image_text', // 图文(Deprecated)
  Video = 'video', // 视频(Deprecated)

  IMAGETEXT = 'imageAndText', // Image & text(Deprecated)
  SHORTVIDEO = 'shortVideo', // Video(Deprecated)
  LONGVIDEO = 'longVideo', // Dedicated video(Deprecated)
  MIDROLL = 'midRoll', // Integrated video(Deprecated)

  // https://cxa8mlnnp7j.feishu.cn/wiki/C7AywMdMQiSfcokSbhCcRJ1knmx#YpH3dImSuoA9n3xHUdqcf3ECnud
  IMAGE_TEXT = 'Image & Text',
  VIDEO = 'Video',
  DEDICATED_VIDEO = 'Dedicated video',
  INTEGRATED_VIDEO = 'Integrated video'
}

// https://cxa8mlnnp7j.feishu.cn/wiki/C7AywMdMQiSfcokSbhCcRJ1knmx#YpH3dImSuoA9n3xHUdqcf3ECnud
export enum OrderContentGenre {
  TUTORIAL = 'Tutorial',
  STORYTELLING = 'Storytelling',
  PRODUCT_DISPLAY = 'Product display',
  TEST_REVIEW = 'Test & review',
  SHOWCASE = 'Showcase',
  NEWS_UPDATE = 'News update'
}
