import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../apis/axiosInstance';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Header from '../../components/campaign/campaignHeader';
import Airwallex from '../../components/airwallex';
import Loading from '../../components/common/loading';
import toast from 'react-hot-toast';
import { CloseIcon } from '../../components/common/icon';
import { getOrderId, getDetailPageUrl } from '@/biz/tool';
import { emailValidator } from '@/biz/validator';

import styles from './updatePayMentMethod.module.less';
import '../../components/campaign/custom.less';

const currencyList = [
  'USD',
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MYR',
  'MXN',
  'TWD',
  'NZD',
  'NOK',
  'PHP',
  'PLN',
  'GBP',
  'SGD',
  'SEK',
  'CHF',
  'THB'
];

interface Props {
  data: any;
}

const SelectDrawer: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
  value: string;
  setValue?: Function;
  selections: any;
}> = ({ isOpen, setIsOpen, value, setValue, selections }) => {
  const [currentVal, setCurrentVal] = useState('');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setCurrentVal(value || '');
  }, [value]);
  useEffect(() => {
    console.log('from Select', isOpen);
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <Drawer
      id="drawerCtn"
      anchor="bottom"
      open={open}
      onClose={() => {
        setOpen(false);
        console.log('onClose open', open);
        setIsOpen(false);
        console.log('onClose isOpen', isOpen);
      }}
    >
      <div className={styles.drawerTitle}>
        Account currency{' '}
        <CloseIcon
          className={styles.closeIcon}
          onClick={() => {
            setOpen(false);
            console.log('closeIcon open', open);
            setIsOpen(false);
            console.log('closeIcon isOpen', isOpen);
          }}
        />
      </div>
      <div className={styles.selectItemList}>
        <RadioGroup
          value={currentVal}
          onChange={(event) => setCurrentVal(event.target.value)}
        >
          {Array.isArray(selections) &&
            selections.map((item, index) => {
              return (
                <div className={styles.radioItem} key={index}>
                  {' '}
                  <Radio value={item} />
                  {item}
                </div>
              );
            })}
        </RadioGroup>
      </div>
      <Button
        variant="contained"
        className={styles.submitButton}
        onClick={() => {
          if (setValue) setValue(currentVal);
          setOpen(false);
          console.log('Confirm open', open);
          setIsOpen(false);
          console.log('Confirm isOpen', isOpen);
        }}
      >
        Confirm
      </Button>
      <Button
        variant="contained"
        className={styles.cancelButton}
        onClick={() => {
          setOpen(false);
          console.log('Cancel open', open);
          setIsOpen(false);
          console.log('Cancel isOpen', isOpen);
        }}
      >
        Cancel
      </Button>
    </Drawer>
  );
};

const Input: React.FC<{
  text?: any;
  setText?: Function;
  placeholder?: any;
  setValid?: Function;
  shouldShowErrMsg?: boolean;
  setShowBottomBtn?: Function;
}> = ({
  text,
  setText,
  placeholder,
  setValid,
  shouldShowErrMsg,
  setShowBottomBtn
}) => {
  const [shouldCheckAccount, setShouldCheckAccount] = useState(false);
  // 暂时仅支持邮箱, 验证规则与后端一致
  const isPayPalAccountValid = emailValidator;

  const errMsg = (value: any) => {
    if (!shouldShowErrMsg && !shouldCheckAccount) return;
    if (!isPayPalAccountValid(value))
      return 'Please enter a valid email address';
  };
  useEffect(() => {
    if (setValid) {
      setValid(isPayPalAccountValid(text));
    }
  }, [text]);
  return (
    <div className={styles.inputCtn}>
      <div className={styles.label}>
        PayPal account email<span className={styles.labelIcon}>*</span>
      </div>
      <TextField
        helperText={errMsg(text)}
        error={!!errMsg(text)}
        id="standard-basic"
        className={styles.input}
        placeholder={placeholder}
        value={text}
        onChange={(e) => {
          if (setText) {
            setShouldCheckAccount(true);
            setText(e.target.value);
          }
        }}
        onFocus={(e) => {
          // console.log('focus1');
          setShowBottomBtn && setShowBottomBtn(false);
        }}
        onBlur={() => {
          // console.log('blur1');
          setShowBottomBtn && setShowBottomBtn(true);
          setShouldCheckAccount(true);
        }}
      />
      {/* <div className={styles.errorMsg}></div> */}
    </div>
  );
};

const Select: React.FC<{
  select?: any;
  setSelect?: Function;
  selections?: any;
  placeholder?: any;
}> = ({ select, setSelect, selections, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    console.log('Select', isOpen);
  }, [isOpen]);
  return (
    <div className={styles.inputCtn}>
      <SelectDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setValue={setSelect}
        value={select}
        selections={selections}
      />
      <div className={styles.label}>
        Account currency<span className={styles.labelIcon}>*</span>
      </div>
      <div
        className="border border-solid border-transparent border-b-[#0000006b] my-2 pb-1 text-black text-base"
        onClick={() => setIsOpen(true)}
      >
        {select}
        <img src="/c-arrow.png" className={styles.arrowIcon} />
        {/* <TextField
          disabled
          id="standard-basic"
          className={styles.input}
          placeholder={placeholder}
          value={select}
        /> */}
      </div>
    </div>
  );
};

const PayPal: React.FC<{
  data: any;
  setData: Function;
  setValid: Function;
  shouldShowErrMsg: boolean;
  setShowBottomBtn?: Function;
}> = ({
  data: { payment } = {},
  setData,
  setValid,
  shouldShowErrMsg,
  setShowBottomBtn
}) => {
  const [account, setAccount] = useState('');
  const [currency, setCurrency] = useState('');
  useEffect(() => {
    if (payment?.paypal?.receiver) {
      setAccount(payment?.paypal?.receiver);
    }
    setCurrency(payment?.paypal?.receiverCurrency || 'USD');
  }, [payment]);
  useEffect(() => {
    setData({
      type: 'paypal',
      paypal: {
        receiver: account || '',
        receiverType: 'EMAIL',
        receiverCurrency: currency || 'USD'
      }
    });
  }, [account, currency]);
  return (
    <>
      <div className={styles.title}>Link your PayPal account</div>
      <div className={styles.desc}>Please fill in the details below:</div>
      <Input
        text={account}
        setText={setAccount}
        placeholder="Enter email address"
        setValid={setValid}
        shouldShowErrMsg={shouldShowErrMsg}
        setShowBottomBtn={setShowBottomBtn}
      />
      <Select
        select={currency}
        setSelect={setCurrency}
        selections={currencyList}
        placeholder="Select account currency"
      />
    </>
  );
};

const UpdatePaymentMethod: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { type } = useParams();
  const [showBottomBtn, setShowBottomBtn] = useState(true);
  const [data, setData] = useState<any>({});
  const [newData, setNewData] = useState<any>({});
  const airwallexRef = useRef<any>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isPaypalValid, setIsPaypalValid] = useState(false);
  const [shouldPaypalShowErrMsg, setShouldPaypalShowErrMsg] = useState(false);
  const orderId = getOrderId();

  const getData = async () => {
    try {
      setLoading(true);
      const {
        data: { data }
      } = await axiosInstance.get(`/payment/account`);
      if (data?.payment) {
        setIsUpdate(true);
      }
      setData(data);
    } catch (e: any) {
      toast.error(e.message || 'Fail to get data.');
    } finally {
      setLoading(false);
    }
  };

  const saveData = async () => {
    setButtonLoading(true);
    let payload = {};
    if ((type as string) === 'bank') {
      const formResult = await airwallexRef.current?.submit();
      console.log(formResult);
      if (formResult?.errors) {
        toast.error(`Error: ${formResult?.errors?.code}.`);
        return;
      }
      // TODO: 组件内 loading 时, 如果操作太快, 可能会导致取到空的 values
      payload = {
        type: 'airwallex',
        airwallex: formResult?.values
      };
      setNewData(payload);
    } else {
      if (!isPaypalValid) {
        setShouldPaypalShowErrMsg(true);
        return;
      }
      payload = newData;
    }
    try {
      await axiosInstance.post(`/payment/account`, payload);
      // TODO: 更新成功的提示
      window.location.href = getDetailPageUrl();
      return;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to save payment info.');
    } finally {
      setButtonLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <Header title="Payment method" isBack />
      <div className={styles.payment}>
        {(type as string) === 'paypal' ? (
          <PayPal
            setShowBottomBtn={setShowBottomBtn}
            data={data}
            setData={setNewData}
            setValid={setIsPaypalValid}
            shouldShowErrMsg={shouldPaypalShowErrMsg}
          />
        ) : (
          loading || <Airwallex airwallexRef={airwallexRef} />
        )}
        {showBottomBtn ? (
          <div className={styles.bottom}>
            {!isUpdate ? (
              <>
                <Button
                  disabled={buttonLoading}
                  variant="contained"
                  className={styles.joinButton}
                  onClick={() => saveData()}
                >
                  {buttonLoading ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  variant="contained"
                  className={styles.notInterestedButton}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                disabled={buttonLoading}
                variant="contained"
                className={styles.joinButton}
                onClick={() => {
                  saveData();
                }}
              >
                {buttonLoading ? 'Updating...' : 'Update payment account'}
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UpdatePaymentMethod;
