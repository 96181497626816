import axios from 'axios';
import { TEST_API_BASE_URL, PRE_API_BASE_URL, BASE_URL } from '../utils/config';
import toast from 'react-hot-toast';
import {
  getLoginPageUrl,
  getRealRouterFromUrl,
  getOrderId,
  clearToken
} from '@/biz/tool';

const getApiBaseUrl = () => {
  if (process.env.REACT_APP_ENV === 'staging') {
    return TEST_API_BASE_URL;
  }
  if (process.env.REACT_APP_ENV === 'pre') {
    return PRE_API_BASE_URL;
  }
  if (process.env.REACT_APP_ENV === 'production') {
    return BASE_URL;
  }

  if (process.env.REACT_APP_ENV === 'development') {
    if (process.env.REACT_APP_API_BASE_URL) {
      // 通过 copy .env.example .env 文件配置
      return process.env.REACT_APP_API_BASE_URL;
    }
    return 'http://localhost:3000/';
  }

  // Default to production API URL or add other environments as needed
  return BASE_URL; // replace with actual production API URL
};

const API_BASE_URL = getApiBaseUrl();

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    // 尝试从存储中获取token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 跳转去登录
const redirectToLogin = () => {
  window.localStorage.setItem('auth_redirect', window.location.href);
  //
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('profile');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('platform');

  window.location.href = getLoginPageUrl();
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isAxiosError(error) && error.response) {
      const url = error?.response?.config?.url || '';
      const orderId = getOrderId();
      //
      if (
        getRealRouterFromUrl() === 'detail' &&
        (url === `/order/${orderId}/updateInfluencerTimezone` ||
          url === `/order/${orderId}/activities` ||
          url === `/order/detail/${orderId}`)
      ) {
        // 再detail 调用这4个接口 鉴权失败 不需要跳转登录。
        // 也不需要清空token
        if (url === `/order/${orderId}/updateInfluencerTimezone`) {
          // 时区 没加去掉鉴权。
        } else {
          //
          clearToken();
        }
        //
      } else {
        // token 权限不足(用了其他人的token)
        if (error.response.status === 401) {
          // token 鉴权失败
          redirectToLogin();
        } else if (error.response.status === 403) {
          // token 权限不足(用了其他人的token)
          redirectToLogin();
        }
      }

      return Promise.reject(error?.response?.data);
    }
    // return Promise.reject({ message: 'Unknown error', error }); // Internal Server Error
    return Promise.reject({
      message: 'Server error. Please refresh the page and try again.',
      error
    }); // Internal Server Error
  }
);

export default axiosInstance;
