import {
  getOrderContentType,
  getOrderContentGenre,
  getOrderPlatform
} from '../../biz/order';
import { getTimezoneName, getTime } from '../../biz/time';
import React, { useState, useEffect } from 'react';
import { BuildingsIcon } from '../common/icon';
import styles from './campaignBrandInfo.module.less';
import { getDuration } from '@/biz/time';
interface Props {
  data: any;
}

export const CampaignBrandInfo: React.FC<Props> = ({
  data: { product, order, timeline, campaign } = {}
}) => {
  const [objective, setObjective] = useState<any>([]);
  useEffect(() => {
    let arr: any[] = [];
    if (Array.isArray(campaign?.objective)) {
      campaign?.objective.forEach((item: any) => {
        if (typeof item === 'string') {
          arr.push(
            'Drive ' +
              item
                .split(/\s+|_/)
                .map((word, index) => (word ? `${word.toLowerCase()}` : ''))
                .join(' ')
          );
        }
      });
    }
    setObjective(arr);
  }, [campaign?.objective]);
  // initialScriptDraftDeadline
  const initialScriptDraftDeadline = (
    hours: number | null,
    createdAt?: string | Date | null
  ) => {
    if (createdAt) return getTime(createdAt);
    if (hours) return `${getDuration(hours)} after application approval`;
    return '-';
  };
  // firstPostDeadline
  const firstPostDeadline = (
    hours: number | null,
    createdAt?: string | Date | null
  ) => {
    if (createdAt) return getTime(createdAt);
    if (hours) return `${getDuration(hours)} after script approval`;
    return '-';
  };
  // finalPostDeadline
  const finalPostDeadline = (
    hours: number | null,
    createdAt?: string | Date | null
  ) => {
    if (createdAt) return getTime(createdAt);
    if (hours) return `${getDuration(hours)} after draft approval`;
    return '-';
  };

  return (
    <>
      {product && (
        <div className={styles.brand}>
          <div className={styles.brandTitle}>Brand Info</div>
          <div className={styles.brandDetail}>
            <div className="box-border w-14 h-14 border border-[#E8E8EC] overflow-hidden flex flex-shrink-0 justify-center items-center rounded-lg bg-white mr-5">
              <img
                alt="product-logo"
                className="max-w-14 max-h-14"
                src={product?.logo || `/c-detail-task.png`}
                onLoad={(e) => {
                  const img = e.target as HTMLImageElement;
                  const aspectRatio = Math.abs(
                    img.naturalWidth / img.naturalHeight
                  );
                  console.log('aspectRatio', aspectRatio);

                  if (aspectRatio === 1) {
                    img.style.padding = '20%'; // 正方形图片 1:1
                  } else if (aspectRatio > 2 || aspectRatio < 0.5) {
                    img.style.padding = '5%'; // 长宽比 > 2:1 或 < 1:2
                  } else {
                    img.style.padding = '10%'; // 1:2 <= 比例 <= 2:1
                  }
                }}
              />
            </div>
            <div className={styles.brandInfo}>
              <div translate="no" className={styles.brandName}>
                {product.name}
              </div>
              {product.website && (
                <a href={product.website}>
                  <div className={styles.brandWebSite}>{product.website}</div>
                </a>
              )}
            </div>
          </div>
          <div className={styles.brandDesc}>{campaign.introduction}</div>
          {objective.length > 0 && (
            <>
              <div className={styles.objectiveTips}>Campaign Objective</div>
              <div className={styles.brandDesc}>{objective.join(', ')}</div>
            </>
          )}
        </div>
      )}

      <div className={styles.card}>
        <div className={styles.title}>Campaign Requirements</div>
        <div className={styles.desc}>
          <div className={styles.label}>Platform</div>
          {/* // TODO: 根据 platform 处理小图标, 至少有三种图标, 需要整理 */}
          {/* <img className={styles.value} src={`/c-${order?.platform}.png`} alt={order?.platform} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src="/c-logo.png"; }}/> */}
          <div className={styles.value}>
            <img
              alt={order?.platform}
              src={`/c-${order?.platform}.png`}
              className={styles.LinkedInLogo}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/c-logo.png';
              }}
            />
            {getOrderPlatform(order)}
          </div>
        </div>
        <div className={styles.desc}>
          {/* 暂不处理无 genre 的情况 */}
          <div className={styles.label}>Content type & genre</div>
          <div className={styles.value}>
            {getOrderContentType(order)}, {getOrderContentGenre(order)}
          </div>
        </div>
        <div className={styles.desc}>
          <div className={styles.label}>First script due</div>
          <div className={styles.timeValue}>
            <div className="">
              {initialScriptDraftDeadline(
                timeline?.initialScriptDraft?.maxWaitInHours,
                timeline?.initialScriptDraft?.deadlineTs
              )}
            </div>
            {timeline?.initialScriptDraft?.deadlineTs ? (
              <div className="">
                ({getTimezoneName(timeline?.initialScriptDraft?.maxWaitInHours)}
                )
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.desc}>
          <div className={styles.label}>First video due</div>
          <div className={styles.timeValue}>
            <div className="">
              {firstPostDeadline(
                timeline?.firstVideoDraftDue?.maxWaitInHours,
                timeline?.firstVideoDraftDue?.deadlineTs
              )}
            </div>
            {timeline?.firstVideoDraftDue?.deadlineTs ? (
              <div className="">
                ({getTimezoneName(timeline?.firstVideoDraftDue?.maxWaitInHours)}
                )
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.desc}>
          <div className={styles.label}>Final post due</div>
          <div className={styles.timeValue}>
            <div className="">
              {finalPostDeadline(
                timeline?.finalPostDeadline?.maxWaitInHours,
                timeline?.finalPostDeadline?.deadlineTs
              )}
            </div>
            {timeline?.finalPostDeadline?.deadlineTs ? (
              <div className="">
                ({getTimezoneName(timeline?.finalPostDeadline?.maxWaitInHours)})
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
