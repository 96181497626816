import React, { useState, useEffect } from 'react';
import { JoinedUser } from './joinedUser';
import styles from './joinCard.module.less';
import { useCountDown } from '../../hooks/useCountdown';
import { datafluxRum } from '@cloudcare/browser-rum';
import { getLoginPageUrl, getAuthPageUrl } from '@/biz/tool';

import { Tracker } from '../../utils';

interface Props {
  data: any;
}

// TODO: 纯前端: 1. 625人分7天上涨 2. 每天下降1/7，最后停在1/7处 3. 倒计时

export const JoinCard: React.FC<Props> = ({
  data: { order, influencer, campaign, timeline } = {}
}) => {
  // joinDeadline
  const joinDeadline = (createdAt: string | Date | null) => {
    if (!createdAt) return;
    return new Date(createdAt).getTime();
  };
  const orderId = order?.id;
  const time = joinDeadline(timeline?.joinDeadline?.deadlineTs);
  const [day, hour, minute, second] = useCountDown(time);

  // Spots Left 计算逻辑
  // campaign 创建的第一天， =85%
  // campaign 创建的第二天， =71% （创建24小时候后，变为71%）
  // ...
  // campaign 创建的第六天， =14%（即六天以后 都显示14%）
  // campaign 创建的第七天， =14%

  let compaignTime: Date = new Date(campaign?.createdAt);
  let spotsLeft: number = 0;

  // 总天数
  // const totalDays = timeline?.postDeadline?.maxWaitInHours / 24 || 1;
  const totalDays = 7;
  // 已过天数 = 现在 减 compaign 创建时间
  let hasUseDays = Math.ceil(
    (Number(Date.now()) - Number(compaignTime)) / (24 * 60 * 60 * 1000)
  );
  // leftDays 取值范围 1-6
  let leftDays = totalDays - hasUseDays;
  if (leftDays > 6) leftDays = 6;
  if (leftDays < 1) leftDays = 1;

  if (leftDays && totalDays) {
    spotsLeft = leftDays / totalDays;
    if (spotsLeft < 0) spotsLeft = 0;
    if (spotsLeft > 1) spotsLeft = 1;
    spotsLeft = Math.floor(spotsLeft * 100);
  }

  // TODO: 缺一个获取和判断是否已经绑定了社交账号的逻辑
  // 如果未登录 就去登录; 如果已登录, 但是未绑定, 就去绑定; 如果已登录, 已绑定, 就去跳转授权
  const [token, setToken] = useState<any>(null);
  const [platform, setPlatform] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (order) {
      setLoading(false);
    }
  }, [order]);

  useEffect(() => {
    try {
      const str = window.localStorage.getItem('token');
      if (str) {
        setToken(str);
      }
    } catch (e) {}
    try {
      const str = window.localStorage.getItem('platform');
      if (str) {
        setPlatform(JSON.parse(str));
      }
    } catch (e) {}
  }, []);

  function navigate() {
    // 直接进入详情
    return `/campaign/detail/${orderId}`;
    // const loginPageUrl = getLoginPageUrl();
    // const authPageUrl = getAuthPageUrl();
    // if (!token) return loginPageUrl;
    // if (!Array.isArray(platform) || platform.length === 0) {
    //   return authPageUrl;
    // }
    // if (
    //   platform.find(
    //     (i: { platform: any; platformId: any }) =>
    //       i?.platform === order?.platform &&
    //       i?.platformId === influencer?.platformId
    //   )
    // ) {
    //   return `/campaign/detail/${orderId}`;
    // }
    // return loginPageUrl;
  }

  return (
    <div className={styles.joinCard}>
      <div className={styles.title}>
        <span>{loading ? '--' : '157'}</span>{' '}
        <span>creators have already joined!</span>
      </div>
      <JoinedUser loading={loading} />
      <div className={styles.progressBarBg}>
        <div className={styles.tips}>
          Only <span translate="no">{spotsLeft}%</span> Spots Left
        </div>
        <div
          className={styles.progressBar}
          style={{ width: `${spotsLeft}%` }}
        />
      </div>

      <div
        className={styles.joinButton}
        onClick={() => {
          Tracker.click('See campaign details');
          window.location.href = navigate();
        }}
      >
        See campaign details
      </div>
      <div translate="no" className={styles.timeTips}>
        {day ? <div className={`mr-1 ${styles.time}`}>{day}d</div> : null}
        {/* <div className={styles.time}>{hour}</div> */}
        <div className={`mr-1 ${styles.time}`}>{hour}h</div>
        {/* <div className={`mr-2 ${styles.colon}`}>h </div> */}
        <div className={`mr-1 ${styles.time}`}>{minute}m</div>
        {/* <div className={`mr-2 ${styles.colon}`}>m </div> */}
        <div className={`mr-1 ${styles.time}`}>{second}s</div>
        {/* <div className={`mr-1 ${styles.colon}`}>s </div> */}
        <div className={styles.tipsText}>left to apply</div>
      </div>
    </div>
  );
};
