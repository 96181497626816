import moment from 'moment';
import { useEffect, useState } from 'react';

export const useCountDown = (dueDate?: EpochTimeStamp) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dueDate) {
        const timeLeftInMs = dueDate - Date.now();
        if (timeLeftInMs >= 0) {
          setTimeLeft(timeLeftInMs);
        } else {
          setTimeLeft(0);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, dueDate]);

  const addLeadingZeroWhenNumberIsBelowTen = (num: number) => {
    if (num < 10) {
      return `0${num}`;
    }
    return `${num}`;
  };
  let day;
  let hour;
  if ((timeLeft || 0) / 1000 / 60 / 60 > 24) {
    day = moment.duration(timeLeft).days();
    hour = moment.duration(timeLeft).hours();
  } else {
    day = 0;
    hour =
      moment.duration(timeLeft).days() * 24 + moment.duration(timeLeft).hours();
  }
  const minute = moment.duration(timeLeft).minutes();
  const second = moment.duration(timeLeft).seconds();

  return dueDate
    ? [
        day,
        addLeadingZeroWhenNumberIsBelowTen(hour),
        addLeadingZeroWhenNumberIsBelowTen(minute),
        addLeadingZeroWhenNumberIsBelowTen(second)
      ]
    : ['--', '--', '--', '--'];
};
