export const TEST_API_BASE_URL = 'https://api.test.ahalab.io/';
export const PRE_API_BASE_URL = 'https://api.pre.ahalab.io/';
export const BASE_URL = 'https://api.ahalab.io/';

// Define a type alias for environment names
type Environment = 'production' | 'pre' | 'staging';

interface Config {
  BASE_URL: string;
  applicationId: string;
  clientToken: string;
  env: Environment;
}

// staging 为测试环境。

const environmentConfig: Record<Environment, Config> = {
  production: {
    BASE_URL: BASE_URL,
    // 观测云 prod 工作空间
    // alalab-influencer
    applicationId: '673b6070_645f_11ef_bde9_8523879b12de',
    clientToken: '486e29412fca488c96b22f0bfb334163',
    env: 'production'
  },
  pre: {
    BASE_URL: PRE_API_BASE_URL,
    // 观测云 pre 工作空间
    applicationId: '',
    clientToken: '',
    env: 'pre'
  },
  staging: {
    BASE_URL: TEST_API_BASE_URL,
    // 观测云 test 工作空间，后会改名为 staging
    // alalab-influencer
    applicationId: '3f3874f0_645f_11ef_8616_a1de74c8cd9a',
    clientToken: '5cc46982375849f2876af25c104bcd26',
    env: 'staging'
  }
};

const env = (process.env.REACT_APP_ENV as Environment) || 'staging';
const appConfig = environmentConfig[env];

export default appConfig;
