import React from 'react';
import TaskDetail from '../../components/task/TaskDetailCheckUser';

const TaskDetailCheckUserPage: React.FC = () => {
  return (
    <div style={{ padding: '24px' }}>
      <div>
        <TaskDetail />
      </div>
    </div>
  );
};

export default TaskDetailCheckUserPage;