import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import { getOrderId } from '@/biz/tool';

import { Tracker } from '../../utils';

import styles from './paymentButton.module.less';
import './custom.less';

export const PaymentButton: React.FC<{ onClose: Function }> = ({ onClose }) => {
  const orderId = getOrderId();
  return (
    <div id="bottom" className={styles.bottom}>
      <div className={styles.title}>
        Set up your payment details now to enjoy a smooth earnings experience.
      </div>
      <NavLink to={`/campaign/payment/method?orderId=${orderId}`}>
        <Button
          variant="contained"
          className={styles.joinButton}
          onClick={() => {
            Tracker.click('set up payment method');
          }}
        >
          Set up payment method
        </Button>
      </NavLink>
      <Button
        variant="contained"
        className={styles.notInterestedButton}
        onClick={() => {
          Tracker.click('not now for setting up payment method');
          onClose();
        }}
      >
        Not now
      </Button>
    </div>
  );
};
