import validator from 'validator';

export const youtubeLinkValidator = (input: string): boolean => {
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:youtu\.be\/|(?:(www|m)\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))(.)+$/;
  // 检查链接是否匹配正则表达式
  return youtubeRegex.test(input);
};

export function linkValidator(url: string): boolean {
  return validator.isURL(url);
}

export function emailValidator(email: string): boolean {
  return validator.isEmail(email);
}
