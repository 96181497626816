import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { OrderStatus } from '../../interface/order';
import { SuccessIcon, WarnIcon } from '../common/icon';
import { Tracker, copyToClipboard } from '@/utils';
import toast from 'react-hot-toast';
import { EmptyPlaceHolder } from './emptyPlaceHolder';
import { getUploadedRecordsFromActivity } from '@/biz/order';
import styles from './deliveryContent.module.less';

interface Props {
  data: any;
}

function formatDateTime(date?: string | Date | null) {
  if (!date) return '-';
  const dateTime = new Date(date);
  return `${dateTime.getFullYear()}-${('0' + (dateTime.getMonth() + 1)).slice(-2)}-${('0' + dateTime.getDate()).slice(-2)} ${('0' + dateTime.getHours()).slice(-2)}:${('0' + dateTime.getMinutes()).slice(-2)}`;
}

export const DeliveryContent: React.FC<Props> = ({
  data: { order, activity = [] } = {}
}) => {
  const [uploadedRecords, setUploadedRecords] = useState<any[]>([]);

  useEffect(() => {
    const records = getUploadedRecordsFromActivity(activity);
    // 这页只展示第一个
    setUploadedRecords(records.slice(0, 1));
  }, [activity, order]);

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        Delivery Content
        {order?._id ? (
          <NavLink
            to={`/campaign/draft/detail/${encodeURIComponent(order._id)}`}
          >
            <div
              className={styles.subTitle}
              onClick={() => Tracker.click('click submission history')}
            >
              Submission history
              <img
                alt="right arrow"
                src="/c-right.png"
                className={styles.rightIcon}
              />
            </div>
          </NavLink>
        ) : null}
      </div>

      {uploadedRecords.length > 0 ? (
        uploadedRecords.map((el: any) => {
          if (el.contentType === 'final') {
            return <FinalLinkContent item={el} />;
          } else if (el.contentType === 'draft') {
            return <LinkContent item={el} />;
          } else if (el.contentType === 'script') {
            return <LinkContent item={el} />;
          }
          return null;
        })
      ) : (
        <EmptyPlaceHolder />
      )}
    </div>
  );
};

let preMaxHeight = '';

export const LinkContent: React.FC<{ item: any }> = ({ item }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);

  useEffect(() => {
    const target = document.getElementById('feedback');
    if (target) {
      const scrollHeight = target.scrollHeight || 0;
      const offsetHeight = target.offsetHeight || 0;
      if (scrollHeight > offsetHeight) {
        setIsShowMoreBtn(true);
      }
    }
  }, []);

  useEffect(() => {
    const target = document.getElementById('feedback');
    if (target) {
      if (isShowMore) {
        preMaxHeight = target.style.maxHeight;
        target.style.display = 'block';
        target.style.maxHeight = 'unset';
      } else {
        target.style.display = '-webkit-box';
        target.style.maxHeight = preMaxHeight;
      }
    }
  }, [isShowMore]);

  return (
    <div className={styles.desc}>
      <div className={styles.draftTitle}>
        {item.contentType === 'script'
          ? `Script`
          : `Video Draft V${item?.version}`}
        {item?.confirmed ? (
          <span className={styles.draftSubTitle}>
            {item.contentType === 'script' ? (
              <>
                <SuccessIcon className={'w-4 h-4 mr-1'} />{' '}
                <span className="text-[#287f2e]">Approved</span>
              </>
            ) : (
              <>
                <img
                  alt="approved"
                  src="/c-approved.png"
                  className={styles.statusIcon}
                />{' '}
                <span className="text-[#5a31f0]">Content Approved</span>
              </>
            )}
          </span>
        ) : item?.feedback ? (
          <span className={styles.draftSubTitle}>
            <WarnIcon className={'w-4 h-4 mr-1'} />
            <span className="text-[#b06700]">
              {item.contentType === 'script'
                ? 'Feedback provided'
                : 'Revision needed'}
            </span>
          </span>
        ) : (
          <span className={styles.draftSubTitle}>Under review</span>
        )}
      </div>
      {item?.feedback ? (
        <div className={styles.feedbackCtn}>
          <div className={styles.feedback} id="feedback">
            {item?.feedback.text
              .split(/\r?\n/)
              .map((text: string, index: number) => {
                return (
                  <div key={index} className="feedbackItem">
                    {index === 0 ? (
                      <span className={styles.label}>Feedback: </span>
                    ) : null}
                    <div className={styles.feedbackItem}>{text}</div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
      {item?.text &&
        item?.text.split(/\r?\n/).map((text: string, index: number) => {
          // if (/^https?:\/\//.test(text)) {
          if (index === 0) {
            return (
              <div key={index}>
                <div className={styles.urlTitle}>
                  {item.contentType === 'script' ? 'Script link' : 'URL'}
                </div>
                <div translate="no" key={index} className={styles.linkTitle}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      /^(https:\/\/|http:\/\/)/.test(text)
                        ? text
                        : 'http://' + text
                    }
                    className={styles.linkText}
                  >
                    {text}
                  </a>
                  <img
                    alt="copy"
                    src="/c-copy.png"
                    className={styles.copyIcon}
                    onClick={() => {
                      copyToClipboard(text);
                      toast.success('Link copied.');
                    }}
                  />
                </div>
              </div>
            );
          }
          return (
            <div key={index} className={styles.linkDesc}>
              {text}
            </div>
          );
        })}
      <div className={styles.publishTime}>
        Submitted on {formatDateTime(item?.createdAt)}
      </div>
    </div>
  );
};

export const FinalLinkContent: React.FC<{ item: any }> = ({ item }) => {
  return (
    <div className={styles.finalLinkDesc}>
      <div className={styles.finalLinkTitle}>Final URL</div>
      <div className={styles.finalLinkSubtitle}>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            /^(https:\/\/|http:\/\/)/.test(item?.text)
              ? item?.text
              : 'http://' + item?.text
          }
        >
          <div translate="no" className={styles.finalLink}>
            {item?.text}
          </div>
        </a>
        <img
          alt="copy"
          src="/c-copy.png"
          className={styles.copyIcon}
          onClick={() => {
            copyToClipboard(item?.text);
            toast.success('Link copied.');
          }}
        />
      </div>
      <div className={styles.publishTime}>
        Submitted on {formatDateTime(item?.createdAt)}
      </div>
    </div>
  );
};
