import React, { useEffect, useState, useRef } from 'react';
import {
  generateCodeVerifier,
  generateCodeChallengeFromVerifier
} from './utils';
import { init, createElement } from '@airwallex/payouts-web-sdk';
import axiosInstance from '../../apis/axiosInstance';
import toast from 'react-hot-toast';

import Loading from '../../components/common/loading';

const Airwallex: React.FC<{ airwallexRef: any; defaultValues?: any }> = ({
  airwallexRef,
  defaultValues = {}
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    let element: any;

    const initSDK = async () => {
      try {
        const codeVerifier = generateCodeVerifier();
        const codeChallenge =
          await generateCodeChallengeFromVerifier(codeVerifier);
        const {
          data: {
            data: { airwallex, ...data }
          }
        } = await axiosInstance.post(`/payment/airwallex/componentConfig`, {
          codeChallenge
        });
        const config = {
          langKey: 'en',
          codeVerifier,
          ...data
        };
        await init(config);

        element = await createElement('beneficiaryForm', {
          defaultValues: airwallex || {},
          // TODO: 颜色配置成我们的主题色
          theme: {
            palette: {
              primary: {
                '100': '#2B1F74',
                '90': '#5B3DE9',
                '80': '#4F15DE',
                '70': '#5A31F0',
                '60': '#C9CCFF',
                '50': '#5A31F0',
                '40': '#E4E7FF',
                '30': '#EFF1FF',
                '20': '#F7F8FF',
                '10': '#FDFDFF'
              },
              gradients: {
                primary: ['#5A31F0', '#C9CCFF'],
                secondary: ['#5A31F0', '#C9CCFF'],
                tertiary: ['#5A31F0', '#C9CCFF'],
                quaternary: ['#5A31F0', '#C9CCFF']
              }
            },
            components: {
              spinner: {
                colors: {
                  start: {
                    initial: '#5A31F0'
                  },
                  stop: {
                    initial: '#9898FF'
                  }
                }
              }
            }
          }
        });

        elementRef.current = element;
        airwallexRef.current = element;

        await element?.mount('#airwallex-container');

        element?.on('ready', () => {
          setLoading(false);
          // console.log(' airwallex-container ready');
        });

        element?.on('error', (e: any) => {
          console.error('error', e);

          // SCHEMA_NOT_FOUND 出现在国家选择 Angola 时。
          if (e.code === 'SCHEMA_NOT_FOUND') {
            return;
          }

          toast.error('Network error. Please refresh your page and try again.');
        });

        element?.on('change', ({ values }: { values: any }) => {
          // Handle when form values changes
          console.log('change', values);
          const country_code = values?.beneficiary?.address?.country_code;
          const payment_methods = values?.payment_methods || [];
          if (country_code === 'CN' && payment_methods.includes('LOCAL')) {
            toast.error('Not support LOCAL payment method for China.', {
              id: 'CN_LOCAL_NOT_SUPPORT'
            });
          }
        });

        // element?.on('formState', ({loading, validating, errors}: {loading: boolean; validating: any; errors: any}) => {
        //   // Handle when form state changes
        //   console.log('formState', {loading, validating, errors})
        // });

        // const submitButton = document.getElementById('submit-button')
        // if (submitButton) {
        //   submitButton.addEventListener('click', async () => {

        //   });
        // }

        // // create onboarding element
        // element = await createElement('beneficiaryForm', {
        //   hideHeader: true,
        //   hideNav: false,
        // });

        // // append to DOM
        // await element?.mount('kyc-rfi');

        // // subscribe element events
        // element?.on('ready', (event: any) => {
        //   handleReady(event);
        // });
        // element?.on('cancel', (event: any) => {
        //   handleCancel(event);
        // });
        // element?.on('success', () => {
        //   handleSuccess();
        // });
        // element?.on('error', (event: any) => {
        //   handleError(event);
        // });
      } catch (e: any) {
        setLoading(false);
        console.error(e);
        toast.error(e.message);
      }
    };

    initSDK();

    return () => elementRef.current?.destroy();
  }, []);

  // const save = async () => {
  //   // const formResult = await elementRef.current?.submit();
  //   // // Handle form results
  //   // console.log(formResult);
  //   // if(formResult?.errors){
  //   //   toast.error(`Error: ${formResult?.errors?.code}`)
  //   //   return
  //   // }
  //   // const { data: { code, message }} = await axiosInstance.post(`/payment/account`, {
  //   //   type: 'airwallex',
  //   //   airwallex: formResult?.values
  //   // })
  //   // if(code === 0) {
  //   //     // TODO: 更新成功的提示
  //   //     window.location.href = window.localStorage.getItem('homepage') || "/campaign/payment/method"
  //   //     return
  //   // } else {
  //   //     toast.error(message || 'Failed to save payment info.')
  //   // }
  //   // {"type":"airwallex","airwallex":{"beneficiary":{"address":{"city":"不知道","country_code":"CN","postcode":"10010","state":"CN-AH"},"additional_info":{"personal_first_name_in_chinese":"三","personal_id_number":"320000199001010000","personal_id_type":"CHINESE_NATIONAL_ID","personal_last_name_in_chinese":"张"},"bank_details":{"account_currency":"CNY","account_name":"张三","account_number":"1111111111","account_routing_type1":"bank_code","account_routing_type2":"area_code","account_routing_value1":"322","account_routing_value2":"3680","bank_country_code":"CN","bank_name":"上海农村商业银行","bank_state":"安徽省"},"entity_type":"PERSONAL","first_name":"三","last_name":"张"},"payment_methods":["LOCAL"]}}
  //   //   {
  //   //     "values": {
  //   //         "beneficiary": {
  //   //             "entity_type": "PERSONAL",
  //   //             "bank_details": {

  //   //             },
  //   //             "additional_info": {

  //   //             },
  //   //             "address": {
  //   //                 "country_code": "CN",
  //   //                 "state": "CN-AH"
  //   //             }
  //   //         },
  //   //         "payment_methods": [
  //   //             "LOCAL"
  //   //         ]
  //   //     },
  //   //     "errors": {
  //   //         "code": "VALIDATION_FAILED"
  //   //     }
  //   // }
  // };

  return (
    <>
      <div
        style={{ display: loading ? 'none' : 'block', paddingBottom: '200px' }}
        id="airwallex-container"
      />
      {/* <button style={{ display: loading ? 'none' : 'block' }} id="submit-button" onClick={() => save()}>Save</button> */}
      {loading ? <Loading open={true} /> : null}
    </>
  );
};

export default Airwallex;
