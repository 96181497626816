import { OrderStatus } from '../../interface/order';
import React, { useState, useEffect } from 'react';
import {
  AssetsAnnexIcon,
  CardAnnexIcon,
  BrandAnnexIcon,
  DownloadIcon
} from '../common/icon';
import { copyToClipboard } from '../../utils';
import CircularProgress, {
  CircularProgressProps
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import axiosInstance from '../../apis/axiosInstance';
import download from '../../biz/download';
import toast from 'react-hot-toast';
import styles from './otherInfomation.module.less';
import { CopyToClipboard } from '../common/copyToClipboard';

interface Props {
  data: any;
}

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <div className={styles.progressCtn}>
      <CircularProgress variant="determinate" {...props} />
      <div className={styles.progressText}>
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </div>
    </div>
  );
};

export const BrandResources: React.FC<Props> = ({
  data: { campaign, order, timeline, activity } = {}
}) => {
  const [assets, setAssets] = useState<any>([]);
  const [progress, setProgress] = useState<any>([]);
  //       - 达人被反选前或申请被拒绝，展示：Short link will be generated after your application is approved
  //       - 达人被反选前或申请被拒绝，展示：Coupon will be generated after your application is approved
  //       - 达人被反选前或申请被拒绝，展示“You will be able to access the assets after your application is approved"
  const isAvailable = (order: any) => {
    return ![
      OrderStatus.Created,
      OrderStatus.Inviting,
      OrderStatus.Invited,
      OrderStatus.CreatorApplied,
      OrderStatus.Cancelled
    ].includes(order?.status);
  };

  useEffect(() => {
    let arr: any[] = [];
    if (Array.isArray(campaign?.mustDoFiles)) {
      arr = arr.concat(campaign?.mustDoFiles);
    }
    if (Array.isArray(campaign?.mustNotDoFiles)) {
      arr = arr.concat(campaign?.mustNotDoFiles);
    }
    setAssets(arr);
  }, [campaign?.mustDoFiles, campaign?.mustNotDoFiles]);

  const updateProgress = (index: number, val: number) => {
    if (isNaN(val)) return;
    const newProgress = [...progress];
    newProgress[index] = val === 100 ? -1 : val;
    setProgress(newProgress);
  };

  const getDownloadLink = async (key: any, index: number) => {
    updateProgress(index, 1);
    try {
      const {
        data: { code, message, data }
      } = await axiosInstance.post(
        `/campaign/${order.campaignId}/downloadLink`,
        {
          key
        }
      );
      const url = data?.presignedUrl;
      if (!url) {
        throw new Error(message || 'Failed to get download link');
      }
      download(url, key, (val: number) => {
        updateProgress(index, val);
      });
    } catch (e) {
      console.error(e);
      toast.error('Download failed.');
    }
  };

  if (!isAvailable(order)) {
    return (
      <div className={styles.otherCtn}>
        <div className={styles.title}>Brand Resources</div>
        <div className={styles.itemCtn}>
          You will be able to access the assets after your application is
          approved
        </div>
      </div>
    );
  }

  return (
    <div className={styles.otherCtn}>
      <div className={styles.title}>Brand Resources</div>
      <div className={styles.itemCtn}>
        <div className={styles.label}>Promotional link</div>
        <div className={order?.utmLink ? styles.link : styles.value}>
          {order?.utmLink || '-'}
          {order?.utmLink && (
            <CopyToClipboard
              onClick={() => {
                copyToClipboard(order?.utmLink);
                toast.success('Link copied.');
              }}
            />
          )}
        </div>
      </div>

      {order?.credentialForInfluencer?.username ? (
        <div className={styles.itemCtn}>
          <div className={styles.label}>Trial account username</div>
          <div className={styles.value}>
            {order?.credentialForInfluencer?.username}
            {order?.credentialForInfluencer?.username && (
              <CopyToClipboard
                onClick={() => {
                  copyToClipboard(order?.credentialForInfluencer?.username);
                  toast.success('Trial account username copied.');
                }}
              />
            )}
          </div>
        </div>
      ) : null}
      {order?.credentialForInfluencer?.password ? (
        <div className={styles.itemCtn}>
          <div className={styles.label}>Trial account password</div>
          <div className={styles.value}>
            {order?.credentialForInfluencer?.password}
            {order?.credentialForInfluencer?.password && (
              <CopyToClipboard
                onClick={() => {
                  copyToClipboard(order?.credentialForInfluencer?.password);
                  toast.success('Trial account password copied.');
                }}
              />
            )}
          </div>
        </div>
      ) : null}

      {/* 上面是临时版本 */}
      {/* 下面是产品化版本 */}
      {order?.credential?.username ? (
        <div className={styles.itemCtn}>
          <div className={styles.label}>Trial account username</div>
          <div className={styles.value}>
            {order?.credential?.username}
            {order?.credential?.username && (
              <CopyToClipboard
                onClick={() => {
                  copyToClipboard(order?.credential?.username);
                  toast.success('Trial account username copied.');
                }}
              />
            )}
          </div>
        </div>
      ) : null}
      {order?.credential?.password ? (
        <div className={styles.itemCtn}>
          <div className={styles.label}>Trial account password</div>
          <div className={styles.value}>
            {order?.credential?.password}
            {order?.credential?.password && (
              <CopyToClipboard
                onClick={() => {
                  copyToClipboard(order?.credential?.password);
                  toast.success('Trial account password copied.');
                }}
              />
            )}
          </div>
        </div>
      ) : null}

      {order?.couponCode ? (
        <div className={styles.itemCtn}>
          <div className={styles.label}>Coupon Code</div>
          <div className={styles.value}>
            {order?.couponCode}
            <CopyToClipboard
              onClick={() => {
                copyToClipboard(order?.couponCode);
                toast.success('Coupon code copied.');
              }}
            />
          </div>
        </div>
      ) : null}

      <div className={styles.annexList}>
        {assets.map((item: any, index: number) => {
          return (
            <div key={index} className={styles.annex}>
              <div className={styles.annexLeft}>
                {
                  [
                    <AssetsAnnexIcon className={styles.annexIcon} />,
                    <CardAnnexIcon className={styles.annexIcon} />,
                    <BrandAnnexIcon className={styles.annexIcon} />
                  ][index % 3]
                }
                <div className="descCtn">
                  <div>{item}</div>
                  {/* <div className={styles.descLink}>
                  https://media-kit.surfe.com/
                </div> */}
                </div>
              </div>
              <div onClick={() => getDownloadLink(item, index)}>
                {!progress[index] || progress[index] === -1 ? (
                  <DownloadIcon className={styles.annexRight} />
                ) : (
                  <CircularProgressWithLabel value={progress} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
