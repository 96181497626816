import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTaskByIdCheckLogin } from '../../apis/taskApi';

const TaskDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [task, setTask] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTask = async () => {
      const data = await getTaskByIdCheckLogin(id!);
      setTask(data);
      setLoading(false);
    };

    fetchTask();
  }, [id]);

  if (loading) return <div className="flex justify-center items-center h-screen"><div className="loader"></div></div>;

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">InfluencerTask Details</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="border p-4 rounded-md">
          <span className="font-bold">Name: </span>
          {task.name}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">PromotionalLink: </span>
          {task.promotionalLink}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">Quote: </span>
          {task.quote}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">Requirement: </span>
          {task.requirement}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">MediaPlatform: </span>
          {task.mediaPlatform}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">MediaPlatformId: </span>
          {task.mediaPlatformId}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">CampaignId: </span>
          {task.campaignId}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">InfluencerId: </span>
          {task.influencerTaskId}
        </div>
        <div className="border p-4 rounded-md">
          <span className="font-bold">Status: </span>
          {task.status}
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;