import React from 'react';
import Header from "../../components/campaign/campaignHeader"
import styles from "./error.module.less"

const ErrorPage: React.FC = () => {



    return (
        <>
            <Header title="Error" />
            <div className={styles.linked}>
                <img src={"/c-errorBg.png"} className={styles.linkedImg} />
                <div className={styles.desc}>
                    Sorry, the page you are trying to access is lost. Please try again later.
                </div>

            </div>
        </>
    );
};

export default ErrorPage;
