import React, { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/campaign/campaignHeader';
import { getOrderPlatform } from '../../biz/order';
import { Tracker } from '../../utils';
import styles from './loginError.module.less';
import { useLoginStore } from '@/hooks/useLoginStore';
import { LoginButtonNoStyle } from '@/components/campaign/loginButtonNoStyle';

const LinkedPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { influencerUserGooglePlatformEmail } = useLoginStore();
  const [buttonLoading, setButtonLoading] = useState(false);
  const query = new URLSearchParams(location.search);
  const status = query.get('status');
  const platform = query.get('platform');
  const errorType = query.get('type');

  const textMap: any = {
    accountNotMatch: {
      img: '/c-failed.png',
      title: (platform: any) => `Login accounts do not match`,
      desc: (platform: any) => (
        <div className="flex flex-col items-center rounded-lg px-[6px] py-4 mt-1">
          <p className="mb-2 text-center">
            The YouTube account linked to this deal is already associated with{' '}
            <strong>{influencerUserGooglePlatformEmail}</strong>. Try logging in
            with that email.
          </p>
          <p>
            Need Help? Contact
            <a
              href="mailto:support@ahalab.ai"
              className="text-center text-[#0c67e6] text-sm font-normal font-['Inter'] underline leading-snug ml-1"
            >
              support@ahalab.ai
            </a>
          </p>
        </div>
      ),
      button: 'Return to login',
      buttonLoading: 'Returning to login...'
    },
    defaultError: {
      img: '/c-failed-no-result.png',
      title: (platform: any) => `No results found`,
      desc: (platform: any) => (
        <div className="flex flex-col items-center rounded-lg px-[6px] py-4 mt-1">
          <p className="mb-2 text-center">
            Please try refreshing the page or contact our customer support.
          </p>
          <p>
            Need Help? Contact
            <a
              href="mailto:support@ahalab.ai"
              className="text-center text-[#0c67e6] text-sm font-normal font-['Inter'] underline leading-snug ml-1"
            >
              support@ahalab.ai
            </a>
          </p>
        </div>
      ),
      button: 'Return to login',
      buttonLoading: 'Returning to login...'
    }
  };
  // TODO: 根据上面的 platform 处理 图片 和 文案
  let currentText = textMap[errorType || 'defaultError'];

  return (
    <>
      <Header title="Login" />
      <div className={styles.linked}>
        <img
          alt="login error"
          src={currentText.img}
          className={styles.linkedImg}
        />

        <div className={styles.title}>{currentText.title(platform)}</div>
        <div className={styles.desc}>{currentText.desc(platform)}</div>

        <LoginButtonNoStyle
          buttonLoading={buttonLoading}
          setButtonLoading={setButtonLoading}
        >
          <div className={styles.buttonBox}>
            <div
              onClick={() => {
                Tracker.click(currentText.button);
              }}
              className={styles.button}
            >
              {buttonLoading ? currentText.buttonLoading : currentText.button}
            </div>
          </div>
        </LoginButtonNoStyle>
      </div>
    </>
  );
};

export default LinkedPage;
