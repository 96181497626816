import React from 'react';
import { NavLink } from 'react-router-dom';
import { CheckIcon, BlueCheckIcon } from '../common/icon';
import { getDuration, getDurationFormatBusinessDays } from '@/biz/time';
import { getOrderId } from '@/biz/tool';

import {
  AHA_DOCS_LINK,
  getFrontPaymentRate
} from '../../pages/campaign/config';

import styles from './snackbar.module.less';
import './custom.less';

// review
const review = (hours: number | null) => {
  if (hours) return getDurationFormatBusinessDays(hours);
  return '-';
};
interface Props {
  data: any;
}
export const CreatorRejectedSnackbar: React.FC<{ activity: any }> = ({
  activity
}) => {
  function creatorRejectedAt(createdAt: string | Date | null) {
    if (!createdAt) return '';
    return ` on ${new Date(createdAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })}`;
  }
  return (
    <div className={styles.snackbar} style={{ backgroundColor: '#FFEBE5' }}>
      <img src="/c-error.png" className={styles.icon} />
      <div className={styles.desc}>
        <span className={styles.label}>Campaign Update: </span>
        You declined the campaign{creatorRejectedAt(activity?.createdAt)}
      </div>
    </div>
  );
};
export const NotApprovedSnackbar: React.FC = () => {
  return (
    <div className={styles.snackbar} style={{ backgroundColor: '#FFEBE5' }}>
      <img src="/c-error.png" className={styles.icon} />
      <div className={styles.desc}>
        <span className={styles.label}>Application Not Approved: </span>
        It wasn't a match this time, but stay tuned for more opportunities
        coming soon!
      </div>
    </div>
  );
};

export const ScriptUnderReviewSnackbar: React.FC<{ timeline: any }> = ({
  timeline
}) => {
  console.log('timeline', timeline);
  return (
    <div className={styles.snackbar} style={{ backgroundColor: '#e2f5ff' }}>
      <BlueCheckIcon className={styles.blueClockIcon} />
      <div className={styles.desc}>
        <span className={styles.label}>Your script is under review.</span> We'll
        email your result within {review(timeline?.review?.maxWaitInHours)}.
      </div>
    </div>
  );
};

export const DraftUnderReviewSnackbar: React.FC<{ timeline: any }> = ({
  timeline
}) => {
  return (
    <div className={styles.snackbar} style={{ backgroundColor: '#e2f5ff' }}>
      <BlueCheckIcon className={styles.blueClockIcon} />
      <div className={styles.desc}>
        <span className={styles.label}>Draft under review: </span>
        Your draft's under review. We'll email your result within{' '}
        {timeline?.review?.maxWaitInHours
          ? timeline.review.maxWaitInHours
          : 'N/A'}{' '}
        hours.
      </div>
    </div>
  );
};

// 预付款全局提醒
export const FrontPaymentSnackbar: React.FC<{
  isUpdate: boolean;
  backgroundColor: string;
  orderId: string;
  prepaymentRate: number;
  title: string;
  Icon?: any;
  airwallexId?: string;
}> = ({
  isUpdate,
  backgroundColor,
  orderId,
  prepaymentRate,
  Icon,
  airwallexId,
  title
}) => {
  const rate = getFrontPaymentRate(prepaymentRate);
  return (
    <div className={styles.snackbar} style={{ backgroundColor }}>
      <Icon className={styles.blueClockIcon} />
      <div className={styles.desc} style={{ fontWeight: '400' }}>
        <div className={styles.frontPaymentTitle}>{title}</div>
        <div>
          {isUpdate ? (
            airwallexId ? (
              <span>
                We're unable to transfer payment to your current payment method.
                Please update your details.
              </span>
            ) : (
              <span>
                Provide your payment method to receive your {rate} upfront
                payment!
              </span>
            )
          ) : (
            <span>
              Get a {rate} upfront payment as soon as your application is
              approved. Join now to start earning!
            </span>
          )}
          {isUpdate ? (
            <NavLink to={`/campaign/payment/method?orderId=${orderId}`}>
              <span className="text-[#0c67ef]" style={{ marginLeft: '8px' }}>
                {airwallexId ? 'Update now' : 'Set up'}
              </span>
            </NavLink>
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href={AHA_DOCS_LINK}
              className="text-[#0c67ef]"
              style={{ marginLeft: '8px' }}
            >
              Learn more
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export const ApprovedSnackbar: React.FC<{
  onClose: Function;
  payment: any;
}> = ({ onClose, payment }) => {
  const orderId = getOrderId();
  // TODO: 根据是否设置了 payment 显示不同的内容
  return (
    <div className={styles.snackbar} style={{ backgroundColor: '#E8F7E8' }}>
      <CheckIcon className={styles.checkIcon} />
      <div className={styles.right}>
        <div className={styles.desc}>
          <span className={styles.label}>Application Approved: </span>
          We recommend setting up your payment method before starting the
          collaboration.
        </div>
        <div className={styles.buttonCtn}>
          <NavLink to={`/campaign/payment/method?orderId=${orderId}`}>
            <div className={styles.setButton}>Set up</div>
          </NavLink>
          <div className={styles.notButton} onClick={() => onClose()}>
            {' '}
            Not now
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReviewSnackbar: React.FC<Props> = ({
  data: { timeline } = {}
}) => {
  // matching
  const matching = (hours: number | null) => {
    if (hours) return getDuration(hours);
    return '-';
  };
  return (
    <div className={styles.snackbar} style={{ backgroundColor: '#E2F5FF' }}>
      <img src="/c-clock.png" className={styles.icon} />
      <div className={styles.desc}>
        <span className={styles.label}>Application Under Review: </span>
        We'll email/DM you the outcome within 1-2 business days. Stay tuned!
      </div>
    </div>
  );
};
