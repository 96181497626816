// /auth
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../apis/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import { getOrderPlatform } from '../../biz/order';
import { getAuthPageUrl, getOrderId, getLinkPageUrl } from '@/biz/tool';

// TODO: 其实跳转到第三方是自动的，没有中间可以选的页面, 订单上已经限定了平台
// 需要改成从通用接口获取到跳转的链接, 目前先写死

const LoginPage: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let platform = query.get('platform') || '__platform__';
  const orderId = getOrderId();
  const handleLogin = async () => {
    const authPageUrl = getAuthPageUrl();

    try {
      const {
        data: { code, message, data }
      } = await axiosInstance.post(`/auth/${platform}`, {
        // 某些平台在本地测试时 不能使用 http://localhost
        // redirect_uri: `https://ahalab.io/auth/${platform}/callback`,
        // redirect_uri: `https://platform.test.ahalab.io/auth/${platform}/callback`,
        // redirect_uri: `http://localhost:5000/auth/${platform}/callback`,
        redirect_uri: `${window.location.origin}/auth/${platform}/callback`,
        orderId
      });
      if (code === 0 && data?.url) {
        setTimeout(() => {
          window.location.href = data?.url;
        }, 0);
      } else {
        // 不展示后端报错, 直接跳转到失败页
        window.location.href = getLinkPageUrl({
          status: 'fail',
          platform,
          redirect: authPageUrl
        });
      }
    } catch (error: any) {
      console.error('auth url error', error);
      // 不展示后端报错, 直接跳转到失败页
      window.location.href = getLinkPageUrl({
        status: 'fail',
        platform,
        redirect: authPageUrl
      });
    }
  };
  useEffect(() => {
    let orderPlatform;
    try {
      orderPlatform = window.localStorage.getItem('orderPlatform');
    } catch (e) {}
    toast.loading(
      `Redirecting to ${orderPlatform ? getOrderPlatform({ platform: orderPlatform }) : 'third-party platform'} access authorization page...`
    );
    handleLogin();
  }, []);

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: 12,
            borderRadius: 4,
            background: '#1C2024',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            color: '#fff'
          }
        }}
      />
    </>
  );
  // return (
  //   <div className="min-h-screen flex items-center justify-center bg-gray-100">
  //      Loading...
  //     {/* <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
  //       <h1 className="text-2xl font-bold mb-6">Login</h1>
  //         <div className="text-center mt-6">
  //           <button
  //             type="button"
  //             onClick={handleLogin}
  //             className="w-full bg-blue-500 text-white py-2 rounded"
  //           >
  //             Login with Twitter
  //             Loading...
  //           </button>
  //         </div>
  //     </div> */}
  //   </div>
  // );
};

export default LoginPage;
