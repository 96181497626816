import React, { useEffect, useState } from 'react';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import axiosInstance from '../../apis/axiosInstance';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { OrderStatus } from '../../interface/order';
import Header from '../../components/campaign/campaignHeader';
import { CampaignInfo } from '../../components/campaign/campaignInfo';
import { RequirementsDialog } from '../../components/campaign/requirementsDialog';
import { CampaignBrandInfo } from '../../components/campaign/campaignBrandInfo';
import { ContentRequirements } from '../../components/campaign/contentRequirements';
import { BrandResources } from '../../components/campaign/otherInfomation';
import { CampaignPayment } from '../../components/campaign/campaignPayment';
import { JoinButton } from '../../components/campaign/joinButton';
import { PaymentButton } from '../../components/campaign/paymentButton';
import { SubmissionHistoryButton } from '../../components/campaign/submissionHistoryButton';
import { SubmitScriptOrDraftButton } from '../../components/campaign/submitDraftButton';
import { SubmitFinalLink } from '../../components/campaign/submitFinalLink';
import { Tracker } from '../../utils';
import { datafluxRum } from '@cloudcare/browser-rum';
import {
  FrontPaymentIcon,
  FrontPaymentUpdateIcon,
  FrontPaymentSetupIcon
} from '../../components/common/icon';
import { LoginDetailBottomButton } from '@/components/campaign/loginDetailBottomButton';
import {
  ReviewSnackbar,
  NotApprovedSnackbar,
  ScriptUnderReviewSnackbar,
  DraftUnderReviewSnackbar,
  CreatorRejectedSnackbar,
  FrontPaymentSnackbar
} from '../../components/campaign/snackbar';
import { fixedDetailBottomHeight } from '../../utils';
import Loading from '../../components/common/loading';
import toast from 'react-hot-toast';
import {
  getOrderId,
  getToken,
  checkLogin,
  getLoginSuccessFromUrlSearch,
  getDetailPageUrl
} from '@/biz/tool';
import '../../components/campaign/custom.less';
import styles from './detail.module.less';
import { useLoginStore } from '@/hooks/useLoginStore';
import { frontPaymentAcountStatus, getFrontPaymentRate } from './config';
import { growthbook } from '@/index';

const DetailPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const orderId = getOrderId();
  const [data, setData] = useState<any>({});

  const [tabId, setTabId] = React.useState(0);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const enabled = useFeatureIsOn('pricing_schema');
  const value = useFeatureValue(
    'pricing_schema',
    'display_price_cannot_change_bad'
  );

  useEffect(() => {
    console.log(`aha-enabled: ${enabled}, value: ${value}`);
  }, [enabled, value]);

  useEffect(() => {
    const labelArr = ['view campaign info', 'view content', 'view payment'];

    Tracker.click(labelArr[tabId]);
  }, [tabId]);

  const getData = async (orderId: string) => {
    try {
      setLoading(true);
      window.localStorage.setItem(
        'auth_redirect',
        `/campaign/detail/${orderId}`
      );

      window.localStorage.setItem('homepage', `/campaign/detail/${orderId}`);

      // 同时发出三个请求
      const detailPromise = axiosInstance.get(
        `/order/detail/${encodeURIComponent(orderId)}`
      );
      const activityPromise = axiosInstance.get(
        `/order/${encodeURIComponent(orderId)}/activities`
      );

      let detailData: any = {};
      // 立即处理订单详情
      detailPromise
        .then((response) => {
          detailData = response.data.data;
          checkLogin(detailData);

          setData(detailData); // 立即更新订单详情
        })
        .catch((error) => {
          console.error('获取订单详情失败:', error);
        });

      // 等待所有请求完成
      const [activityResponse] = await Promise.allSettled([activityPromise]);

      let newData: any = { ...detailData }; // 使用当前的 data 状态

      if (activityResponse.status === 'fulfilled') {
        let activity = activityResponse.value.data.data?.activity || [];
        newData.activity = activity;
      } else {
        console.error('获取活动数据失败:', activityResponse.reason);
      }

      setData(newData); // 更新完整的数据
    } catch (e: any) {
      toast.error(e.message || '获取数据失败。');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getLoginSuccessFromUrlSearch()) {
      setTimeout(() => {
        toast.success('Login successful!');
        // 清除url中的loginSuccess参数
        const url = getDetailPageUrl();
        window.history.pushState({}, '', url);
      }, 0);
    }
    const handleScroll = () => {
      const tabCtn = document.getElementById('tabCtn');
      const target = document.getElementById('detailTab');

      if (tabCtn && target) {
        const top = tabCtn.getBoundingClientRect().top;

        if (top < 5) {
          target.style.position = 'fixed';
        } else {
          target.style.position = 'relative';
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!data?.order) return;
    const orderStatus = data?.order?.status;
    // 头像区域
    if (data?.influencer) {
      window.localStorage.setItem(
        'profile',
        JSON.stringify({
          name: data?.influencer?.name,
          avatar: data?.influencer?.profilePictureLink
        })
      );
    }
    // 提醒观看Requirements
    if (
      [OrderStatus.UnderProduction].includes(orderStatus) &&
      data?.order?.contentStage === 'Script'
    ) {
      let contentRequirementsViewed;
      try {
        contentRequirementsViewed = window.localStorage.getItem(
          `content_requirements_viewed-${orderId}`
        );
      } catch (e) {}
      setIsShowDialog(contentRequirementsViewed ? false : true);
    } else {
      // try {
      //   window.localStorage.removeItem(
      //     `content_requirements_viewed-${orderId}`
      //   );
      // } catch (e) {}
    }
    // 页签切换
    if (
      [
        OrderStatus.Invited,
        OrderStatus.CreatorApplied,
        OrderStatus.Created,
        OrderStatus.Inviting,
        OrderStatus.Cancelled
      ].includes(orderStatus)
    ) {
      setTabId(0);
    } else if (
      [
        OrderStatus.PendingPublish,
        OrderStatus.PendingPayment,
        OrderStatus.PaymentSubmitted,
        OrderStatus.Paid,
        OrderStatus.PaymentFailed,
        OrderStatus.Completed
      ].includes(orderStatus)
    ) {
      setTabId(2);
    } else {
      setTabId(1);
    }

    if (data.order.campaignId && data.order._id) {
      growthbook.setAttributes({
        id: data.order.campaignId + data.order._id,
        url: window.location.href,
        path: window.location.pathname,
        host: window.location.host,
        query: window.location.search,
        deviceType: 'foo',
        browser: navigator.userAgent,
        utmSource: 'foo',
        utmMedium: 'foo',
        utmCampaign: 'foo',
        utmTerm: 'foo',
        utmContent: 'foo',
        platform_user_id: data?.user?.__id || 'foo',
        campaign_id: data.order.campaignId
      });
    }
  }, [data]);

  useEffect(() => {
    if (orderId) {
      getData(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (data?.influencer) {
      let lastUsedTimezone = data?.influencer?.lastUsedTimezone;
      console.log(`lastUsedTimezone: ${lastUsedTimezone}`);
      let timezone: any;
      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(`timezone: ${timezone}`);
      } catch (e) {
        console.error('Fail to get timezone', e);
        const error = new Error('MOBILE: Fail to get timezone');
        datafluxRum && datafluxRum.addError(error);
      }
      if (getToken() && timezone && timezone !== lastUsedTimezone) {
        console.log('updating timezone');
        axiosInstance
          .post(`/order/${orderId}/updateInfluencerTimezone`, { timezone })
          .catch((e) => {
            console.error('Fail to update timezone', e);
            const error = new Error('MOBILE: Fail to update timezone');
            datafluxRum && datafluxRum.addError(error);
          });
      }
    }
  }, [data?.influencer, orderId]);

  const handleChange = (event: any, newValue: any) => {
    setTabId(newValue);
  };

  fixedDetailBottomHeight();

  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <Header title="Campaign details" />
      <RequirementsDialog
        isOpen={isShowDialog}
        setIsOpen={(isOpen: boolean) => {
          setTabId(1);
          setIsShowDialog(isOpen);
          window.localStorage.setItem(
            `content_requirements_viewed-${orderId}`,
            'true'
          );
        }}
      />
      <div id="detail" className={styles.detail}>
        {/* 顶部提示横幅 */}
        <TopSnackBar data={data} />
        {/* 活动明细 */}
        <CampaignInfo data={data} />
        {/* Tabs */}
        <div id="tabCtn" className={styles.tabCtn}>
          <Tabs id="detailTab" value={tabId} onChange={handleChange}>
            <Tab label="Campaign info" style={{ lineHeight: 'normal' }} />
            <Tab
              style={{ lineHeight: 'normal' }}
              label="Content Requirements"
            />
            <Tab label="Payment" style={{ lineHeight: 'normal' }} />
            <div className="h-[102px]"></div>
          </Tabs>
        </div>
        {/* 主要内容 */}
        {tabId === 0 && <CampaignBrandInfo data={data} />}
        {tabId === 0 ? <BrandResources data={data} /> : null}
        {
          // 提前加载 iframe
          <div className={`${tabId === 1 ? 'block' : 'hidden'}`}>
            <ContentRequirements data={data} />{' '}
          </div>
        }
        {tabId === 2 ? <CampaignPayment data={data} /> : null}

        {/* 底部操作按钮 */}
        <BottomButton data={data} />
      </div>
    </>
  );
};

export default DetailPage;

const BottomButton = ({ data }: any) => {
  const { order, activity, timeline, payment } = data;
  const [clickNotNow, setClickNotNow] = useState(false);
  const { isLogin, isLoginMatch, influencerUserGooglePlatformEmail } =
    useLoginStore();
  const bottomButtonMap = {
    noBottomButton: {
      bottomComponent: <></>
      // bottomDesc: ''
    },
    joinButton: {
      bottomComponent: <JoinButton data={data} />,
      bottomDesc: 'to join the campaign'
    },
    paymentMethod: {
      bottomComponent: <PaymentButton onClose={() => setClickNotNow(true)} />
      // bottomDesc: 'to add payment method'
    },
    submitDraft: {
      bottomComponent: (
        <SubmitScriptOrDraftButton data={{ order, activity, timeline }} />
      )
      // bottomDesc: 'to submit draft'
    },
    submitFinalLink: {
      bottomComponent: <SubmitFinalLink data={{ order, payment, timeline }} />
      // bottomDesc: 'to submit final link'
    },
    showSubmissionHistory: {
      bottomComponent: <SubmissionHistoryButton />
      // bottomDesc: 'to view Submission history'
    }
  };

  let buttonComponent: any = bottomButtonMap['noBottomButton'];

  if ([OrderStatus.Created].includes(order?.status)) {
    //
  } else if ([OrderStatus.Invited].includes(order?.status)) {
    buttonComponent = bottomButtonMap['joinButton'];
  } else if ([OrderStatus.CreatorApplied].includes(order?.status)) {
    if (!data?.payment && !clickNotNow) {
      buttonComponent = bottomButtonMap['paymentMethod'];
    }
  } else if (
    [OrderStatus.UnderProduction, OrderStatus.ReviewRejected].includes(
      order?.status
    )
  ) {
    buttonComponent = bottomButtonMap['submitDraft'];
  } else if (
    [OrderStatus.PendingPublish, OrderStatus.PublishRejected].includes(
      order?.status
    )
  ) {
    buttonComponent = bottomButtonMap['submitFinalLink'];
  } else if (
    [
      // 审核状态
      OrderStatus.UnderReview,
      // 支付状态
      OrderStatus.PendingPayment,
      OrderStatus.PaymentSubmitted,
      OrderStatus.Paid,
      OrderStatus.PaymentFailed,
      // 其他状态
      OrderStatus.Cancelled,
      OrderStatus.Completed
    ].includes(order?.status)
  ) {
    buttonComponent = bottomButtonMap['showSubmissionHistory'];
  }

  if (!data.order) return null;
  if (!(isLogin && isLoginMatch)) {
    return <LoginDetailBottomButton desc={buttonComponent.bottomDesc} />;
  }

  return <>{buttonComponent.bottomComponent}</>;
};

const TopSnackBar = ({ data }: any) => {
  const { order, timeline, payment, user } = data;
  const orderStatus = order?.status;
  const rate = getFrontPaymentRate(order?.prepaymentRate);

  const [showSnackBar, setShowSnackBar] = useState(true);

  if (!showSnackBar) {
    return null;
  }

  /**
   * 当前环境中优先级最高的全局提醒
   * 预付款当达人接单后直到订单取消的中间所有状态下，达人为设置收款账号或账号有误时展示
   * isValid 字段为后端判断当前收款账号是否可用的字段，分为两种情况 1.未创建 2.账号有误
   * airwallexId 为后端储存收款信息的id。如果没有id说明账号未创建
   */
  if (
    !payment?.isValid &&
    frontPaymentAcountStatus.includes(orderStatus) &&
    user?.id
  ) {
    return (
      <FrontPaymentSnackbar
        isUpdate={true}
        backgroundColor={payment?.airwallexId ? '#ffeddc' : '#e2f5ff'}
        orderId={order?.id}
        prepaymentRate={order?.prepaymentRate}
        airwallexId={payment?.airwallexId}
        Icon={
          payment?.airwallexId ? FrontPaymentUpdateIcon : FrontPaymentSetupIcon
        }
        title={
          payment?.airwallexId
            ? 'Update Your Payment Details'
            : 'Set up your payment'
        }
      />
    );
  }

  if ([OrderStatus.Cancelled].includes(orderStatus)) {
    if (
      data?.activity?.[0]?.targetStatus === OrderStatus.Cancelled &&
      data?.activity?.[0]?.sourceStatus === OrderStatus.CreatorRejected
    ) {
      return <CreatorRejectedSnackbar activity={data?.activity?.[0]} />;
    }
    if (
      data?.activity?.[0]?.targetStatus === OrderStatus.Cancelled &&
      data?.activity?.[0]?.sourceStatus === OrderStatus.ApplyRejected
    ) {
      return <NotApprovedSnackbar />;
    }
  } else if ([OrderStatus.CreatorApplied].includes(orderStatus)) {
    return <ReviewSnackbar data={data} />;
  } else if ([OrderStatus.UnderReview].includes(orderStatus)) {
    if (order?.contentStage === 'Script') {
      return <ScriptUnderReviewSnackbar timeline={timeline} />;
    }

    if (order?.contentStage !== 'Script') {
      return <DraftUnderReviewSnackbar timeline={timeline} />;
    }
  } else if ([OrderStatus.Invited].includes(orderStatus) && user?.id) {
    // 在Invited状态下出预付款说明全局提示栏
    return (
      <FrontPaymentSnackbar
        isUpdate={false}
        backgroundColor="#eff1ff"
        orderId={order?.id}
        prepaymentRate={order?.prepaymentRate}
        Icon={FrontPaymentIcon}
        title={`${rate} front payment available!`}
      />
    );
  }

  return null;
};
