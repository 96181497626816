import React, { useState, useEffect, useCallback } from 'react';
import { useParams, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Header from '../../components/campaign/campaignHeader';
import axiosInstance from '../../apis/axiosInstance';
import { youtubeLinkValidator, linkValidator } from '@/biz/validator';
import styles from './uploadDraft.module.less';
import Modal from '@material-ui/core/Modal';
import { OrderStatus } from '../../interface/order';
import { getOrderId } from '@/biz/tool';

import '../../components/campaign/custom.less';
import toast from 'react-hot-toast';

import { ReactComponent as SuccessIcon } from '@/assets/icons/success.svg';
import { ReactComponent as FailedIcon } from '@/assets/icons/failed.svg';
import { ReactComponent as LoadingIcon } from '@/assets/icons/loading.svg';
import { withStyles } from '@material-ui/core/styles';
const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '5B3DE9'
    }
  }
})(TextField);

interface Props {
  data: any;
}

export const ConfirmDialog: React.FC<{
  isOpen: boolean;
  setIsOpen: Function;
  orderId: any;
}> = ({ isOpen, setIsOpen, orderId }) => {
  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <div className={styles.dialogTitle}>Confirmation</div>
      <div className={styles.dialogDesc}>
        The information will not be saved if you continue.
      </div>
      <div className={styles.btnCtn}>
        <div className={styles.subBtn} onClick={() => setIsOpen(false)}>
          {' '}
          Stay
        </div>
        <NavLink replace to={`/campaign/detail/${encodeURIComponent(orderId)}`}>
          <div className={styles.btn}>Discard</div>
        </NavLink>
      </div>
    </Dialog>
  );
};

const UrlDraft: React.FC<{
  type: string;
  setLink: Function;
  setDesc: Function;
  setSubmitBtnDisabled: Function;
  showRemarks: Boolean;
  headerDesc: React.ReactNode;
  headerTitleLink?: React.ReactNode;
}> = ({
  type,
  setLink,
  setDesc,
  setSubmitBtnDisabled,
  showRemarks,
  headerDesc,
  headerTitleLink
}) => {
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [remarks, setRemarks] = useState('');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setLink(url);
  }, [url]);

  useEffect(() => {
    setDesc(remarks);
  }, [remarks]);

  useEffect(() => {
    if (url) {
      let isValidLink = false;
      if (type === 'script') {
        // script 是 url 链接
        isValidLink = linkValidator(url);
      } else {
        // draft 和 final 都是 youtube 链接
        isValidLink = youtubeLinkValidator(url);
      }
      setIsValid(isValidLink);
      setSubmitBtnDisabled(!isValidLink);
    } else if (url === '' && mounted) {
      setIsValid(false);
      setSubmitBtnDisabled(true);
    }
  }, [url]);

  return (
    <div className={styles.upload}>
      <div className={styles.inputCtn}>
        <div className="flex justify-between">
          <div className={styles.title}>
            {type === 'script' ? 'Script draft link' : null}
            {type === 'draft' ? 'Draft link' : null}
            {type === 'final' ? 'Video link' : null}
            <span className={styles.titleIcon}>*</span>
          </div>
          <div className="text-[#0c67e6] font-normal text-xs self-center">
            {headerTitleLink && headerTitleLink}
          </div>
        </div>

        {headerDesc && <div className={styles.desc}>{headerDesc}</div>}
        <CssTextField
          error={!isValid}
          helperText={
            !isValid &&
            (type === 'script'
              ? 'Please enter the correct URL link.'
              : 'Please enter the correct YouTube link.')
          }
          className={styles.input}
          inputProps={{ autoComplete: 'new-password' }}
          placeholder="Paste link"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
        />
      </div>
      {showRemarks && (
        <div className={styles.inputCtn}>
          <div className={styles.title}>Additional notes</div>
          <CssTextField
            className={styles.input}
            inputProps={{ autoComplete: 'new-password' }}
            placeholder="Say something..."
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

const PictureDraft: React.FC = () => {
  const [desc, setDesc] = useState('');
  return (
    <div className={styles.upload}>
      <div className={styles.inputCtn}>
        <div className={styles.contentTitle}>
          <div className={styles.label}>Platform</div>
          <div className={styles.platform}>
            <img src="/c-twitter.png" className={styles.platformIcon} />
            Twitter
          </div>
        </div>
      </div>
      <div className={styles.inputCtn}>
        <div className={styles.title}>
          Description<span className={styles.titleIcon}>*</span>
        </div>
        <TextareaAutosize
          value={desc}
          className={styles.mTextArea}
          placeholder="Enter"
          onChange={(event) => {
            let text = event?.target?.value;
            text = text.length > 3000 ? text.slice(0, 3000) : text;
            setDesc(text);
          }}
        />
        <div className={styles.limitNum}>{desc.length}/3000</div>
      </div>
      <div className={styles.inputCtn}>
        <div className={styles.title}>
          Upload<span className={styles.titleIcon}>*</span>
        </div>
        <div className={styles.desc}>Images: JPG, PNG (maximum size: 10MB</div>
        <div className={styles.imageList}>
          <img src="/c-uploadIcon.png" className={styles.img} />
          <div className={styles.img}>
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              className={styles.inputFile}
            />
            <img src="/c-uploadIcon.png" className={styles.img} />
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoDraft: React.FC = () => {
  const [desc, setDesc] = useState('');
  return (
    <div className={styles.upload}>
      <div className={styles.inputCtn}>
        <div className={styles.contentTitle}>
          <div className={styles.label}>Platform</div>
          <div className={styles.platform}>
            <img src="/c-twitter.png" className={styles.platformIcon} />
            Twitter
          </div>
        </div>
      </div>
      <div className={styles.inputCtn}>
        <div className={styles.title}>
          Description<span className={styles.titleIcon}>*</span>
        </div>
        <TextareaAutosize
          value={desc}
          className={styles.mTextArea}
          placeholder="Enter"
          onChange={(event) => {
            let text = event?.target?.value;
            text = text.length > 3000 ? text.slice(0, 3000) : text;
            setDesc(text);
          }}
        />
        <div className={styles.limitNum}>{desc.length}/3000</div>
      </div>
      <div className={styles.inputCtn}>
        <div className={styles.title}>
          Upload<span className={styles.titleIcon}>*</span>
        </div>
        <div className={styles.desc}>
          Videos: MP4, MOV (maximum size: 100MB)
        </div>
        <div className={styles.imageList}>
          <img src="/c-uploadIcon.png" className={styles.img} />
          <div className={styles.img}>
            <input type="file" accept="video/*" className={styles.inputFile} />
            <img src="/c-uploadIcon.png" className={styles.img} />
          </div>
        </div>
      </div>
    </div>
  );
};

const UploadDraft: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const type: string = query.get('type') || '';
  const orderId = getOrderId();
  const { method } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [desc, setDesc] = useState('');
  const [submitBtnLoading, setSubmitJoinBtnLoading] = useState<boolean>(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resultType, setResultType] = useState<'success' | 'failed'>('success');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorTitle, setErrorTitle] = useState<string>('2');
  const [pollingCount, setPollingCount] = useState(0); // 用于跟踪轮询次数

  const { data: orderData } = useRequest(async () => {
    const res = await axiosInstance.get(`/order/detail/${orderId}`);
    if (res.data.data?.order) {
      return Promise.resolve(res.data.data.order);
    } else {
      return Promise.reject(res);
    }
  });

  useEffect(() => {
    console.log('data:', orderData);
    if (orderData?.status) {
      // 回到 detail 页面, replace:true
      if (
        type !== 'final' &&
        orderData.status !== OrderStatus.UnderProduction
      ) {
        if (type === 'script') {
          toast.error(
            'Script cannot be submitted with the current order status.'
          );
        }
        if (type === 'draft') {
          toast.error(
            'Drafts cannot be submitted with the current order status.'
          );
        }
        navigate(`/campaign/detail/${orderId}`, { replace: true });
      }
      console.log('type: ', type);
      if (
        type === 'final' &&
        ![OrderStatus.PendingPublish, OrderStatus.PublishRejected].includes(
          orderData.status
        )
      ) {
        toast.error(
          'Final link cannot be submitted with the current order status.'
        );
        navigate(`/campaign/detail/${orderId}`, { replace: true });
      }
    }
  }, [orderData]);

  useEffect(() => {
    if (desc) {
      setText([link, desc].filter((i) => i.length > 0).join('\n'));
    } else {
      setText(link);
    }
  }, [link, desc]);

  // 1s 一个轮询  5次
  const getFinalLinkStatus = async () => {
    const {
      data: { data }
    } = await axiosInstance.get(`/order/detail/${orderId}`);

    if (data.order.status === OrderStatus.PendingPayment) {
      cancel();
      setLoading(false);
      setResultType('success');
    }

    if (data.order.status === OrderStatus.PublishRejected) {
      setLoading(false);
      setResultType('failed');

      if (
        data.order?.extraInfo?.extraMessage ===
        'Please check whether your link can be accessed.'
      ) {
        setErrorTitle('Video does not exist');
      } else if (
        data.order?.extraInfo?.extraMessage ===
        'Please submit a link from the account associated with this Aha collaboration.'
      ) {
        setErrorTitle('Incorrect YouTube account');
      } else if (
        data.order?.extraInfo?.extraMessage ===
        "Please check if you've submitted the correct video with the promotional link in description."
      ) {
        setErrorTitle('Promotional link not detected');
      } else {
        setErrorTitle('Connection timed out');
      }

      setErrorMessage(
        data.order?.extraInfo?.extraMessage ||
          'Connection timed out Please check your internet connection and try again.'
      );
      cancel();
    }
  };

  const { run: runQueryLinkStatus, cancel } = useRequest(
    useCallback(() => {
      if (pollingCount < 5) {
        setPollingCount((prevCount) => prevCount + 1); // 增加轮询次数
        return getFinalLinkStatus();
      }
      // 五次还没有返回数据
      // 弹窗重新提交
      if (loading) {
        setLoading(false);
        setResultType('failed');
        setErrorTitle('Connection timed out');
        setErrorMessage(
          'Connection timed out Please check your internet connection and try again.'
        );
      }
      cancel();
      return Promise.resolve(null); // 超过5次后不再发起请求
    }, [pollingCount]),
    {
      pollingInterval: 1000,
      pollingWhenHidden: false,
      manual: true
    }
  );

  // 提交 script
  const postScript = async () => {
    setSubmitJoinBtnLoading(true);
    try {
      await axiosInstance.post(`/order/${orderId}/draft`, { text });
      navigate(`/campaign/detail/${orderId}`, { replace: true });
      return;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to submit script.');
      console.error(
        `Failed to fetch : (/order/${orderId}/draft script) \n`,
        error
      );
    } finally {
      setSubmitJoinBtnLoading(false);
    }
  };

  const postDraft = async () => {
    setSubmitJoinBtnLoading(true);
    try {
      await axiosInstance.post(`/order/${orderId}/draft`, { text });
      navigate(`/campaign/detail/${orderId}`, { replace: true });
      return;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to submit draft.');
      console.error(
        `Failed to fetch : (/order/${orderId}/draft draft) \n`,
        error
      );
    } finally {
      setSubmitJoinBtnLoading(false);
    }
  };

  const postFinalLink = async () => {
    setSubmitJoinBtnLoading(true);
    setShowModal(true);
    setLoading(true);
    setPollingCount(0);
    setErrorMessage('');

    try {
      await axiosInstance.put(`/order/${orderId}/promotionalLink`, {
        promotionalLink: text
      });
      // 轮询 finalLink 校验接口
      setTimeout(() => {
        runQueryLinkStatus();
      }, 1000);
    } catch (error: any) {
      setLoading(false);
      setResultType('failed');
      setErrorMessage('YouTube Link Upload Failed.');

      toast.error(error?.message || 'Failed to submit final link.');
      console.error(
        `Failed to fetch : (/order/${orderId}/promotionalLink) \n`,
        error
      );
    } finally {
      setSubmitJoinBtnLoading(false);
    }
  };

  const pageMap = {
    script: {
      headerTitle: 'Submit script',
      headerTitleLink: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.notion.so/Aha-Docs-11104daded68803fb924f34bbac22a76?pvs=4#11804daded6880668084f37b392d707a"
          className="text-[#0c67e6] font-normal"
        >
          Need Help? See tutorial
        </a>
      ),
      headerDesc: (
        <>
          <p className="break-all">
            Upload your content to to Google doc and submit the link here.
            Please make sure the link is set to{' '}
            <span className="text-[#5B3DE9] font-medium">
              public for sharing
            </span>
            .
          </p>
        </>
      ),
      submitAction: postScript
    },
    draft: {
      headerTitle: 'Submit video draft',
      headerTitleLink: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.notion.so/Aha-Docs-11104daded68803fb924f34bbac22a76?pvs=4#11804daded6880668084f37b392d707a"
          className="text-[#007AFF]"
        >
          Need Help? See tutorial
        </a>
      ),
      headerDesc: (
        <>
          <p className="break-all">
            Link must be an <strong>unlisted</strong> YouTube video
            (e.g.https://www.youtube.com/watch?v=3lnpTnjcH-k).
          </p>
        </>
      ),
      submitAction: postDraft
    },
    final: {
      headerTitle: 'Submit final link',
      headerTitleLink: null,
      headerDesc: (
        <>
          <p>Please ensure you're uploading your approved final draft.</p>
          <p>
            {`To obtain your link: Open your video on YouTube > Copy the link in the URL bar (Example: https://www.youtube.com/watch?v=llyKYkumZVO)`}
          </p>
        </>
      ),
      submitAction: postFinalLink
    }
  } as const;

  return (
    <>
      <Header title={pageMap[type as keyof typeof pageMap].headerTitle} />
      {(method as string) === 'link' ? (
        <UrlDraft
          setLink={setLink}
          setDesc={setDesc}
          type={type}
          setSubmitBtnDisabled={setSubmitBtnDisabled}
          showRemarks={type !== 'final'}
          headerDesc={pageMap[type as keyof typeof pageMap].headerDesc}
          headerTitleLink={
            pageMap[type as keyof typeof pageMap].headerTitleLink
          }
        />
      ) : (
        <PictureDraft />
      )}
      <div className={styles.bottom}>
        <ConfirmDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orderId={orderId}
        />
        <Button
          variant="contained"
          disabled={submitBtnLoading || submitBtnDisabled}
          className={`${styles.joinButton} ${submitBtnDisabled ? styles.joinButtonDisabled : ''} `}
          onClick={() => pageMap[type as keyof typeof pageMap].submitAction()}
        >
          {submitBtnLoading ? 'Submitting...' : 'Submit'}
        </Button>

        <Button
          variant="contained"
          className={styles.notInterestedButton}
          onClick={() => setIsOpen(true)}
        >
          Cancel
        </Button>
      </div>
      <Modal
        open={showModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="bg-white relative rounded-[20px] p-5 w-[280px] left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center items-center">
          {loading ? (
            <LoadingContent submitBtnLoading={submitBtnLoading} />
          ) : (
            <ResultContent
              type={resultType}
              setShowModal={setShowModal}
              errorMessage={errorMessage}
              errorTitle={errorTitle}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

const ResultContent = ({
  type,
  setShowModal,
  errorMessage,
  errorTitle
}: {
  type: 'success' | 'failed';
  setShowModal: Function;
  errorMessage: string;
  errorTitle: string;
}) => {
  const navigate = useNavigate();
  const orderId = getOrderId();

  return (
    <>
      {type === 'success' ? (
        <div className="flex flex-col w-full items-center">
          <div className="mt-2 mb-4">
            <SuccessIcon />
          </div>
          <div className="font-semibold text-xl">Success!</div>
          <div className="text-[#60646C] text-sm text-center my-4">
            Your link has been submitted.
          </div>
          <div className="flex w-full space-x-2">
            <Button
              variant="contained"
              className={`${styles.primaryButton}`}
              onClick={() => {
                navigate(`/campaign/detail/${orderId}`, { replace: true });
              }}
            >
              {'OK'}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full items-center">
          <div className="mt-2 mb-4">
            <FailedIcon />
          </div>
          <div className="font-semibold text-xl text-nowrap">{errorTitle}</div>
          <div className="text-[#60646C] text-sm text-center my-4">
            {errorMessage}
          </div>
          <div className="flex w-full space-x-2">
            {/* <Button
              variant="contained"
              className={`${styles.button} mr-2`}
              onClick={() => {
                navigate(`/campaign/detail/${orderId}`, { replace: true });
              }}
            >
              {'Cancel'}
            </Button> */}
            <Button
              variant="contained"
              className={`${styles.primaryButton}`}
              onClick={() => {
                setShowModal(false);
              }}
            >
              {'Try again'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const LoadingContent = ({ submitBtnLoading }: { submitBtnLoading: any }) => (
  <>
    <div className="animate-spin-keyframes">
      <LoadingIcon />
    </div>
    <div className="flex flex-col items-center text-[#60646C] mt-4">
      {submitBtnLoading ? (
        <div className="">Uploading link...</div>
      ) : (
        <div className="">Validating link...</div>
      )}
    </div>
  </>
);

export default UploadDraft;
