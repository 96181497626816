import React, { useState, useEffect } from 'react';
import { Tracker } from '../../utils';
import Button from '@material-ui/core/Button';
import styles from './contentRequirements.module.less';
import { ReactComponent as NotionIcon } from '@/assets/icons/notion.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right.svg';
import { getDuration } from '@/biz/time';
import { isMobile } from '@/biz/tool';
interface Props {
  data: any;
  type?: string;
}

export const Brand: React.FC<{ brand: Array<string> }> = ({ brand }) => {
  return (
    <div className={styles.brandCtn}>
      {brand.map((item, index) => (
        <div key={index} className={styles.textCtn}>
          {/* <div className={styles.label}>{index + 1}.</div> */}
          <p className={styles.li}>{item}</p>
        </div>
      ))}
    </div>
  );
};

export const NotionLink: React.FC<{ href: string; fileName: string }> = ({
  href,
  fileName
}) => {
  return (
    <div
      className="rounded-lg py-1 flex justify-center mb-2"
      style={{ backgroundColor: '#EAF3FF', fontFamily: 'Inter' }}
    >
      <a
        className="flex flex-row justify-start items-center mx-auto"
        target="_blank"
        href={href}
        rel="noreferrer"
      >
        <div className="mr-2 font-normal text-xs" style={{ color: '#136BEB' }}>
          Check {fileName} in
        </div>
        <NotionIcon className="mr-1" />
        <ArrowRightIcon />
      </a>
    </div>
  );
};

export const ContentRequirements: React.FC<Props> = ({
  data: { campaign, order, timeline } = {},
  type
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [brand, setBrand] = useState<any>([]);
  useEffect(() => {
    let str = '';
    if (typeof campaign?.mustDo === 'string') {
      str = campaign?.mustDo;
    }
    if (typeof campaign?.mustNotDo === 'string') {
      str = str + '\n' + campaign?.mustNotDo;
    }
    if (str) {
      setBrand(
        str
          .split(/\r?\n/)
          .map((i) => i.trim().replace(/^\s*?(•|-|\d+\.)?\s*/, ''))
          .filter((item: string) => item)
      );
    }
  }, [campaign?.mustDo, campaign?.mustNotDo]);
  // productionTime

  useEffect(() => {
    const labelArr = ['View platform', 'View brand'];

    Tracker.click(labelArr[activeTabIndex]);
  }, [activeTabIndex]);

  const productionTimeDeadline = (hours: number | null) => {
    if (hours) return getDuration(hours);
    return '-';
  };
  return (
    <div className={styles.contentCtn}>
      <div
        className={styles.buttonList}
        style={{ display: brand.length > 0 ? 'flex' : 'none' }}
      >
        <Button
          variant="contained"
          className={activeTabIndex === 0 ? styles.activeButton : styles.button}
          onClick={() => setActiveTabIndex(0)}
        >
          General
        </Button>

        <Button
          variant="contained"
          className={activeTabIndex === 1 ? styles.activeButton : styles.button}
          onClick={() => setActiveTabIndex(1)}
        >
          Brand
        </Button>
      </div>
      {activeTabIndex === 0 ? (
        <>
          <NotionLink
            href={
              'https://chartreuse-wrinkle-697.notion.site/YouTube-Creator-Requirements-final-2b26b43555934070a8b6835096303a77'
            }
            fileName="platform requirements"
          />
          <div className="app-web-iframe-box">
            <iframe
              src="https://v2-embednotion.com/2b26b43555934070a8b6835096303a77"
              style={{
                // width: '100%',
                height: type === 'delivery' ? 'calc(100vh - 176px)' : '500px',
                border: '2px solid #ccc',
                borderRadius: '10px',
                padding: 'none'
              }}
              className={isMobile() ? 'app-mobile-iframe' : 'app-web-iframe'}
              title="Platform Requirements"
            ></iframe>
          </div>
        </>
      ) : null}
      {activeTabIndex === 1 ? <Brand brand={brand} /> : null}
    </div>
  );
};
