import React, { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Checkbox from '@material-ui/core/Checkbox';
// import Radio from '@material-ui/core/Radio';
import Radio from '@/components/Radio/index';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { InfluencerAgreement } from './influencerAgreement';
import axiosInstance from '../../apis/axiosInstance';
import toast from 'react-hot-toast';
import {
  CloseIcon,
  JoinDrawEmaiIcon,
  JoinDrawClockIcon,
  JoinDrawFrontPaymentIcon
} from '../common/icon';
import { Tracker } from '../../utils';
import styles from './joinButton.module.less';
import './custom.less';
import { normalizePrice } from '@/utils/normalizePrice';
import { getDuration } from '@/biz/time';
import { getOrderId, getToken, getLoginPageUrl } from '@/biz/tool';
import { getFrontPaymentRate } from '../../pages/campaign/config';

interface Props {
  data: any;
}

const NotInterestedDrawer: React.FC<{
  order: any;
  isOpen: boolean;
  setIsOpen: Function;
  onSubmit?: Function;
}> = (props) => {
  const navigate = useNavigate();
  const { isOpen, setIsOpen, onSubmit, order } = props;
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [showTimelineInput, setShowTimelineInput] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [feedback, setFeedback] = useState([]);

  const saveFeedback = async () => {
    if (!getToken()) {
      navigate(getLoginPageUrl());
      return;
    }
    setSubmitBtnLoading(true);
    try {
      await axiosInstance.post(
        `/order/${order?._id}/creatorReject`,
        feedback.filter((item) => !!item)
      );
      toast.success('Submission success.');
      //成功之后关闭弹窗，并且隐藏按钮
      setIsOpen(false);
      onSubmit && onSubmit();
      // 提交后刷新页面获取状态
      window.location.reload();
    } catch (err) {
      toast.error('Submission failed.');
      console.error(err);
    } finally {
      setSubmitBtnLoading(false);
    }
  };

  const updateFeedback = (
    feedback: Array<any>,
    type: string,
    input: string,
    isSelect: boolean,
    index: number
  ) => {
    if (isSelect) {
      feedback[index] = { type, input };
    } else {
      feedback[index] = false;
    }

    setFeedback([...feedback] as SetStateAction<never[]>);
  };

  return (
    <Drawer
      id="drawerCtn"
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles.drawerTitle}>
        Not interested{' '}
        <CloseIcon
          className={styles.closeIcon}
          onClick={() => setIsOpen(false)}
        />
      </div>
      <div className={styles.drawerDesc}>
        We're sorry that you're not interested in the campaign. Please select
        the reason below
      </div>
      <div className={styles.item}>
        <Checkbox
          color="primary"
          onChange={(_, isSelect) => {
            if (!getToken()) {
              navigate(getLoginPageUrl());
              return;
            }
            updateFeedback(
              feedback,
              ' Proposed price is not attractive',
              '',
              isSelect,
              0
            );
          }}
        />
        Proposed price is not attractive
      </div>

      <div className={styles.item}>
        <Checkbox
          color="primary"
          onChange={(_, isSelect) => {
            if (!getToken()) {
              navigate(getLoginPageUrl());
              return;
            }
            updateFeedback(
              feedback,
              'Timeline is too tight given the deliverables',
              '',
              isSelect,
              1
            );
            setShowTimelineInput(!showTimelineInput);
          }}
        />
        Timeline is too tight given the deliverables
      </div>
      {showTimelineInput ? (
        <div className={styles.textArea}>
          <div className={styles.textAreaTitle}>
            Enter your expected timeline
          </div>
          <TextareaAutosize
            className={styles.mTextArea}
            onChange={(event) => {
              if (!getToken()) {
                navigate(getLoginPageUrl());
                return;
              }
              updateFeedback(
                feedback,
                'Timeline is too tight given the deliverables',
                event.target.value,
                true,
                1
              );
            }}
            placeholder="Example: 2 days for filming, 3 days for editing"
          />
        </div>
      ) : null}
      <div className={styles.item}>
        <Checkbox
          color="primary"
          onChange={(_, isSelect) => {
            if (!getToken()) {
              navigate(getLoginPageUrl());
              return;
            }
            updateFeedback(
              feedback,
              'Not interested in this brand or product',
              '',
              isSelect,
              2
            );
          }}
        />
        Not interested in this brand or product
      </div>
      <div className={styles.item}>
        <Checkbox
          color="primary"
          onChange={(_, isSelect) => {
            if (!getToken()) {
              navigate(getLoginPageUrl());
              return;
            }
            updateFeedback(feedback, 'Other', '', isSelect, 3);
            setShowOtherInput(!showOtherInput);
          }}
        />
        Other
      </div>
      {showOtherInput ? (
        <div className={styles.textArea}>
          <div className={styles.textAreaTitle}>Enter your reason</div>
          <TextareaAutosize
            className={styles.mTextArea}
            placeholder="Example: I have scheduling conflicts"
            onChange={(event) =>
              updateFeedback(feedback, 'Other', event.target.value, true, 3)
            }
          />
        </div>
      ) : null}
      <div className="flex gap-2 mt-4">
        <Button
          variant="contained"
          className={styles.cancelButton}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={submitBtnLoading}
          className={styles.submitButton}
          onClick={saveFeedback}
        >
          {submitBtnLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </Drawer>
  );
};

export const JoinButton: React.FC<Props> = ({
  data: { order, payment, timeline } = {}
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [submitDrawer, setSubmitDrawerOpen] = useState(false);
  const [joinBtnLoading, setJoinBtnLoading] = useState<boolean>(false);
  const orderId = getOrderId();
  // initialScriptDraftDeadline
  const initialScriptDraftDeadline = (hours: number | null) => {
    if (hours) return getDuration(hours);
    return '-';
  };

  const joinCampaign = async (
    order: {
      _id: string;
      campaignId: string | number;
      platform: string;
    },
    payment: any
  ) => {
    setJoinBtnLoading(true);
    try {
      if (!getToken()) {
        window.location.href = getLoginPageUrl({ joinCampaign: true });
        return;
      }

      const {
        data: { code, message }
      } = await axiosInstance.post(`/campaign/join`, {
        campaignId: order?.campaignId,
        platform: order?.platform,
        orderId: order?._id
      });
      toast.success('You have successfully applied!', { duration: 2000 });

      setTimeout(() => {
        if (payment) {
          window.location.reload();
        } else {
          window.location.href = `/campaign/payment/method?orderId=${orderId}`;
        }
      }, 2000);
    } catch (error: any) {
      setJoinBtnLoading(false);
      toast.error(error.message || 'Failed to join.', { duration: 2000 });
      console.error(`Failed to fetch : (/campaign/join) \n`, error);
    }
  };

  const ConfirmDrawer: React.FC<{
    isOpen: boolean;
    setIsOpen: Function;
    order: any;
  }> = ({ isOpen, setIsOpen, order }) => {
    const [isAgree, setIsAgree] = useState(true);
    const [isShowAgreement, setIsShowAgreement] = useState(false);

    useEffect(() => {
      Tracker.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: 'confirm to join'
      });
    }, []);

    // matching
    const matching = (hours: number | null) => {
      if (hours) return getDuration(hours);
      return '-';
    };

    if (isShowAgreement) {
      return (
        <Drawer
          id="drawerCtn"
          anchor="bottom"
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <InfluencerAgreement
            isOpen={isShowAgreement}
            setIsOpen={setIsShowAgreement}
          />
        </Drawer>
      );
    }

    const rate = getFrontPaymentRate(order?.prepaymentRate);

    return (
      <Drawer
        id="drawerCtn"
        anchor="bottom"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className={styles.drawerTitle}>
          Confirm to join
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className={styles.confirmDesc}>
          <div className={styles.li}>
            <JoinDrawEmaiIcon className={styles.joinDrawIcon} />
            <span>
              We’ll review your application and send results within 1-2 days.
            </span>
          </div>
          <div className={styles.li}>
            <JoinDrawClockIcon className={styles.joinDrawIcon} />
            <div>
              <span>If accepted, submit your script draft within </span>
              <span className={styles.timeText}>
                {initialScriptDraftDeadline(
                  timeline?.initialScriptDraft?.maxWaitInHours
                )}{' '}
                of approval
              </span>
              .
            </div>
          </div>
          <div className={styles.li}>
            <JoinDrawFrontPaymentIcon className={styles.joinDrawIcon} />
            <span>
              You'll receive{' '}
              <span style={{ fontWeight: '600', color: '#1C2024' }}>
                {rate} ({normalizePrice(order?.prePaymentAmount)})
              </span>
              after your application is approved and another{' '}
              <span style={{ fontWeight: '600', color: '#1C2024' }}>
                {rate} ({normalizePrice(order?.price - order?.prePaymentAmount)}
                )
              </span>{' '}
              within one week of your post going live.
            </span>
          </div>
        </div>
        <div className={styles.agree}>
          <Radio
            checked={isAgree}
            onClick={() => {
              setIsAgree(!isAgree);
            }}
          />
          <span className="ml-1">I consent to Aha's</span>
          <span
            className={styles.linkText}
            onClick={() => setIsShowAgreement(!isShowAgreement)}
          >
            Creator Agreement
          </span>
          .
        </div>
        <div className="flex gap-2 mt-4">
          <Button
            variant="contained"
            className={styles.cancelButton}
            onClick={() => {
              Tracker.click('cancel on confirm to join in');
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={joinBtnLoading}
            className={styles.submitButton}
            onClick={() => {
              Tracker.click('confirm on confirm to join in');
              joinCampaign(order, payment);
            }}
          >
            {joinBtnLoading ? 'Confirming...' : 'Confirm'}
          </Button>
        </div>
      </Drawer>
    );
  };
  if (!isMenuOpen) return null;
  return (
    // <div id="bottom" className={styles.bottom}>
    <div
      id="bottom"
      className="bg-white fixed bottom-0 w-[390px] shadow-md flex pt-4 pb-4 px-4 gap-2"
    >
      <Button
        variant="contained"
        className={styles.notInterestedButton}
        onClick={() => {
          setIsOpen(true);
          Tracker.click('not interested');
        }}
      >
        Not interested
      </Button>
      <Button
        variant="contained"
        className={styles.joinButton}
        onClick={() => {
          setSubmitDrawerOpen(true);
          Tracker.click('Join now');
        }}
      >
        Join now
      </Button>
      <NotInterestedDrawer
        order={order}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={() => setIsMenuOpen(false)}
      />
      <ConfirmDrawer
        isOpen={submitDrawer}
        setIsOpen={setSubmitDrawerOpen}
        order={order}
      />
    </div>
  );
};
